// Packages
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const StockListItem = ({ isLoading, name, reference, quantity, supplier }) => {

	return (
		<div className='StockListItem'>
			<div className='StockListItem__name'>
				<h6 className='blue inline-block' style={{marginRight: '20px'}}>{! isLoading ? reference !== '' ? 'number ' + reference : '' : <BTDSkeleton width='50%'/>}</h6> 
				<h3 className='blue'>{! isLoading ? name : <BTDSkeleton width='50%'/>}</h3>
			</div>
			<div className='StockListItem__supplier'>
				<p className='blue'>{! isLoading ? supplier ? supplier.name : '' : <BTDSkeleton width='50%'/>}</p>
			</div>
			<div className='StockListItem__quantity'>
				<p className='blue'>{! isLoading ? 'Quantity: ' + quantity : <BTDSkeleton width='50%'/>}</p>
			</div>

		</div>
	)
}

StockListItem.propTypes = {
	isLoading: PropTypes.bool,
	name: PropTypes.string, 
	reference: PropTypes.string, 
	quantity: PropTypes.number,
	supplier: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
}

StockListItem.defaultProps = {
	isLoading: false,
	name: '',
	reference: '',
	quantity: '',
	supplier: {},
}

export default StockListItem;