// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDTab from 'components/BTDUI/BTDTab';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const TestResultsListItem = ({ contractJob, contract_id, id, isLoading, model_number, manufacturer, onChange, reference, serial, test_results }) => {

	const [colour, setColour] = useState();
	const [statusText, setStatusText] = useState();
	const [resultId, setResultId] = useState(null);

	const { destroy: destroyResults } = useApi('/testresults/' + resultId, false);
	const { destroy: destroyEquipment } = useApi('/contractjobs/' + contractJob.id + '/equipment/' + id, false);
	const { data: pdfUrl, post: generatePDF } = useApi('/testresults/generate-pdf', false);

	useEffect(() => {

		if( test_results[0] ) {

			switch (test_results[0].status) {
				case 'passed': 
					setColour('green');
					setStatusText('Passed');
					break;
				case 'failed': 
					setColour('red');
					setStatusText('Failed');
					break;
				default:
					setColour('orange');
					setStatusText('Waiting for readings');
					break;
			}
		}
		else {
			setColour('orange');
			setStatusText('Waiting for readings');
		}

	}, [test_results])


	const deleteEquipment = async () => {

		if(await destroyEquipment('piece of equipment', 'Equipment Deleted!')) {

			onChange();
		}
    }

	useEffect(() => {

		if( resultId !== null ) {

			destroyResults('test result', 'Test results Deleted!');

			onChange();
		}

	// eslint-disable-next-line
	}, [resultId])

	useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])

	return (
		<div className='TestResultsListItem tr'>

			<p className='TestResultsListItem__ref blue semi-bold'><span className='TestResultsListItem__mobileLabel'>Ref:<>&nbsp;</></span>{! isLoading ? reference : <BTDSkeleton width='50%'/>}</p> 

			<div className='TestResultsListItem__model'>
				<p className='blue'>{! isLoading ? manufacturer ? manufacturer.name : '' : <BTDSkeleton width='50%'/>}</p>
				<p className='blue'>{! isLoading ? model_number : <BTDSkeleton width='50%'/>}</p>
				<p className='blue'>{! isLoading ? serial : <BTDSkeleton width='50%'/>}</p>
			</div>

			<p className='TestResultsListItem__status blue'>{! isLoading ? <BTDTab colour={colour} style={{margin: '0 auto'}} text={statusText} /> : <BTDSkeleton width='50%'/>}</p>


			<div className='TestResultsListItem__results'>
			{test_results && test_results.map((result) => {
				
				return(
					<div className='centre' key={result.id}>	
						<span className='TestResultsListItem__results__left'>
							<span className='blue'>{! isLoading ? dayjs(result.created_at).format('D MMM YYYY') + ' - ' : <BTDSkeleton width='50%'/>}</span>
							<span className='blue' style={{color: result.status === 'passed' ? '#6DA726' : '#BC3B3B' }}>{! isLoading ? result.status === 'passed' ? 'PASS' : 'FAIL' : <BTDSkeleton width='50%'/>}</span> -<>&nbsp;</>
						</span>
						<span className='TestResultsListItem__results__right'>
							<span 
								className='light-blue' 
								onClick={() => {generatePDF({id: result.id})}}
								style={{cursor: 'pointer'}}
							>
							<a id="downloadLink" href=""/>
								{! isLoading ? result.certificate_reference : <BTDSkeleton width='50%'/>}
							</span>
							{! isLoading ? 
								<>
								{contractJob.status !== 'closed' ?
									<Link to={'/contracts/' + contract_id + '/jobs/' + contractJob.id + '/equipment/' + id + '/results/' + result.id}>
										<EditIcon 
											colour='#40506B'
											height='16px' 
											style={{cursor: 'pointer', margin: '0 10px 0 10px', transform: 'translateY(2px)'}} 
											width='16px' 
										/> 
									</Link>
									:
									<span style={{display: 'inline-block', width: '5px'}}> </span>
								}
								<ClearIcon 
									colour='#BC3B3B' 
									height='18px' 
									onClick={() => setResultId(result.id)}
									style={{cursor: 'pointer', transform: 'translateY(4px)'}} 
									width='18px' 
								/> 
							</>
							: <BTDSkeleton width='50%'/>}
						</span>
					</div>
					)
				})
			
			}
			</div>

			{contractJob.status !== 'closed' ?
				<BTDButton
					className='TestResultsListItem__button'
					label='Enter test results'
					url={'/contracts/' + contract_id + '/jobs/' + contractJob.id + '/equipment/' + id + '/results'}
					size='small'
				/>
				:
				<div></div>
			}

			<BTDMenu className='TestResultsListItem__menu' icon={<EllipsisIcon height="16px" width='8px'/>} style={{margin: '0 0 0 auto'}}>
				<li onClick={() => {deleteEquipment()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(4px)'}} /> Delete</li>
			</BTDMenu>

		</div>
	)
}

TestResultsListItem.propTypes = {
	contractJob: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	contract_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	manufacturer: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	model_number: PropTypes.string, 
	onChange: PropTypes.func,
	reference: PropTypes.string, 
	serial: PropTypes.string, 
	test_results: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
}

TestResultsListItem.defaultProps = {
	contractJob: {},
	contract_id: '',
	id: '',
	manufacturer: {},
	model_number: '',
	isLoading: false,
	onChange: () => {alert('onChange is not set!')},
	reference: '',
	serial: '',
	test_results: [],
}

export default TestResultsListItem;