// Packages
import PropTypes from 'prop-types';

// Components
import ListItem from 'modules/PurchaseOrderItems/components/ListItem';

// Other
import './styles.css';

const PurchaseOrderItemList = ({ discount, delivery, isLoading, items, purchaseOrderId, subtotal, onChange, total }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='PurchaseOrderItemList'>
				<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No purchase order items to show here</h3>
		)
	}


	// Data State
	return (
		<div className='PurchaseOrderItemList'>

			<div className='PurchaseOrderItemList__tr PurchaseOrderItemList__header tr'>
				<div className='th left'>Qty</div>
				<div className='th left'>Description</div>
				<div className='th right'>Unit Price</div>
				<div className='th right'>Total Price</div>
			</div>

			{items.map((item, index) => {

				return (
					<ListItem 
						{...item} 
						isLoading={false}
						key={index}
						onChange={() => {onChange()}}
						purchaseOrderId={purchaseOrderId}
					/>
				)
				
			})}

			<div className='PurchaseOrderItemList__border'/>

			{(discount || delivery) ?
				<div className='PurchaseOrderItemList__tr'>
					<span className='td'></span>
					<span className='td'></span>
					<span className='th right'>Subtotal</span>
					<span className='th right'>£ {subtotal.toFixed(2)}</span>
				</div>
			:
			<></>}
			{discount ?
				<div className='PurchaseOrderItemList__tr'>
					<span className='td'></span>
					<span className='td'></span>
					<span className='td right'>Discount</span>
					<span className='td right'>£ {discount.toFixed(2)}</span>
				</div>
			:
			<></>}
			{delivery ?
				<div className='PurchaseOrderItemList__tr'>
					<span className='td'></span>
					<span className='td'></span>
					<span className='td right'>Delivery</span>
					<span className='td right'>£ {delivery.toFixed(2)}</span>
				</div>
			:
			<></>}
			<div className='PurchaseOrderItemList__tr'>
				<span className='td'></span>
				<span className='td'></span>
				<span className='th right'>Total</span>
				<span className='th right'>£ {total.toFixed(2)}</span>
			</div>

		</div>
	)
}

PurchaseOrderItemList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
	purchaseOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PurchaseOrderItemList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
	purchaseOrderId: null,
}

export default PurchaseOrderItemList;