// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import ScreenTitle from 'components/ScreenTitle';
import Header from 'components/Header';
import Footer from 'components/Footer';

const PurchaseOrderLayout = ({ children, title }) => {

	return (
		<div className='PurchaseOrderLayout'>
			
			<Header/>

			<Container>

				<ScreenTitle title={title}/>

				{children}

			</Container>

			<Footer/>
        </div>
	)
}

PurchaseOrderLayout.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string
}

PurchaseOrderLayout.defaultProps = {
	children: null,
	title: ''
}

export default PurchaseOrderLayout