// Packages
import PropTypes from 'prop-types'

// Components
import ContractJobEquipmentForm from 'modules/ContractJobs/components/EquipmentForm';

// Other
import useApi from 'hooks/useApi';

const ContractJobAddEquipmentScreen = ({ contract_id, contract_job_id, onClose, onSubmit }) => {

    const { put: addEquipment, errors: addErrors } = useApi(`/contractjobs/${contract_job_id}/add-equipment?with=equipment,engineers`, false);
    const { data: equipment, post: createEquipment, errors } = useApi('/customerequipment?contract_id=' + contract_id + '&with=manufacturer&per_page=999')

    const submit = async (values) => {

        if( values.type === 'new' ) {

            if ( await createEquipment({...values.data, contract_job_id: contract_job_id}, 'Equipment added!') ) {

                onSubmit();
            }
        }
        else if( values.type === 'existing' ) {

            if( await addEquipment(values.data, 'Equipment added!') ) {

                onSubmit();
            }
        }
    }


    return(
        <ContractJobEquipmentForm 
            addErrors={addErrors}
            contract_id={contract_id}
            errors={errors} 
            equipment={equipment}
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

ContractJobAddEquipmentScreen.propTypes = {
    contract_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

ContractJobAddEquipmentScreen.defaultProps = {
    contract_id: null,
	onClose: () => {alert('onClose is not set!')},
	onSubmit: () => {alert('onSubmit is not set!')},
}

export default ContractJobAddEquipmentScreen;