// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';


const NoteForm = ({ errors, note, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    

    // Load Item values into form
	useEffect(() => {

		if ( note )
		{
            setData(note);
		}

	}, [note]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create note' : 'Edit note'}</h3>
        
            <BTDTextareaField 
                error={errors && errors.body ? errors.body : null} 
                label='Message'
                onChange={(value) => {
                    setData({...data, body: value});
                    setChangedData({...changedData, body: value});
                }} 
                required={true}
                tabIndex={1}
                value={data.body ?? ''} 
            />

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save note' : 'Update note'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

NoteForm.propTypes = {
	errors: PropTypes.object,
	note: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

NoteForm.defaultProps = {
    errors: {},
    note: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default NoteForm;