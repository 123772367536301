// Packages
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const ContractListItem = ({ customer, isLoading, reference, schedule_months }) => {

	const [monthString, setMonthString] = useState('');

	useEffect(() => {

		let jsxElements = [];

		for (const key in schedule_months) {

			if (schedule_months.hasOwnProperty(key)) {

				jsxElements.push(
					<span key={key} className="semi-bold">
						{key}
					</span>
				);
				jsxElements.push(': ');
			}

			if (typeof schedule_months[key] === 'object' && schedule_months[key] !== null) {

				for ( let i = 0; i < schedule_months[key].length; i++ ) {

					jsxElements.push(<span>{schedule_months[key][i].month.name}</span>)
				
					if( i < (schedule_months[key].length -1) ) {

						jsxElements.push(', ' );
					} else {
						jsxElements.push(<span>&nbsp;&nbsp;&nbsp;</span>);
					}
				}
			}

		}

		setMonthString(jsxElements);

	}, [schedule_months])

	return (
		<div className='ContractListItem'>
			<div style={{paddingRight: '15px'}}>
				<h3 className='blue'>{! isLoading ? reference !== '' ? reference : '' : <BTDSkeleton width='50%'/>}</h3> 
			</div>
			<div style={{paddingRight: '15px'}}>
				<p className='blue semi-bold'>{! isLoading ? customer ? customer.name : '' : <BTDSkeleton width='50%'/>}</p>
				<p className='blue opacity regular'>{! isLoading ? customer ? customer.division : '' : <BTDSkeleton width='50%'/>}</p>
			</div>
			<div>
				<p className='blue'>{! isLoading ? monthString : <BTDSkeleton width='50%'/>}</p>
			</div>

		</div>
	)
}

ContractListItem.propTypes = {
	customer: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	isLoading: PropTypes.bool,
	reference: PropTypes.string, 
}

ContractListItem.defaultProps = {
	customer: {},
	isLoading: false,
	reference: '',
}

export default ContractListItem;