// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';

// other
import './styles.css';

const LoginLayout = ({ children }) => {

	return (
		<div className='LoginLayout'>
			<Container>

				{children}

			</Container>
        </div>
	)
}

LoginLayout.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string
}

LoginLayout.defaultProps = {
	children: null,
	title: ''
}

export default LoginLayout