// Packages
import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import ContractEditScreen from 'modules/Contracts/screens/Edit';
import ContractViewLayout from 'modules/Contracts/layouts/View';
import CustomerEquipmentList from 'modules/CustomerEquipment/components/List';
import TickIcon from 'components/BTDUI/icons/TickIcon';
import CustomerEquipmentCreateScreen from 'modules/CustomerEquipment/screens/Create';
import ContractJobList from 'modules/ContractJobs/components/List';
import ContractJobCreateScreen from 'modules/ContractJobs/screens/Create';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const ContractViewScreen = () => {

	const { contractId } = useParams();

	const { data: contract, isLoading, onChange } = useApi('/contracts/' + contractId + '?with=customer,leadengineer,customerequipment,customerequipment.manufacturer,contractjobs,user,updatedbyuser');
	const { destroy } = useApi('/contracts/' + contractId, false);
	const { data: pdfUrl, get: generatePDF } = useApi('/contracts/' + contractId + '/generate-pdf', false);

	const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('query');

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const [formActive, setFormActive] = useState(false);
	const [equipmentFormActive, setEquipmentFormActive] = useState(false);
	const [jobFormActive, setJobFormActive] = useState(false);

	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('contract', 'Contract Deleted!')) {

			navigate('/contracts');
		}

    }

	const [scheduleString, setScheduleString] = useState('');

	useEffect(() => {

		if( contract ) {

			let jsxElements = [];

			for (const key in contract.schedule_months) {

				if (contract.schedule_months.hasOwnProperty(key)) {

					jsxElements.push(
						<span key={key} className="semi-bold">
							{key}
						</span>
					);
					jsxElements.push(': ');
				}

				if (typeof contract.schedule_months[key] === 'object' && contract.schedule_months[key] !== null) {

					for ( let i = 0; i < contract.schedule_months[key].length; i++ ) {

						jsxElements.push(<span>{contract.schedule_months[key][i].month.name}</span>)
					
						if( i < (contract.schedule_months[key].length -1) ) {

							jsxElements.push(', ' );
						} else {
							jsxElements.push(<span>&nbsp;&nbsp;&nbsp;</span>);
						}
					}
				}

				jsxElements.push(<><br/><br/></>);

			}

			setScheduleString(jsxElements);
		}

		
	}, [contract])

	useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])


	return (
		<ContractViewLayout className='ContractViewScreen'>

				<Link className='blue opacity' to={`/contracts${query ? '?query=' + query: ''}`}>{query ? 'Back to search results ' : 'Contracts'}{' >'}</Link> 

				<div className='ContractViewScreen__titleBar'>
					<div className='ContractViewScreen__titleBar__left'>
						<h1 style={{marginRight: '20px'}}>{! isLoading ? 'Contract ' + contract.reference : <BTDSkeleton width='200px'/>}</h1>
					</div>
					<div className='ContractViewScreen__titleBar__right'>
						<BTDButton
							label='Edit'
							onClick={() => {setFormActive(true)}}
							style={{marginRight: '20px'}}
						/>
						<BTDButton
							label='PDF'
							onClick={() => {generatePDF()}}
							style={{margin: '0 20px 0 auto'}}
						/>
						<a id="downloadLink" href="" target='_blank'/>
						<BTDButton
							colour='red'
							label='Delete'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='ContractViewScreen__details'>
					<p>Customer: </p>
					<p className='semi-bold'><Link className='light-blue' to={'/customers/' + (contract && contract.customer ? contract.customer.id : '')}>{! isLoading ? contract.customer ? contract.customer.name : '' : <BTDSkeleton width='100px'/>}</Link><span className='medium'>{! isLoading ? (contract.customer && contract.customer.division != '') ? ' > ' + contract.customer.division : '' : ''}</span></p>
					<p>Created: </p>
					<p className='semi-bold'>{! isLoading ? dayjs(contract.created_at).format('DD MMMM YYYY') : <BTDSkeleton width='100px'/>}</p>
					<p>Signed: </p>
					<p className='semi-bold'>{! isLoading ?  contract.is_signed ? 'Yes' : 'No' : <BTDSkeleton width='100px'/>}</p>
					<p>Test Equipment: </p>
					<p className='semi-bold'>{! isLoading ? contract.test_equipment : <BTDSkeleton width='100px'/>}</p>
					<p>Price: </p>
					<p className='semi-bold'>{! isLoading ? contract.price : <BTDSkeleton width='100px'/>}</p>
					<p className='ContractViewScreen__details__notes'>Engineers Notes: </p>
					<p className='semi-bold'>{! isLoading ? contract.engineers_notes : <BTDSkeleton width='100px'/>}</p>
					<p>Contract Detail: </p>
					{! isLoading && contract && contract.detail ?
						<div className='ContractViewScreen__details__notes semi-bold' style={{lineHeight: '1.35'}} dangerouslySetInnerHTML={{ __html: contract.detail.replace(/\n/g, '<br>')}}/>
					:
						<div/>
					}
					<p className='ContractViewScreen__details__notes'>Lead Engineer: </p>
					<p className='semi-bold'>{! isLoading ? contract.lead_engineer ? contract.lead_engineer.firstname + ' ' + contract.lead_engineer.lastname : '' : <BTDSkeleton width='100px'/>}</p>
					<p>Created at: </p>
					{! isLoading ? <NameDateDisplay date={contract.created_at} user={contract.user} /> : <BTDSkeleton width='100px'/>}
					<p>Updated at: </p>
					{! isLoading ? <NameDateDisplay date={contract.updated_at} user={contract.updated_by_user} /> : <BTDSkeleton width='100px'/>}
				</div>

				<h2 className='ContractViewScreen__subtitle'>Contract Schedule</h2>

				<div className='ContractViewScreen__table'>
					<div className='ContractViewScreen__tr tr'>
						<div className='ContractViewScreen__tr__th th'></div>
						{! isLoading && months.map((month, index) => {
							return(
								<div className='semi-bold td centre' key={index}>{month}</div>
								)
							})
						}
						
					</div>

					<div className='ContractViewScreen__tr tr'>
						<div className='td'>Service</div>
							{! isLoading && months.map((month, index) => {
								return(
									<div className=' ContractViewScreen__tr__td td' key={index}>{
										contract.schedule && contract.schedule['Service'] && contract.schedule['Service'].includes(index + 1) ? <TickIcon colour='#6DA726' height='20px' style={{margin: '0 auto'}} width='20px'/> : ''
									}</div>
								)
							})
						}
					</div>
					<div className='ContractViewScreen__tr tr'>
						<div className='td'>Calibration</div>
							{! isLoading && months.map((month, index) => {
								return(
									<div className='ContractViewScreen__tr__td td' key={index}>{
										contract.schedule && contract.schedule['Calibration'] && contract.schedule['Calibration'].includes(index + 1) ? <TickIcon colour='#6DA726' height='20px' width='20px'/> : ''
									}</div>
								)
							})
						}
					</div>
					<div className='ContractViewScreen__tr tr'>
						<div className='td'>Letter</div>
							{! isLoading && months.map((month, index) => {
								return(
									<div className='ContractViewScreen__tr__td td' key={index}>{
										contract.schedule && contract.schedule['Letter'] && contract.schedule['Letter'].includes(index + 1) ? <TickIcon colour='#6DA726' height='20px' width='20px'/> : ''
									}</div>
								)
							})
						}
					</div>
					<div className='ContractViewScreen__tr tr'>
						<div className='td'>Reminder</div>
							{! isLoading && months.map((month, index) => {
								return(
									<div className='ContractViewScreen__tr__td td' key={index}>{
										contract.schedule && contract.schedule['Reminder'] && contract.schedule['Reminder'].includes(index + 1) ? <TickIcon colour='#6DA726' height='20px' width='20px'/> : ''
									}</div>
								)
							})
						}
					</div>
				</div>

				<div className='ContractViewScreen__scheduleString'>{scheduleString}</div>

				<h2 className='ContractViewScreen__subtitle'>Contract Jobs</h2>

				<BTDButton
					className='ContractViewScreen__button'
					label='Start Contract Job'
					onClick={() => {setJobFormActive(true)}}
					size='small'
				/>

				<div className='ContractViewScreen__list'>
					<ContractJobList
						items={contract ? contract.contract_jobs : []}
						isLoading={isLoading}
					/>
				</div>

				<h2 className='ContractViewScreen__subtitle'>Customer Equipment</h2>

				<BTDButton
					className='ContractViewScreen__button'
					label='Add Equipment'
					onClick={() => {setEquipmentFormActive(true)}}
					size='small'
				/>

				<div className='ContractViewScreen__list'>
					<CustomerEquipmentList
						items={contract ? contract.customer_equipment : []}
						isLoading={isLoading}
					/>
				</div>


				{formActive &&
					<ContractEditScreen
						itemId={contractId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}

				{jobFormActive &&
					<ContractJobCreateScreen
						contract_id={contractId}
						onClose={() => {
							setJobFormActive(false);
						}} 
					/>
				}

				{equipmentFormActive &&
					<CustomerEquipmentCreateScreen
						contract_id={contractId}
						onClose={() => {
							setEquipmentFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setEquipmentFormActive(false);
						}} 
					/>
				}

				
		</ContractViewLayout>

	)

}

export default ContractViewScreen