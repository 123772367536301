// Packages
import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import PurchaseOrderEditScreen from 'modules/PurchaseOrders/screens/Edit';
import PurchaseOrderViewLayout from 'modules/PurchaseOrders/layouts/View';
import BTDTab from 'components/BTDUI/BTDTab';
import PurchaseOrderItemList from 'modules/PurchaseOrderItems/components/List';
import PurchaseOrderItemCreateScreen from 'modules/PurchaseOrderItems/screens/Create';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const PurchaseOrderViewScreen = () => {

	const { purchaseOrderId } = useParams();

	dayjs.extend(AdvancedFormat);

	const { data: purchaseOrder, isLoading, onChange } = useApi('/purchaseorders/' + purchaseOrderId + '?with=supplier,purchaseorderitems,purchaseorderitems.purchaseorder,purchaseorderitems.stock,user,updatedbyuser');
	const { destroy } = useApi('/purchaseorders/' + purchaseOrderId, false);
	const { data: pdfUrl, get: generatePDF } = useApi('/purchaseorders/' + purchaseOrderId + '/generate-pdf', false);

	const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('query');

	const [formActive, setFormActive] = useState(false);
	const [itemFormActive, setItemFormActive] = useState(false);

	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('purchase order', 'Purchase Order Deleted!')) {

			navigate('/purchaseorders');
		}

    }

	const [colour, setColour] = useState('');

	useEffect(() => {
	
		switch (purchaseOrder.status) {
			case 'Open':
				setColour('red');
				break;
			case 'Part Received':
				setColour('orange');
				break;
			case 'Received':
				setColour('green');
				break;
		
			default:
				setColour('grey')
				break;
		}
	}, [purchaseOrder.status])

	useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])


	return (
		<PurchaseOrderViewLayout className='PurchaseOrderViewScreen'>

				<Link className='blue opacity' to={`/purchaseorders${query ? '?query=' + query: ''}`}>{query ? 'Back to search results' : 'Purchase Orders' }{' >'}</Link> 

				<div className='PurchaseOrderViewScreen__titleBar'>
					<div className='PurchaseOrderViewScreen__titleBar__left'>
						<h1 style={{marginRight: '20px'}}>{! isLoading ? 'Purchase Order ' + purchaseOrder.reference : <BTDSkeleton width='200px'/>}</h1>
					</div>
					<div className='PurchaseOrderViewScreen__titleBar__right'>
						<BTDButton
							label='Edit'
							onClick={() => {setFormActive(true)}}
						/>
						<BTDButton
							label='PDF'
							onClick={() => {generatePDF()}}
						/>
						<a id="downloadLink" href="" target='_blank'/>
						<BTDButton
							colour='red'
							label='Delete'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='PurchaseOrderViewScreen__details'>
					<p>Status: </p>
					<p className='semi-bold'>{! isLoading ? <BTDTab colour={colour} text={purchaseOrder.status} /> : <BTDSkeleton width='100px'/>}</p>
					<p>Created: </p>
					<p className='semi-bold'>{! isLoading ? dayjs(purchaseOrder.created_at).format('DD MMMM YYYY') : <BTDSkeleton width='100px'/>}</p>
					<p>Supplier: </p>
					<p><Link className='light-blue' to={purchaseOrder.supplier ? '/suppliers/' + purchaseOrder.supplier.id : ''}>{! isLoading ?  purchaseOrder.supplier ? purchaseOrder.supplier.name : '' : <BTDSkeleton width='100px'/>}</Link></p>
					<p>Reference: </p>
					<p className='semi-bold'>{! isLoading ? purchaseOrder.reference : <BTDSkeleton width='100px'/>}</p>
					<p>Placed by: </p>
					{! isLoading ? <NameDateDisplay date={purchaseOrder.updated_at} user={purchaseOrder.user} /> : <BTDSkeleton width='100px'/>}
					{purchaseOrder.received &&
					<>
						<p>Received: </p>
						<p className='semi-bold'>{! isLoading ? dayjs(purchaseOrder.received).format('dddd Do MMMM YYYY HH:mm') : <BTDSkeleton width='100px'/>}</p>
					</>
					}
					<p>Updated at: </p>
					{! isLoading ? <NameDateDisplay date={purchaseOrder.updated_at} user={purchaseOrder.updated_by_user} /> : <BTDSkeleton width='100px'/>}
				</div>

				<BTDButton
					label='Add item'
					onClick={() => {setItemFormActive(true)}}
					style={{margin: '0 0 15px auto'}}
				/>

				{ ! isLoading && purchaseOrder.purchase_order_items &&

					<PurchaseOrderItemList 
						{...purchaseOrder}
						items={purchaseOrder.purchase_order_items}
						onChange={() => {onChange()}}
						purchaseOrderId={purchaseOrderId}
					/>
				}
				

				{formActive &&
					<PurchaseOrderEditScreen
						itemId={purchaseOrderId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}

				{itemFormActive &&
					<PurchaseOrderItemCreateScreen
						onClose={() => {
							setItemFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setItemFormActive(false);
						}} 
						purchaseOrderId={purchaseOrderId}
						supplier_id={purchaseOrder ? purchaseOrder.supplier_id : ''}
					/>
				}

				
		</PurchaseOrderViewLayout>

	)

}

export default PurchaseOrderViewScreen;