// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import ScreenTitle from 'components/ScreenTitle';
import Header from 'components/Header';
import Footer from 'components/Footer';

const UserLayout = ({ children }) => {

	return (
		<div className='ProcedureLayout'>
			
			<Header/>

			<Container>

				<ScreenTitle title='Users'/>

				{children}

			</Container>

			<Footer/>
        </div>
	)
}

UserLayout.propTypes = {
	children: PropTypes.node,
}

UserLayout.defaultProps = {
	children: null,
}

export default UserLayout;