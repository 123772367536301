// Packages
import PropTypes from 'prop-types'

// Components
import StockForm from 'modules/Stock/components/Form';

// Other
import useApi from 'hooks/useApi';

const StockCreateScreen = ({ onClose }) => {

    const { post: create, errors } = useApi('/stock', false);

    const submit = async (values) => {

        create(values, 'Stock created!', '/stock/');
    }

    return(
        <StockForm 
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

StockCreateScreen.propTypes = {
	onClose: PropTypes.func,
}

StockCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
}

export default StockCreateScreen;