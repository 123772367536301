// packages
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const NameDateDisplay = ({ date, user }) => {

    return(
        <p className='NameDateDisplay'>
            <span className='semi-bold'>{date ? dayjs(date).format('DD MMMM YYYY HH:mm') : ''}</span>
            <span className='semi-bold'>{user ? ' (' + user.firstname + ' ' + user.lastname + ')' : ''}</span>
        </p>
    )
}

NameDateDisplay.propTypes = {
    date: PropTypes.string,
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

NameDateDisplay.defaultProps = {
    date: '',
    user: {}
}

export default NameDateDisplay;