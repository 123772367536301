// Packages
import PropTypes from 'prop-types'

// Components
import TestSpecificationForm from 'modules/TestSpecifications/components/Form';

// Other
import useApi from 'hooks/useApi';

const TestSpecificationCreateScreen = ({ onClose, onSubmit, parent, parentId }) => {

    const { post: createItem, errors } = useApi('/testspecifications',  false)

	const submit = async (values) => {

        if( await createItem({[parent]: parentId, ...values}, 'Test Specification created!') ) {
    
            onSubmit();
        }
    }


    return(
        <TestSpecificationForm 
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

TestSpecificationCreateScreen.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    parent: PropTypes.string,
    parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

TestSpecificationCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
    parent: '',
    parentId: ''
}

export default TestSpecificationCreateScreen;