// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import TestEquipmentListScreen from "modules/TestEquipment/screens/List"
import TestEquipmentViewScreen from "modules/TestEquipment/screens/View"


const TestEquipmentRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<TestEquipmentListScreen />}/>
			<Route path="/:testEquipmentId/*" element={<TestEquipmentViewScreen />}/>
		</Routes>
	)
}

export default TestEquipmentRoutes;