// Packages
import PropTypes from 'prop-types'

// Components
import ContractForm from 'modules/Contracts/components/Form';

// Other
import useApi from 'hooks/useApi';

const ContractCreateScreen = ({ customer_id, onClose }) => {

    const { post: create, errors } = useApi('/contracts', false);

    const submit = async (values) => {

        create(values, 'Contract created!', '/contracts/');
    }

    return(
        <ContractForm 
            customer_id={customer_id}
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

ContractCreateScreen.propTypes = {
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
}

ContractCreateScreen.defaultProps = {
    customer_id: null,
	onClose: () => {alert('onClose is not set!')},
}

export default ContractCreateScreen;