// Packages
import ListItem from 'modules/ContractJobs/components/ListItem';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Other
import './styles.css';

const ContractJobList = ({ isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='ContractJobList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No jobs to show here</h3>
		)
	}


	// Data State
	return (
		<div className='ContractJobList'>

			<div className='ContractJobList__tr ContractJobList__tr-th'>
				<span className='semi-bold'>Date</span>
				<span className='semi-bold'>Customer Order #</span>
				<span className='semi-bold'>Engineer Report #</span>
				<span className='semi-bold centre'>Equipment Count</span>
				<span className='semi-bold centre'>Readings Reg</span>
				<span className='semi-bold centre'>Status</span>
			</div>

			{items.map((item, index) => {

				return (
						<Link className='ContractJobList__tr' to={'/contracts/' + item.contract_id + '/jobs/' + item.id} key={index}>
							<ListItem 
								{...item} 
								isLoading={false}
								onChange={() => {onChange()}}
							/>
						</Link>
				)
				
			})}

		</div>
	)
}

ContractJobList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

ContractJobList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default ContractJobList;