// Packages
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import ProcedureEditScreen from 'modules/Procedures/screens/Edit';
import ProcedureViewLayout from 'modules/Procedures/layouts/View';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const ProcedureViewScreen = () => {

	const { procedureId } = useParams();

	const { data: procedure, isLoading, onChange } = useApi('/procedures/' + procedureId + '?with=createdbyuser,updatedbyuser');
	const { destroy } = useApi('/procedures/' + procedureId, false);

	const [formActive, setFormActive] = useState(false);
	
	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('procedure', 'Procedure Deleted!')) {

			navigate('/procedures');
		}

    }



	return (
		<ProcedureViewLayout className='ProcedureViewScreen'>

				<div className='ProcedureViewScreen__titleBar'>
					<div className='ProcedureViewScreen__titleBar__left'>
						<h1 style={{marginRight: '20px'}}>{! isLoading ? procedure.name : <BTDSkeleton width='200px'/>}</h1>
					</div>
					<div className='ProcedureViewScreen__titleBar__right'>
						<BTDButton
							label='Edit'
							onClick={() => {setFormActive(true)}}
							style={{marginRight: '20px'}}
						/>
						<BTDButton
							colour='red'
							label='Archive'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='ProcedureViewScreen__details'>
					<p>Number: </p>
					<p className='semi-bold'>{! isLoading ? procedure.number : <BTDSkeleton width='100px'/>}</p>
					<p>Procedure: </p>
					<p className='semi-bold'>{! isLoading ? procedure.name : <BTDSkeleton width='100px'/>}</p>
					<p>Created at: </p>
					{! isLoading ? <NameDateDisplay date={procedure.created_at} user={procedure.created_by_user} /> : <BTDSkeleton width='100px'/>}
					<p>Updated at: </p>
					{! isLoading ? <NameDateDisplay date={procedure.updated_at} user={procedure.updated_by_user} /> : <BTDSkeleton width='100px'/>}
				</div>


				{formActive &&
					<ProcedureEditScreen
						itemId={procedureId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}

				
		</ProcedureViewLayout>

	)

}

export default ProcedureViewScreen;