// Packages
import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import CustomerEditScreen from 'modules/Customers/screens/Edit';
import CustomerViewLayout from 'modules/Customers/layouts/View';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import ContactList from 'modules/Contacts/components/List';
import ContactCreateScreen from 'modules/Contacts/screens/Create';
import NoteList from 'modules/Notes/components/List';
import NoteCreateScreen from 'modules/Notes/screens/Create';
import CustomerStockPriceList from 'modules/CustomerStockPrices/components/List';
import CustomerStockPriceCreateScreen from 'modules/CustomerStockPrices/screens/Create';
import CustomerContractList from 'modules/Customers/components/ContractList';
import ContractCreateScreen from 'modules/Contracts/screens/Create';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const CustomerViewScreen = () => {

	const { customerId } = useParams();

	const { data: customer, isLoading, onChange } = useApi('/customers/' + customerId + '?with=contacts,notes,notes.lastupdateduser,customerstockprices,contracts,createdbyuser,updatedbyuser');
	const { destroy } = useApi('/customers/' + customerId, false);

	const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('query');

	const [formActive, setFormActive] = useState(false);
	const [contactFormActive, setContactFormActive] = useState(false);
	const [contractFormActive, setContractFormActive] = useState(false);
	const [noteFormActive, setNoteFormActive] = useState(false);
	const [stockPriceFormActive, setStockPriceFormActive] = useState(false);
	const [activeTab, setActiveTab] = useState('people');

	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('customer', 'Customer Deleted!')) {

			navigate('/customers');
		}
    }



	return (
		<CustomerViewLayout className='CustomerViewScreen'>

				<Link className='blue opacity' to={`/customers${query ? '?query=' + query: ''}`}>{query ? 'Back to search results' : 'Customers'}{' >'}</Link> 

				<div className='CustomerViewScreen__titleBar'>
					<div className='CustomerViewScreen__titleBar__left'>
						<h1 style={{marginRight: '20px'}}>{! isLoading ? customer.name : <BTDSkeleton width='200px'/>}</h1>
						<h2 className='opacity'> {! isLoading ? customer.division ? ' > ' + customer.division : '' : <BTDSkeleton width='150px'/>}</h2>
					</div>
					<div className='CustomerViewScreen__titleBar__right'>
						<BTDButton
							label='Edit'
							onClick={() => {setFormActive(true)}}
							style={{marginRight: '20px'}}
						/>
						<BTDButton
							colour='red'
							label='Delete'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='CustomerViewScreen__details'>
					<p>Company name: </p>
					<p className='semi-bold'>{! isLoading ? customer.name : <BTDSkeleton width='100px'/>}</p>
					<p>Telephone: </p>
					<p className='semi-bold'>{! isLoading ? customer.telephone : <BTDSkeleton width='100px'/>}</p>
					<p>Reference: </p>
					<p className='semi-bold'>{! isLoading ? customer.reference : <BTDSkeleton width='100px'/>}</p>
					<p>Email: </p>
					<Link className='CustomerViewScreen__details__email light-blue semi-bold' to={'mailto:' + customer.email}>{! isLoading ? customer.email : <BTDSkeleton width='100px'/>}</Link>
					<p>Address: </p>
					<div>
						<p className='semi-bold'>{! isLoading ? customer.address1 : <BTDSkeleton width='100px'/>}</p>
						<p className='semi-bold'>{! isLoading ? customer.address2 : <BTDSkeleton width='100px'/>}</p>
						<p className='semi-bold'>{! isLoading ? customer.city : <BTDSkeleton width='100px'/>}</p>
						<p className='semi-bold'>{! isLoading ? customer.county : <BTDSkeleton width='100px'/>}</p>
						<p className='semi-bold'>{! isLoading ? customer.postcode : <BTDSkeleton width='100px'/>}</p>
					</div>
					<p></p>
					<p></p>
					<p>Created at: </p>
					{! isLoading ? <NameDateDisplay date={customer.created_at} user={customer.created_by_user} /> : <BTDSkeleton width='100px'/>}
					<p>Updated at: </p>
					{! isLoading ? <NameDateDisplay date={customer.updated_at} user={customer.updated_by_user} /> : <BTDSkeleton width='100px'/>}
				</div>

				<div className="tabs">
					<ul className="tabs__list">
						<li className={`tabs__list__item ${ activeTab === 'people' ? 'active' : ''}`} onClick={() => {setActiveTab('people')}}>People</li>
						<li className={`tabs__list__item ${ activeTab === 'notes' ? 'active' : ''}`} onClick={() => {setActiveTab('notes')}}>Notes</li>
						<li className={`tabs__list__item ${ activeTab === 'contracts' ? 'active' : ''}`} onClick={() => {setActiveTab('contracts')}}>Contracts</li>
						<li className={`tabs__list__item ${ activeTab === 'stock' ? 'active' : ''}`} onClick={() => {setActiveTab('stock')}}>Stock Prices</li>
					</ul>
				</div>

				<div className='tabs__select'>
					<BTDSelectField
						label='Select Tab'
						onChange={(value) => {setActiveTab(value)}}
						options={[
							{id: 'people', name: 'People'},
							{id: 'notes', name: 'Notes'},
							{id: 'contracts', name: 'Contracts'},
							{id: 'stock', name: 'Stock Prices'},
						]}
						style={{marginBottom: '30px'}}
						value={activeTab}
					/>
				</div>

				{activeTab === 'people' &&
					<>
						<BTDButton
							label='Create Contact'
							onClick={() => {setContactFormActive(true)}}
							style={{marginBottom: '30px'}}
						/>

						<ContactList
							items={! isLoading ? customer.contacts : []}
							onChange={() => {onChange()}}
							parent='customer_id'
							parentId={! isLoading ? customer.id : ''}
						/>
					</>
				}

				{activeTab === 'notes' &&
					<>
						<BTDButton
							label='Add Note'
							onClick={() => {setNoteFormActive(true)}}
							style={{marginBottom: '30px'}}
						/>

						<NoteList
							items={! isLoading ? customer.notes : []}
							onChange={() => {onChange()}}
							parent='customer_id'
							parentId={! isLoading ? customer.id : ''}
						/>
					</>
				}

				{activeTab === 'contracts' &&
					<>
						<BTDButton
							label='Create Contract'
							onClick={() => {setContractFormActive(true)}}
							style={{marginBottom: '30px'}}
						/>

						<CustomerContractList
							items={! isLoading ? customer.contracts : []}
							onChange={() => {onChange()}}
						/>
					</>
				}

				{activeTab === 'stock' &&
					<>
						<BTDButton
							label='Add Item'
							onClick={() => {setStockPriceFormActive(true)}}
							style={{marginBottom: '30px'}}
						/>

						
						<CustomerStockPriceList
							items={! isLoading ? customer.customer_stock_prices : []}
							onChange={() => {onChange()}}
							customerId={! isLoading ? customer.id : ''}
						/>
					</>
				}

				{formActive &&
					<CustomerEditScreen
						itemId={customerId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}

				{contactFormActive &&
					<ContactCreateScreen
						onClose={() => {
							setContactFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setContactFormActive(false);
						}} 
						parent='customer_id'
						parentId={! isLoading ? customer.id : ''}
					/>
				}

				{noteFormActive &&
					<NoteCreateScreen
						onClose={() => {
							setNoteFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setNoteFormActive(false);
						}} 
						parent='customer_id'
						parentId={! isLoading ? customer.id : ''}
					/>
				}

				{contractFormActive &&
					<ContractCreateScreen
						customer_id={! isLoading ? customer.id : null}
						onClose={() => {
							setContractFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setContractFormActive(false);
						}} 
					/>
				}


				{stockPriceFormActive &&
					<CustomerStockPriceCreateScreen
						onClose={() => {
							setStockPriceFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setStockPriceFormActive(false);
						}} 
						customerId={! isLoading ? customer.id : ''}
					/>
				}
				
		</CustomerViewLayout>

	)

}

export default CustomerViewScreen