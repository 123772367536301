// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import ContractListScreen from "modules/Contracts/screens/List"
import ContractScheduleScreen from "modules/Contracts/screens/Schedule"
import ContractViewScreen from "modules/Contracts/screens/View"


const ContractRoutes = () => 
{
	return (
		<Routes>
			<Route path="/schedule/*" element={<ContractScheduleScreen />}/>
			<Route path="/*" element={<ContractListScreen />}/>
			<Route path="/:contractId/*" element={<ContractViewScreen />}/>
		</Routes>
	)
}

export default ContractRoutes;