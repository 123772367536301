// Packages
import ListItem from 'modules/Contacts/components/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const ContactList = ({ isLoading, items, onChange, parent, parentId }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='ContactList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No contacts to show here</h3>
		)
	}


	// Data State
	return (
		<div className='ContactList'>

			{items.map((item, index) => {

				return (
					<div key={index}>
						<ListItem 
							{...item} 
							isLoading={false}
							onChange={() => {onChange()}}
							parent={parent}
							parentId={parentId}
						/>
					</div>
				)
				
			})}
	
		</div>
	)
}

ContactList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
	parent: PropTypes.string,
    parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ContactList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
	parent: '',
    parentId: ''
}

export default ContactList;