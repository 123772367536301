// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import Container from 'components/BTDUI/Container';
import Header from 'components/Header';
import ScreenTitle from 'components/ScreenTitle';
import Footer from 'components/Footer';

const ContractScheduleLayout = ({ children }) => {

	return (
		<div className='ContractScheduleLayout'>
			
			<Header/>

				<Container>

					<Link className='blue opacity' to='/contracts' style={{display: 'block', marginBottom: '20px'}}>Contracts {'>'}</Link> 

					<ScreenTitle title='Contract Schedule'/>

					{children}

			</Container>

			<Footer/>
        </div>
	)
}

ContractScheduleLayout.propTypes = {
	children: PropTypes.node,
}

ContractScheduleLayout.defaultProps = {
	children: null,
}

export default ContractScheduleLayout;