// Packages
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Context
import { useUser } from 'context/User';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import UserEditScreen from 'modules/Users/screens/Edit';
import UserViewLayout from 'modules/Users/layouts/View';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';
import dayjs from 'dayjs';

const UserViewScreen = () => {

	const { userId } = useParams();

	const { user } = useUser();

	const { data, isLoading, onChange, destroy } = useApi('/users/' + userId + '?with=createdbyuser,updatedbyuser');

	const [formActive, setFormActive] = useState(false);
	
	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('user', 'User Deleted!')) {

			navigate('/users');
		}
    }


	if( ! user.is_admin ) {

        navigate('/customers')
    }


	return (
		<UserViewLayout className='UserViewScreen'>

			<div className='UserViewScreen__titleBar'>
				<div className='UserViewScreen__titleBar__left'>
					<h1 style={{marginRight: '20px'}}>{! isLoading ? data.firstname + ' ' + data.lastname : <BTDSkeleton width='200px'/>}</h1>
				</div>
				<div className='UserViewScreen__titleBar__right'>
					<BTDButton
						label='Edit'
						onClick={() => {setFormActive(true)}}
						style={{marginRight: '20px'}}
					/>
					<BTDButton
						colour='red'
						label='Delete'
						onClick={() => {deleteItem()}}
					/>
				</div>
			</div>

			<div className='UserViewScreen__details'>
				<p>Name: </p>
				<p className='semi-bold'>{! isLoading ? data.firstname + ' ' + data.lastname: <BTDSkeleton width='100px'/>}</p>
				<p>Initials: </p>
				<p className='semi-bold'>{! isLoading ? data.initials : <BTDSkeleton width='100px'/>}</p>
				<p>Username: </p>
				<p className='semi-bold'>{! isLoading ? data.username : <BTDSkeleton width='100px'/>}</p>
				<p>Email: </p>
				<p className='semi-bold'>{! isLoading ? data.email : <BTDSkeleton width='100px'/>}</p>
				<p>Type: </p>
				<p className='semi-bold'>{! isLoading ? data.type : <BTDSkeleton width='100px'/>}</p>
				<p>User Management: </p>
				<p className='semi-bold'>{! isLoading ? (data.is_admin ? 'Yes' : 'No') : <BTDSkeleton width='100px'/>}</p>
				<p>Created at: </p>
				{! isLoading ? <NameDateDisplay date={data.created_at} user={data.created_by_user} /> : <BTDSkeleton width='100px'/>}
				<p>Updated at: </p>
				{! isLoading ? <NameDateDisplay date={data.updated_at} user={data.updated_by_user} /> : <BTDSkeleton width='100px'/>}
			</div>


			{formActive &&
				<UserEditScreen
					itemId={userId}
					onClose={() => {
						setFormActive(false);
					}} 
					onSubmit={() => {
						onChange();
						setFormActive(false);
					}} 
				/>
			}
				
		</UserViewLayout>

	)

}

export default UserViewScreen;