// Packages
import PropTypes from 'prop-types'

// Components
import CustomerStockPriceForm from 'modules/CustomerStockPrices/components/Form';

// Other
import useApi from 'hooks/useApi';

const CustomerStockPriceCreateScreen = ({ onClose, onSubmit, customerId }) => {

    const { post: createItem, errors } = useApi('/customerstockprices',  false);

	const submit = async (values) => {

        if( await createItem({customer_id: customerId, ...values}, 'Customer Stock Price created!') ) {
    
            onSubmit();
        }
    }


    return(
        <CustomerStockPriceForm 
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

CustomerStockPriceCreateScreen.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

CustomerStockPriceCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
    customerId: ''
}

export default CustomerStockPriceCreateScreen;