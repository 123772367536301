// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import EmailIcon from 'components/BTDUI/icons/EmailIcon';
import PhoneIcon from 'components/BTDUI/icons/PhoneIcon';

// Other
import './styles.css'

const CustomerListItem = ({ division, email, isLoading, name, primary_contact, reference, telephone }) => {

	return (
		<div className='CustomerListItem'>
			<div style={{gridArea: 'name'}}>
				<h6 className='blue inline-block' style={{marginRight: '20px'}}>{! isLoading ? reference : <BTDSkeleton width='50%'/>}</h6> 
				<p className='medium opacity blue inline-block'>{! isLoading ? division : <BTDSkeleton width='50%'/>}</p>
				<h3 className='blue'>{! isLoading ? name : <BTDSkeleton width='50%'/>}</h3>
			</div>
			<div style={{gridArea: 'contact'}}>
				<p className='blue'>{! isLoading ? primary_contact ? primary_contact.name : '' : <BTDSkeleton width='50%'/>}</p>
			</div>
			<div style={{display: 'grid', rowGap: '8px', gridArea: 'details'}}>
				{telephone &&
					<div>
						<PhoneIcon colour='#40506B' height='14px' style={{marginRight: '4px', transform: 'translateY(0px)'}} />
						<Link className='blue medium'>{! isLoading ? telephone : <BTDSkeleton width='50%'/>}</Link>
					</div>
				}
				{email &&
				<div className='CustomerListItem__email'>
					<EmailIcon colour='#40506B' height='14px' style={{marginRight: '4px', transform: 'translateY(3px)'}} />
					<Link 
						className='light-blue medium' 
						onClick={(e) => {e.stopPropagation()}}
						to={'mailto:' + email} 
					>
						{! isLoading ? email : <BTDSkeleton width='50%'/>}
					</Link>
				</div>
				}
			</div>

		</div>
	)
}

CustomerListItem.propTypes = {
	division: PropTypes.string, 
	email: PropTypes.string, 
	isLoading: PropTypes.bool,
	name: PropTypes.string, 
	primary_contact: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	reference: PropTypes.string, 
	telephone: PropTypes.string,
}

CustomerListItem.defaultProps = {
	division: '',
	email: '',
	isLoading: false,
	name: '',
	primary_contact: {},
	reference: '',
}

export default CustomerListItem;