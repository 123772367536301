// Packages
import PropTypes from 'prop-types';

const LetterIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="LetterIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Customers---List" transform="translate(-805, -207)" fill={colour} fillRule="nonzero">
                        <g id="letter-b" transform="translate(805, 207)">
                            <path d="M11.09476,7.91632 C11.09476,7.37136 10.72156,7.00224 10.11664,7.00224 L8.65468,7.00224 L8.65468,8.83024008 L10.1166,8.83024008 C10.7216,8.83044 11.09476,8.46128 11.09476,7.91632 L11.09476,7.91632 Z" id="Path"></path>
                            <path d="M10,0 C4.47716,0 0,4.47716 0,10 C0,15.52284 4.47716,20 10,20 C15.52284,20 20,15.52284 20,10 C20,4.47716 15.52284,0 10,0 Z M10.7166,15.25004 L5.87696,15.25004 L5.87696,4.75004 L10.61972,4.75004 C12.48156,4.75004 13.84012,5.88604 13.84012,7.43732 C13.84012,8.37556 13.31464,9.21964 12.51244,9.70564 L12.51276,9.70564 C13.50216,10.17472 14.12304,11.11416 14.12304,12.26004 C14.12304,13.9614 12.6548,15.25004 10.7166,15.25004 Z" id="Shape"></path>
                            <path d="M10.22596,10.96556 L8.6546,10.96556 L8.6546,12.87508 L10.22596,12.87508 C10.85236,12.87508 11.26196,12.48236 11.26196,11.91596 C11.26192,11.35828 10.85236,10.96556 10.22596,10.96556 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

LetterIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

LetterIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default LetterIcon;