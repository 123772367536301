// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// other
import './styles.css';

const BTDTab = ({ colour, style, text, text_format }) => {

    const [string, setString] = useState('');

    useEffect(() => {

        switch (text_format) {
            case 'lowercase':
                setString(text.toLowerCase())
                break;
            case 'uppercase':
                setString(text.toUpperCase())
                break;
            case 'ucfirst':
                let string = text.replace(/\b\w/g, function (match) {
                    return match.toUpperCase();
                });
                setString(string)
                break;
        
            default:
                setString(string)
                break;
        }
    }, [text, text_format])

    return(
        <div className={`BTDTab BTDTab--colour-${colour}`} style={style}>{string}</div>
    )
}

BTDTab.propTypes = {
    colour: PropTypes.string,
    style: PropTypes.object,
    text: PropTypes.string,
    text_format: PropTypes.string
}

BTDTab.defaultProps = {
    colour: '',
    style: {},
    text: '',
    text_format: 'ucfirst'
}

export default BTDTab;