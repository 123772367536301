// Packages
import ListItem from 'modules/CustomerEquipment/components/ListItem';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Other
import './styles.css';

const CustomerEquipmentList = ({ isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='CustomerEquipmentList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No customer equipment to show here</h3>
		)
	}


	// Data State
	return (
		<div className='CustomerEquipmentList'>

			<div className='CustomerEquipmentList__header tr'>
				<span className='semi-bold'>Ref</span>
				<span className='semi-bold'>Serial</span>
				<span className='semi-bold'>Manufacturer</span>
				<span className='semi-bold'>Model #</span>
				<span className='semi-bold centre'>Type</span>
			</div>

			{items.map((item, index) => {

				return (
						<Link className='CustomerEquipmentList__link' to={'/customerequipment/' + item.id} key={index}>
							<ListItem 
								{...item} 
								isLoading={false}
								onChange={() => {onChange()}}
							/>
						</Link>
				)
				
			})}

		</div>
	)
}

CustomerEquipmentList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

CustomerEquipmentList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default CustomerEquipmentList;