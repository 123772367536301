// Packages
import PropTypes from 'prop-types';

const EmailIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="EmailIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg width={width} height={height} viewBox="0 0 20 16" version="1.1">
                <title>mail</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Customers---List" transform="translate(-921, -498)" fill={colour} fillRule="nonzero">
                        <g id="mail" transform="translate(921, 498)">
                            <path d="M1.60627841,0.890223447 L7.61700315,6.90355551 C8.92795995,8.2119919 11.0709102,8.21312174 12.3829534,6.90355551 L18.3936781,0.890223447 C18.4542112,0.829690338 18.4453898,0.730004085 18.3751662,0.681030062 C17.7621001,0.253474244 17.0161914,-2.47014274e-15 16.2127046,-2.47014274e-15 L3.78729543,-2.47014274e-15 C2.98376513,-2.47014274e-15 2.23785644,0.253517699 1.62479033,0.681030062 C1.55456671,0.730004085 1.5457453,0.829690338 1.60627841,0.890223447 Z M0,3.78725198 C0,3.15541322 0.156656035,2.55842553 0.432596623,2.03370386 C0.475747647,1.95161697 0.585124412,1.93501708 0.650698325,2.00059099 L6.585333,7.93522566 C8.4646405,9.81727084 11.5342297,9.81835722 13.414667,7.93522566 L19.3493017,2.00059099 C19.4148756,1.93501708 19.5242524,1.95161697 19.5674034,2.03370386 C19.8433005,2.55842553 20,3.15545667 20,3.78725198 L20,11.6933627 C20,13.7832541 18.2999887,15.4806581 16.2127046,15.4806581 L3.78729543,15.4806581 C1.70005475,15.4806581 0,13.7832541 0,11.6933627 L0,3.78725198 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>

        </div>
    )
}

EmailIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EmailIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default EmailIcon;