// Packages
import ListItem from 'modules/TestResults/components/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const TestResultsList = ({ contractJob, isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='TestResultsList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No test results to show here</h3>
		)
	}


	// Data State
	return (
		<div className='TestResultsList'>

			<div className='TestResultsList__header tr'>
				<span className='semi-bold'>Reference</span>
				<span className='semi-bold'>Man, Model & Serial</span>
				<span className='semi-bold centre'>Status</span>
				<span className='semi-bold centre'>Certificates</span>
				<span className='semi-bold'></span>
				<span className='semi-bold'></span>
			</div>

			{items.map((item, index) => {

				return (
						<ListItem 
							{...item} 
							contractJob={contractJob}
							isLoading={false}
							key={index}
							onChange={() => {onChange()}}
						/>
				)
				
			})}

		</div>
	)
}

TestResultsList.propTypes = {
	contractJob: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

TestResultsList.defaultProps = {
	contractJob: {},
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default TestResultsList;