// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import useApi from 'hooks/useApi';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';


const CustomerStockPriceForm = ({ customer_stock_price, errors, onClose, onSubmit, type, }) => {

    const { data: stock, isLoading: stockLoading } = useApi('/stock?per_page=9999999');

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    

    // Load Item values into form
	useEffect(() => {

		if ( customer_stock_price )
		{
            setData(customer_stock_price);
		}

	}, [customer_stock_price]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create customer stock price' : 'Edit customer stock price'}</h3>
        
            <BTDAutoCompleteField
                error={errors && errors.stock_id ? errors.stock_id : null} 
                isLoading={stockLoading}
                label='Stock item'
                onChange={(value) => {
                    setData({...data, stock_id: value});
                    setChangedData({...changedData, stock_id: value});
                }} 
                options={stock ?? []}
                optionLabel={['reference', 'name']}
                required={true}
                tabIndex={1}
                value={data.stock_id ?? ''} 
            />

            <BTDNumberField
                error={errors && errors.price ? errors.price : null} 
                label='Price'
                onChange={(value) => {
                    setData({...data, price: value});
                    setChangedData({...changedData, price: value});
                }} 
                required={true}
                step={0.01}
                style={{marginBottom: '50px'}}
                tabIndex={2}
                value={data.price ?? ''} 
            />


            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save customer stock price' : 'Update customer stock price'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

CustomerStockPriceForm.propTypes = {
	errors: PropTypes.object,
	customer_stock_price: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

CustomerStockPriceForm.defaultProps = {
    errors: {},
    customer_stock_price: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default CustomerStockPriceForm;