// Packages
import { useState } from 'react';

// Context
import { useUser } from 'context/User';

// Components
import Container from 'components/BTDUI/Container';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import ScreenTitle from 'components/ScreenTitle';
import BTDButton from 'components/BTDUI/BTDButton';
import Header from 'components/Header';

// Other
import useApi from 'hooks/useApi';
import './styles.css';


const ProfileScreen = () => {

    const { user } = useUser();

    const { data, errors, put } = useApi(`/users/${user ? user.id : ''}/password`, false);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    return(
        <div className='ProfileScreen'>

            <Header/>

            <Container>

                <ScreenTitle title={`Profile for ${user ? user.firstname + ' ' + user.lastname : ''}`}/>

                <h2 className='blue medium'>Your Details</h2>
                
                <div className='ProfileScreen__details'>
					<p>Your Name: </p>
					<p className='semi-bold'>{user ? user.firstname + ' ' + user.lastname : ''}</p>
					<p>Your Username: </p>
					<p className='semi-bold'>{user ? user.username : ''}</p>
				</div>

                <h2 className='blue medium'>Change Your Password</h2>

                <BTDTextField
                    error={errors && errors.password ? errors.password : null} 
                    label='Current Password'
                    onChange={(value) => {setPassword(value)}} 
                    required={true}
                    style={{maxWidth: '400px'}}
                    tabIndex={1}
                    type='password'
                    value={password ?? ''} 
                />

                <BTDTextField
                    label='New Password'
                    onChange={(value) => {setNewPassword(value)}} 
                    required={true}
                    style={{maxWidth: '400px'}}
                    tabIndex={2}
                    type='password'
                    value={newPassword ?? ''} 
                />

                <BTDTextField
                    label='Confirm New Password'
                    onChange={(value) => {setConfirmPassword(value)}} 
                    required={true}
                    style={{marginBottom: '5px', maxWidth: '400px'}}
                    tabIndex={3}
                    type='password'
                    value={confirmPassword ?? ''} 
                />

                {newPassword.length !== 0 &&  confirmPassword.length !== 0 && newPassword !== confirmPassword &&
                    <p className='red'>Passwords must match!</p>
                }
                {newPassword.length !== 0 &&  confirmPassword.length !== 0 && newPassword === confirmPassword &&
                    <p className='green'>Passwords match!</p>
                }

                <BTDButton
                    disabled={newPassword.length !== 0 &&  confirmPassword.length !== 0 && newPassword === confirmPassword ? false : true}
                    label='Change Password'
                    onClick={() => {put({password: password, new_password: newPassword}, 'Password changed!')}}
                    style={{marginTop: '30px'}}
                />

            </Container>
        </div>

    );
}

export default ProfileScreen;