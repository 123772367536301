// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import ManufacturerListScreen from "modules/Manufacturers/screens/List"
import ManufacturerViewScreen from "modules/Manufacturers/screens/View"


const ManufacturerRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<ManufacturerListScreen />}/>
			<Route path="/:manufacturerId/*" element={<ManufacturerViewScreen />}/>
		</Routes>
	)
}

export default ManufacturerRoutes;