// Packages
import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import StockEditScreen from 'modules/Stock/screens/Edit';
import StockViewLayout from 'modules/Stock/layouts/View';
import NoteList from 'modules/Notes/components/List';
import NoteCreateScreen from 'modules/Notes/screens/Create';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const StockViewScreen = () => {

	const { stockId } = useParams();

	const { data: stock, isLoading, onChange } = useApi('/stock/' + stockId + '?with=supplier,notes,notes.lastupdateduser,createdbyuser,updatedbyuser');
	const { destroy } = useApi('/stock/' + stockId, false);

	const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('query');

	const [formActive, setFormActive] = useState(false);
	const [noteFormActive, setNoteFormActive] = useState(false);

	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('stock', 'Stock Deleted!')) {

			navigate('/stock');
		}

    }


	return (
		<StockViewLayout className='StockViewScreen'>

			<Link className='blue opacity' to={`/stock${query ? '?query=' + query: ''}`}>{query ? 'Back to search results' : 'Stock'}{' >'}</Link> 

			<div className='StockViewScreen__titleBar'>
				<div className='StockViewScreen__titleBar__left'>
					<h1 style={{marginRight: '20px'}}>{! isLoading ? stock.name : <BTDSkeleton width='200px'/>}</h1>
				</div>
				<div className='StockViewScreen__titleBar__right'>
					<BTDButton
						label='Edit'
						onClick={() => {setFormActive(true)}}
						style={{marginRight: '20px'}}
					/>
					<BTDButton
						colour='red'
						label='Delete'
						onClick={() => {deleteItem()}}
					/>
				</div>
			</div>

			<div className='StockViewScreen__details'>
				<p>Stock name: </p>
				<p className='semi-bold'>{! isLoading ? stock.name : <BTDSkeleton width='100px'/>}</p>
				<p>Created: </p>
				<p className='semi-bold'>{! isLoading ? dayjs(stock.created_at).format('DD MMMM YYYY') : <BTDSkeleton width='100px'/>}</p>
				<p>Supplier: </p>
				<p><Link className='semi-bold light-blue' to={'/suppliers/' + stock.supplier_id}>{! isLoading ?  stock.supplier ? stock.supplier.name : '' : <BTDSkeleton width='100px'/>}</Link></p>
				<p>Reference: </p>
				<p className='semi-bold'>{! isLoading ? stock.reference : <BTDSkeleton width='100px'/>}</p>
				<p>Supplier Part Number: </p>
				<p className='semi-bold'>{! isLoading ? stock.supplier_reference : <BTDSkeleton width='100px'/>}</p>
				<p>Stock Quantity: </p>
				<p className='semi-bold'>{! isLoading ? stock.quantity : <BTDSkeleton width='100px'/>}</p>
				<p>Cost Price: </p>
				<p className='semi-bold'>{! isLoading ? '£' + stock.cost_price : <BTDSkeleton width='100px'/>}</p>
				<p>Sales Price: </p>
				<p className='semi-bold'>{! isLoading ? '£' + stock.price : <BTDSkeleton width='100px'/>}</p>
				<p>Created at: </p>
				{! isLoading ? <NameDateDisplay date={stock.created_at} user={stock.created_by_user} /> : <BTDSkeleton width='100px'/>}
				<p>Updated at: </p>
				{! isLoading ? <NameDateDisplay date={stock.updated_at} user={stock.updated_by_user} /> : <BTDSkeleton width='100px'/>}
			</div>

			<BTDButton
				label='Add Note'
				onClick={() => {setNoteFormActive(true)}}
				style={{marginBottom: '30px'}}
			/>

			<NoteList
				items={! isLoading ? stock.notes : []}
				onChange={() => {onChange()}}
				parent='stock_id'
				parentId={! isLoading ? stock.id : ''}
			/>
			

			{formActive &&
				<StockEditScreen
					itemId={stockId}
					onClose={() => {
						setFormActive(false);
					}} 
					onSubmit={() => {
						onChange();
						setFormActive(false);
					}} 
				/>
			}

			{noteFormActive &&
				<NoteCreateScreen
					onClose={() => {
						setNoteFormActive(false);
					}} 
					onSubmit={() => {
						onChange();
						setNoteFormActive(false);
					}} 
					parent='stock_id'
					parentId={! isLoading ? stock.id : ''}
				/>
			}
				
		</StockViewLayout>

	)

}

export default StockViewScreen