// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';


const CustomerForm = ({ errors, customer, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    
    // Load Item values into form
	useEffect(() => {

		if ( customer )
		{
            setData(customer);
		}

	}, [customer]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create customer' : 'Edit customer'}</h3>
        
            <BTDTextField 
                error={errors && errors.name ? errors.name : null} 
                label='Name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                tabIndex={1}
                value={data.name ?? ''} 
            />

            <div className='form__grid-2'>
                <BTDTextField 
                    error={errors && errors.reference ? errors.reference : null} 
                    label='Reference'
                    onChange={(value) => {
                        setData({...data, reference: value});
                        setChangedData({...changedData, reference: value});
                    }} 
                    required={true}
                    tabIndex={2}
                    value={data.reference ?? ''} 
                />

                <BTDTextField 
                    error={errors && errors.division ? errors.division : null} 
                    label='Division'
                    onChange={(value) => {
                        setData({...data, division: value});
                        setChangedData({...changedData, division: value});
                    }} 
                    tabIndex={3}
                    value={data.division ?? ''} 
                />
            </div>

            {type === 'create' &&
                <div className='form__grid-2'>
                    <BTDTextField 
                        error={errors && errors.contact1 ? errors.contact1 : null} 
                        label='Contact 1'
                        onChange={(value) => {
                            setData({...data, contact1: value});
                            setChangedData({...changedData, contact1: value});
                        }} 
                        tabIndex={4}
                        value={data.contact1 ?? ''} 
                    />

                    <BTDTextField 
                        error={errors && errors.contact2 ? errors.contact2 : null} 
                        label='Contact 2'
                        onChange={(value) => {
                            setData({...data, contact2: value});
                            setChangedData({...changedData, contact2: value});
                        }} 
                        tabIndex={5}
                        value={data.contact2 ?? ''} 
                    />
                </div>
            }


            <div className='form__grid-2'>
                <BTDTextField 
                    error={errors && errors.address1 ? errors.address1 : null} 
                    label='Address 1'
                    onChange={(value) => {
                        setData({...data, address1: value});
                        setChangedData({...changedData, address1: value});
                    }} 
                    tabIndex={6}
                    value={data.address1 ?? ''} 
                />
                <BTDTextField 
                    error={errors && errors.address2 ? errors.address2 : null} 
                    label='Address 2'
                    onChange={(value) => {
                        setData({...data, address2: value});
                        setChangedData({...changedData, address2: value});
                    }} 
                    tabIndex={7}
                    value={data.address2 ?? ''} 
                />
            </div>

            <div className='form__grid-2'>

                <BTDTextField 
                    error={errors && errors.city ? errors.city : null} 
                    label='City'
                    onChange={(value) => {
                        setData({...data, city: value});
                        setChangedData({...changedData, city: value});
                    }} 
                    tabIndex={8}
                    value={data.city ?? ''} 
                />
                <BTDTextField 
                    error={errors && errors.county ? errors.county : null} 
                    label='County'
                    onChange={(value) => {
                        setData({...data, county: value});
                        setChangedData({...changedData, county: value});
                    }} 
                    tabIndex={9}
                    value={data.county ?? ''} 
                />
            </div>

            <div className='form__grid-2'>

                <BTDTextField 
                    error={errors && errors.postcode ? errors.postcode : null} 
                    label='Postcode'
                    onChange={(value) => {
                        setData({...data, postcode: value});
                        setChangedData({...changedData, postcode: value});
                    }} 
                    tabIndex={10}
                    value={data.postcode ?? ''} 
                />
                <BTDTextField 
                    error={errors && errors.telephone ? errors.telephone : null} 
                    label='Telephone'
                    onChange={(value) => {
                        setData({...data, telephone: value});
                        setChangedData({...changedData, telephone: value});
                    }} 
                    tabIndex={11}
                    value={data.telephone ?? ''} 
                />
            </div>

            <BTDTextField 
                error={errors && errors.email ? errors.email : null} 
                label='Email'
                onChange={(value) => {
                    setData({...data, email: value});
                    setChangedData({...changedData, email: value});
                }} 
                tabIndex={12}
                value={data.email ?? ''} 
            />

            {type === 'create' &&
                <BTDTextareaField
                    error={errors && errors.notes ? errors.notes : null} 
                    label='Notes'
                    onChange={(value) => {
                        setData({...data, notes: value});
                        setChangedData({...changedData, notes: value});
                    }} 
                    rows={3}
                    tabIndex={13}
                    value={data.notes ?? ''} 
                />
            }

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save customer' : 'Update customer'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

CustomerForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

CustomerForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default CustomerForm;