// Packages
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import ContractJobEditScreen from 'modules/ContractJobs/screens/Edit';
import ContractJobViewLayout from 'modules/ContractJobs/layouts/View';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import TestResultsList from 'modules/TestResults/components/List';
import ContractJobAddEquipmentScreen from '../AddEquipment';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const ContractJobViewScreen = () => {

	dayjs.extend(advancedFormat)

	const { contractJobId } = useParams();

	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const results = params.get('results');

	const { data: contractJob, isLoading, onChange } = useApi('/contractjobs/' + contractJobId + '?with=equipment,contract,contract.customer,engineers');
	const { data: pdfUrl, get: generatePDF } = useApi('/contractjobs/' + contractJobId + '/generate-pdf', false);
	const { destroy } = useApi('/contractjobs/' + contractJobId, false);
	const { data: resultsPdfUrl, post: generateResultsPDF } = useApi('/testresults/generate-pdf', false);

	const [formActive, setFormActive] = useState(false);
	const [equipmentFormActive, setEquipmentFormActive] = useState(false);

	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('contract job', 'Contract Job Deleted!')) {

			navigate('/contracts/' + contractJob.contract_id);
		}

    }

	useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])

	useEffect(() => {

        if( resultsPdfUrl && resultsPdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = resultsPdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [resultsPdfUrl])


	return (
		<ContractJobViewLayout className='ContractJobViewScreen'>

			<Link className='blue opacity' to='/contracts'>{'Contracts > '}</Link>
			<Link className='blue opacity' to={contractJob ? '/contracts/' + contractJob.contract_id : '/contracts'}>{contractJob && contractJob.contract ? 'Contract ' + contractJob.contract.reference + ' >' : ''}</Link> 

				<div className='ContractJobViewScreen__titleBar'>
					<div className='ContractJobViewScreen__titleBar__left'>
						<h1 style={{marginRight: '5px'}}>Contract Job for {! isLoading ? contractJob && contractJob.contract ? contractJob.contract.reference : '' : <BTDSkeleton width='200px'/>}</h1>
					</div>
					<div className='ContractJobViewScreen__titleBar__right'>
						<BTDButton
							className='ContractJobViewScreen__titleBar__right__button'
							label='Edit'
							onClick={() => {setFormActive(true)}}
						/>
						<BTDButton
							className='ContractJobViewScreen__titleBar__right__button'
							label='Copy Certs'
							onClick={() => {generatePDF()}}
						/>
						<a id="downloadLink" href=""/>
						<BTDButton
							className='ContractJobViewScreen__titleBar__right__button'
							colour='red'
							label='Delete'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='ContractJobViewScreen__details'>
					<p>Contract Ref: </p>
					<Link className='semi-bold light-blue' to={contractJob && contractJob.contract ? '/contracts/' + contractJob.contract.id : ''}>{! isLoading ? contractJob && contractJob.contract ? contractJob.contract.reference : '' : <BTDSkeleton width='100px'/>}</Link>
					<p>Job Started: </p>
					<p className='semi-bold'>{! isLoading ? contractJob.created_at ? dayjs(contractJob.created_at).format('ddd Do MMMM YYYY HH:mm') : '' : <BTDSkeleton width='100px'/>}</p>
					<p>Customer: </p>
					<Link className='semi-bold light-blue' to={contractJob && contractJob.contract && contractJob.contract.customer ? '/customers/' + contractJob.contract.customer.id : ''}>{! isLoading ? contractJob && contractJob.contract && contractJob.contract.customer ? contractJob.contract.customer.name : '' : <BTDSkeleton width='100px'/>}</Link>
					<p>Customer Order #: </p>
					<p className='semi-bold'>{! isLoading ? contractJob.customer_order_number : <BTDSkeleton width='100px'/>}</p>
					<p>Engineer Report #: </p>
					<p className='semi-bold'>{! isLoading ? contractJob.engineer_report_number : <BTDSkeleton width='100px'/>}</p>
					<p>Equipment Count: </p>
					<p className='semi-bold'>{! isLoading ? contractJob.equipment_count + ' items' : <BTDSkeleton width='100px'/>}</p>
					<p>Engineers Involved:</p>
					<div>
						{contractJob && contractJob.engineers && contractJob.engineers.map((engineer) => {
							return(<p className='semi-bold'>{engineer.firstname + ' ' + engineer.lastname}</p>)
						})}
					</div>
					<p>Readings Count: </p>
					<p className='semi-bold'>{! isLoading ? contractJob.readings_count + ' items' : <BTDSkeleton width='100px'/>}</p>
				</div>

				{results &&
					<BTDButton
						label="View the certificate you've just generated"
						onClick={() =>{generateResultsPDF({id: results})}}
						style={{margin: '15px auto'}}
					/>
				}

				<BTDButton
					label='Add Equipment to Job'
					onClick={() =>{setEquipmentFormActive(true)}}
					size='small'
					style={{margin: '0 0 15px auto'}}
				/>

				<TestResultsList
					contractJob={contractJob ?? {}}
					isLoading={isLoading}
					items={contractJob.equipment ?? []}
					onChange={() => {onChange()}}
				/>

				{formActive &&
					<ContractJobEditScreen
						itemId={contractJobId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}

				{equipmentFormActive &&
					<ContractJobAddEquipmentScreen
						contract_id={contractJob.contract_id ?? null}
						contract_job_id={contractJobId}
						onClose={() => {
							setEquipmentFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setEquipmentFormActive(false);
						}} 
					/>
				}
				
		</ContractJobViewLayout>

	)

}

export default ContractJobViewScreen