// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Other
import './styles.css';
import dayjs from 'dayjs';

const BTDDatepicker = ({ description, error, label, onChange, required, style, tabIndex, type, value }) => {

    const [active, setActive] = useState(false);

    const currentYear = new Date().getFullYear();

    // Create an array of the next ten years, including the present one
    const years = Array.from({ length: 10 }, (_, index) => currentYear + index);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    useEffect(() => {

        if( value != null && value != '' ) {

            setActive(true);
        }
        else {

            setActive(false)
        }
    }, [value])


    return(
        <div className="BTDDatepicker" style={style}>
            {description &&
                <p className='BTDDatepicker__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
            }
            <label className={`BTDDatepicker__label ${active ? 'active' : ''}`}>{label ?? ''}
                {required &&
                    <span className='red'>{<>&nbsp;</>}*</span>
                }
            </label>
            <ReactDatePicker 
                calendarClassName="BTDDatepicker__calendar"
                className="BTDDatepicker__input" 
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                        </button>
                        <select
                            value={dayjs(date).format('YYYY')}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
            
                        <select
                            value={months[dayjs(date).format('M') - 1]}
                            onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </select>
                
                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                        </button>
                        </div>
                    )}
                popperClassName="BTDDatepicker__popper"
                popperPlacement="bottom-start"
                popperModifiers={[
                    {
                        name: 'arrow',
                        options: {
                            padding: 36,
                        },
                    },
                ]}
                showTimeSelect={type !== 'date'}
                showTimeSelectOnly={type === 'time'}
                tabIndex={tabIndex ?? 0}
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="time"
                dateFormat="dd-MM-yyyy hh:mm"
                onChange={(value) => {onChange(dayjs(value).format('YYYY-MM-DD HH:mm'))}} 
                required={required} 
                selected={value ? new Date(Date.parse(value)) : null}
            />
            {error &&
                <p className='BTDDatepicker__error red'>{error}</p>
            }
        </div>
    )
}

BTDDatepicker.propTypes = {
    description: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    width: PropTypes.string
}

BTDDatepicker.defaultProps = {
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    style: {},
    type: 'datetime',
    tabIndex : 0,
    value: null,
    width: 'large'
}

export default BTDDatepicker;                                         