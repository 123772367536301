// Packages
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import ContractScheduleLayout from "modules/Contracts/layouts/Schedule";
import BTDProgress from "components/BTDUI/BTDProgress";
import BTDTab from "components/BTDUI/BTDTab";
import BTDButton from "components/BTDUI/BTDButton";

// Other
import useApi from "hooks/useApi";
import './styles.css';

const ContractScheduleScreen = () => {

    const { data, isLoading } = useApi('/contractschedule?with=contract,contract.customer');
    const { data: pdfUrl, post: generatePDF } = useApi('/contractschedule/generate-pdf', false);
    const { data: contractPdfUrl, post: generateContractPDF } = useApi('/contracts/generate-pdf', false);

    useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])

    useEffect(() => {

        if( contractPdfUrl && contractPdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = contractPdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [contractPdfUrl])

    const getTotalItemsForMonth = (group) => {

        const totalScheduleItems = group.contracts.reduce((accumulator, contract) => {

            const scheduleItems = contract.schedule_items;

            if (scheduleItems && Array.isArray(scheduleItems)) {

                const filteredItems = scheduleItems.filter(item => item.month_id === group.month.id);
                accumulator += filteredItems.length;
            }

            return accumulator;
        }, 0);

        return totalScheduleItems;
    }

    return(
        <ContractScheduleLayout>

            {isLoading &&

                <div className="progressDiv">
                    <BTDProgress height={50} type="circular" width={50} />
                </div>
            }
            {! isLoading &&
                <>
        
                <div className="ContractScheduleScreen__summary">
                    {data.length !== 0 && data.map((group, index) => {
                        
                        return(
                            <a className={`ContractScheduleScreen__summary__item blue ${new Date().getMonth() === index ? 'current' : ''}`} href={'#' + group.month.name} key={index}>
                                <p className="semi-bold">{group.month.abbreviation}</p>
                                <p className="medium">{getTotalItemsForMonth(group)} items</p>
                            </a>
                        )
                    })}
                </div>

                <div className="ContractScheduleScreen__months">
                    {data.length !== 0 && data.map((group, index) => {
                        
                        return(
                            <div className="ContractScheduleScreen__months__month" id={group.month.name} key={index}>

                                <h1 className="ContractScheduleScreen__months__month__title centre">{group.month.name}</h1>
                                <h4 className="ContractScheduleScreen__months__month__subtitle medium centre">{getTotalItemsForMonth(group)} items</h4>

                                <div className="ContractScheduleScreen__months__month__buttons">
                                    <BTDButton
                                        label={'All PDFs for ' + group.month.name}
                                        onClick={() => {
                                            generatePDF({month_id: group.month.id, with_contracts: true})
                                        }}
                                        size='small'
                                        style={{marginRight: '15px'}}
                                    />
                                    <BTDButton
                                        label='Cover Page'
                                        onClick={() => {
                                            generatePDF({month_id: group.month.id})
                                        }}
                                        size='small'
                                    />
                                    <a id="downloadLink" href=""/>
                                </div>

                                {group.contracts && group.contracts.map((contract) => {

                                    return(

                                        contract.schedule_items.map((item) => {

                                            if(item.month_id === group.month.id) {
    
                                                var colour = '';
        
                                                switch (item.type) {
                                                    case 'Calibration':
                                                        colour = 'green';
                                                        break;
                                                    case 'Reminder':
                                                        colour = 'grey';
                                                        break;
                                                    case 'Service':
                                                        colour = 'orange';
                                                        break;
                                                    case 'Letter':
                                                        colour = 'red';
                                                        break;
                                                    default:
                                                        break;
                                                }
        
                                                return(
                                                    <div className="ContractScheduleScreen__months__month__tr tr">
                                                        <Link className="td light-blue" to={'/contracts/' + contract.id}>{contract.reference }</Link>
                                                        <div className="td">{contract.customer ? contract.customer.name : ''}</div>
                                                        <div className="td"><BTDTab colour={colour} text={item.type} /></div>
                                                        <div className="td" onClick={() => {generateContractPDF({id: contract.id})}} style={{cursor: 'pointer'}}>PDF</div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return(<></>)
                                            }
                                        })
                                    )

                                })}

                            </div>
                        )
                    })}

                </div>
                

                </>

            }

        </ContractScheduleLayout>
    )

}

export default ContractScheduleScreen;