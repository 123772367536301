// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import Container from 'components/BTDUI/Container';
import Header from 'components/Header';
import dayjs from 'dayjs';
import Footer from 'components/Footer';

const TestResultsLayout = ({ children, contract_job, title }) => {


	return (
		<div className='TestResultsLayout'>
			
			<Header/>

			<Container>
				<Link className='blue opacity' to='/contracts'>Contracts {'>'} </Link>
				<Link className='blue opacity' to={contract_job.contract ? '/contracts/' + contract_job.contract.id : ''}>Contract {(contract_job.contract ? contract_job.contract.reference : '') + ' >'} </Link>
				<Link className='blue opacity' to={contract_job.contract ? '/contracts/' + contract_job.contract.id + '/jobs/' + contract_job.id : ''}>Contract Job {contract_job && contract_job.created_at ? dayjs(contract_job.created_at).format('D MMM') : ''} {'>'}</Link> 

				<h1>{title}</h1>

				{children}

			</Container>

			<Footer/>
        </div>
	)
}

TestResultsLayout.propTypes = {
	children: PropTypes.node,
	contract_job: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
,	title: PropTypes.string
}

TestResultsLayout.defaultProps = {
	children: null,
	contract_job: {},
	title: ''
}

export default TestResultsLayout