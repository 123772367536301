// Packages
import PropTypes from 'prop-types';

const StarIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="StarIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg width={width} height={height} viewBox="0 0 16 15" version="1.1">
                <title>star</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Customers---People" transform="translate(-230, -773)" fill={colour} fillRule="nonzero">
                        <g id="star" transform="translate(230, 773)">
                            <path d="M15.6527738,6.79774694 C15.967708,6.49740835 16.0788801,6.05661915 15.9430387,5.64686703 C15.8068769,5.23711491 15.4522155,4.94461397 15.0164977,4.88253982 L11.1424542,4.33171007 C10.9774581,4.30819713 10.8348887,4.20693475 10.7612011,4.06052753 L9.02922303,0.625757639 C8.83475194,0.239831961 8.4400429,0 8.00016019,0 C7.56059786,0 7.16588882,0.239831961 6.97141773,0.625757639 L5.23911924,4.06084103 C5.16543168,4.20724825 5.02254188,4.30851064 4.85754582,4.33202358 L0.98350229,4.88285332 C0.548104907,4.94461397 0.193123081,5.23742842 0.0569612803,5.64718054 C-0.0788801402,6.05693266 0.0322919657,6.49772186 0.347226202,6.79806044 L3.15023702,9.47163817 C3.26973903,9.5857543 3.32452413,9.75003135 3.29633063,9.91054634 L2.63506484,13.6857836 C2.57643517,14.0180997 2.66550101,14.3413242 2.88528218,14.5962045 C3.22680801,14.9934164 3.82303651,15.1144296 4.29976301,14.8692681 L7.76435999,13.0866739 C7.90917207,13.012373 8.09146869,13.013 8.23596039,13.0866739 L11.7008778,14.8692681 C11.869398,14.9561092 12.0491316,15 12.234632,15 C12.5732744,15 12.8942959,14.8526523 13.1150382,14.5962045 C13.3351398,14.3413242 13.4238852,14.0174727 13.3652555,13.6857836 L12.7036694,9.91054634 C12.6754759,9.74971784 12.730261,9.5857543 12.849763,9.47163817 L15.6527738,6.79774694 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

StarIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

StarIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default StarIcon;