// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import TestSpecificationListScreen from "modules/TestSpecifications/screens/List"
import TestSpecificationViewScreen from "modules/TestSpecifications/screens/View"


const TestSpecificationRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<TestSpecificationListScreen />}/>
			<Route path="/:testSpecificationId/*" element={<TestSpecificationViewScreen />}/>
		</Routes>
	)
}

export default TestSpecificationRoutes;