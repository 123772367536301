// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';

// Other
import useApi from 'hooks/useApi';
import BTDMultiselect from 'components/BTDUI/fields/BTDMultiselect';
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDRadioField from 'components/BTDUI/fields/BTDRadioField';


const ContractJobForm = ({ errors, equipment, contract_job, onClose, onSubmit, type, }) => {

    const { data: engineers, isLoading: engineerLoading } = useApi('/users/engineers');

    const [data, setData] = useState({});
	const [changedData, setChangedData] = useState({});
    

    // Load Item values into form
	useEffect(() => {

		if ( contract_job )
		{
            setData(contract_job);

            if( contract_job.engineers ) {

                setData({...contract_job, engineer_ids: contract_job.engineers.map(obj => obj.id)})
                setChangedData({engineer_ids: contract_job.engineers.map(obj => obj.id)})
            }
		}
        else {
            setData({status: 'in progress', equipment_ids: equipment.map(obj => obj.id)});
            setChangedData({status: 'in progress', equipment_ids: equipment.map(obj => obj.id)});
        }

    // eslint-disable-next-line
	}, [contract_job, equipment]);


    return(
        <FormTemplate onClose={onClose}>

            <h2 className="form__title medium">{type === 'create' ? 'Create contract job' : 'Edit contract job'}</h2>

            <div className='form__grid-2'>
                <BTDSelectField
                    blankOption={false}
                    error={errors && errors.status ? errors.status : null} 
                    label='Job Status'
                    onChange={(value) => {
                        setData({...data, status: value});
                        setChangedData({...changedData, status: value});
                    }} 
                    options={[
                        {id: 'closed', name: 'Closed'},
                        {id: 'in progress', name: 'In Progress'},
                    ]}
                    required={true}
                    tabIndex={1}
                    value={data.status ?? 'in progress'} 
                />

                <BTDTextField 
                    error={errors && errors.customer_order_number ? errors.customer_order_number : null} 
                    label='Customer Order #'
                    onChange={(value) => {
                        setData({...data, customer_order_number: value});
                        setChangedData({...changedData, customer_order_number: value});
                    }} 
                    tabIndex={2}
                    value={data.customer_order_number ?? ''} 
                />
            </div>

            <div className='form__grid-2'>
                <BTDTextField 
                    error={errors && errors.engineer_report_number ? errors.engineer_report_number : null} 
                    label='Engineers Report #'
                    onChange={(value) => {
                        setData({...data, engineer_report_number: value});
                        setChangedData({...changedData, engineer_report_number: value});
                    }} 
                    tabIndex={3}
                    value={data.engineer_report_number ?? ''} 
                />

                <BTDMultiselect
                    error={errors && errors.engineer_ids ? errors.engineer_ids : null} 
                    isLoading={engineerLoading}
                    label='Engineers Involved'
                    onChange={(value) => {
                        setData({...data, engineer_ids: value});
                        setChangedData({...changedData, engineer_ids: value});
                    }} 
                    options={engineers ?? []}
                    optionLabel={['firstname', 'lastname']}
                    tabIndex={4}
                    value={data.engineer_ids ?? []} 
                />
            </div>

            {type === 'create' &&
            <>
                <BTDRadioField
                    error={errors && errors.equipment ? errors.equipment : null} 
                    label='Equipment'
                    onChange={(value) => {
                        if( value === 'all') {
                            setData({...data, equipment: 'all', equipment_ids: equipment.map(obj => obj.id)})
                            setChangedData({...changedData, equipment_ids: equipment.map(obj => obj.id)})
                        }
                        else {
                            setData({...data, equipment: 'select', equipment_ids: []})
                            setChangedData({...changedData, equipment_ids: []})
                        }
                    }}
                    options={[
                        {value: 'all', label: 'Add all contract equipment to contract ' + (equipment ? `(${equipment.length} items)` : ''), colour: 'blue'},
                        {value: 'select', label: 'Select equipment to add', colour: 'blue'}
                    ]}
                    value={data.equipment ?? 'all'} 
                />

                {data.equipment === 'select' &&
                    <BTDMultiselect
                        error={errors && errors.equipment_ids ? errors.equipment_ids : null} 
                        label='Equipment'
                        onChange={(value) => {
                            setData({...data, equipment_ids: value});
                            setChangedData({...changedData, equipment_ids: value});
                        }} 
                        options={equipment ?? []}
                        optionLabel={['reference', 'manufacturer.name', 'model_number', 'serial']}
                        tabIndex={5}
                        value={data.equipment_ids ?? []} 
                    />
                }
            </>
            }

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save contract job' : 'Update contract job'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>


        </FormTemplate>
    )

}

ContractJobForm.propTypes = {
	contract_job: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	errors: PropTypes.object,
    equipment: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

ContractJobForm.defaultProps = {
    contract_job: null,
    errors: {},
    equipment: [],
	onSubmit: () => {alert('onSubmit is not set')},
    onClose: () => {'onClose is not set!'},
    type: 'create'
}

export default ContractJobForm;