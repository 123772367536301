// Packages
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import TestSpecificationEditScreen from 'modules/TestSpecifications/screens/Edit';
import TestSpecificationViewLayout from 'modules/TestSpecifications/layouts/View';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const TestSpecificationViewScreen = () => {

	const { testSpecificationId } = useParams();

	const { data: testSpecification, isLoading, onChange } = useApi('/testspecifications/' + testSpecificationId);
	const { destroy } = useApi('/testspecifications/' + testSpecificationId, false);

	const [formActive, setFormActive] = useState(false);
	
	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('test specification', 'Test Specification Deleted!')) {

			navigate('/testspecifications');
		}
    }



	return (
		<TestSpecificationViewLayout className='TestSpecificationViewScreen'>

				<div className='TestSpecificationViewScreen__titleBar'>
					<div className='TestSpecificationViewScreen__titleBar__left'>
						<h1 style={{marginRight: '20px'}}>{! isLoading ? testSpecification.name : <BTDSkeleton width='200px'/>}</h1>
					</div>
					<div className='TestSpecificationViewScreen__titleBar__right'>
						<BTDButton
							label='Edit'
							onClick={() => {setFormActive(true)}}
							style={{marginRight: '20px'}}
						/>
						<BTDButton
							colour='red'
							label='Archive'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='TestSpecificationViewScreen__details'>
					<p>Test Specification: </p>
					<p className='semi-bold'>{! isLoading ? testSpecification.name : <BTDSkeleton width='100px'/>}</p>
					<p>Code: </p>
					<p className='semi-bold'>{! isLoading ? testSpecification.code : <BTDSkeleton width='100px'/>}</p>
				</div>


				{formActive &&
					<TestSpecificationEditScreen
						itemId={testSpecificationId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}

				
		</TestSpecificationViewLayout>

	)

}

export default TestSpecificationViewScreen;