// Packages
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const ManufacturerListItem = ({ isLoading, name }) => {

	return (
		<div className='ManufacturerListItem'>
			<h3 className='blue'>{! isLoading ? name : <BTDSkeleton width='50%'/>}</h3> 
		</div>
	)
}

ManufacturerListItem.propTypes = {
	isLoading: PropTypes.bool,
	name: PropTypes.string, 
}

ManufacturerListItem.defaultProps = {
	isLoading: false,
	name: '',
}

export default ManufacturerListItem;