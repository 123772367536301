// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';

// Other
import useApi from 'hooks/useApi';

const PurchaseOrderItemForm = ({ errors, purchaseOrderItem, onClose, onSubmit, supplier_id, type, }) => {

    const { data: stock, isLoading: stockLoading } = useApi('/stock/list-by-supplier?per_page=99999&supplier_id=' + supplier_id);

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    const [itemType, setItemType] = useState('stock');
    

    // Load Item values into form
	useEffect(() => {

		if ( purchaseOrderItem )
		{
            setData(purchaseOrderItem);

            if( ! purchaseOrderItem.stock_id || purchaseOrderItem.stock_id === 0 ) {

                setItemType('one-off');
            }
            else {
                setItemType('stock');
            }
		}

	}, [purchaseOrderItem]);

    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create purchase order item' : 'Edit purchase order item'}</h3>


            {itemType === 'stock' ?
                <>
                    <div className='form__grid-2'>

                        <p className='form__text'>Search for Stock item:</p>

                        <BTDButton
                            label='Enter one-off item'
                            onClick={() => {
                                setItemType('one-off');
                                setData({...data, stock_id: null, price: null})
                                setChangedData({...data, stock_id: null, price: null})
                            }}
                            size='small'
                            style={{margin: '0 0 30px auto'}}
                        />

                    </div>
                    <BTDAutoCompleteField
                        error={errors && errors.stock_id ? errors.stock_id : null} 
                        isLoading={stockLoading}
                        label='Stock'
                        onChange={(value) => {
                            const stockItem = stock.find(obj => obj.id === value);
                            setData({...data, stock_id: value, price: stockItem ? stockItem.cost_price : '', name: stockItem ? stockItem.name : ''});
                            setChangedData({...changedData, stock_id: value, price: stockItem ? stockItem.cost_price : '', name: stockItem ? stockItem.name : ''});
                        }} 
                        options={stock ?? []}
                        optionLabel={['reference','name']}
                        tabIndex={1}
                        value={data.stock_id ?? ''} 
                    />  
                </>
                :
                <>
                    <div className='form__grid-2'>

                        <p className='form__text'>Enter one-off item:</p>

                        <BTDButton
                            label='Search for stock item'
                            onClick={() => {
                                setItemType('stock');
                                setData({...data, name: null, price: null})
                                setChangedData({...data, name: null, price: null})
                            }}
                            size='small'
                            style={{margin: '0 0 30px auto'}}
                        />

                    </div>
                
                    <BTDTextField
                        error={errors && errors.name ? errors.name : null} 
                        label='Name'
                        onChange={(value) => {
                            setData({...data, name: value});
                            setChangedData({...changedData, name: value});
                        }}
                        required={true}
                        style={{width: '100%'}} 
                        tabIndex={2}
                        value={data.name ?? ''} 
                    />

                </>
            }   

            <div className='form__grid-2' style={{marginBottom: '20px'}}>

                <BTDNumberField
                    error={errors && errors.quantity ? errors.quantity : null} 
                    label='Quantity'
                    onChange={(value) => {
                        setData({...data, quantity: value});
                        setChangedData({...changedData, quantity: value});
                    }}
                    required={true}
                    style={{width: '100%'}} 
                    tabIndex={3}
                    value={data.quantity ?? ''} 
                />

                <BTDNumberField  
                    error={errors && errors.price ? errors.price : null} 
                    label='Price (£)'
                    onChange={(value) => {
                        setData({...data, price: value});
                        setChangedData({...changedData, price: value});
                    }} 
                    required={true}
                    step={0.01}
                    style={{width: '100%'}} 
                    tabIndex={4}
                    value={data.price ?? ''} 
                />
            </div>


            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save purchase order item' : 'Update purchase order item'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

PurchaseOrderItemForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    supplier_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string
}

PurchaseOrderItemForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    supplier_id: '',
    type: 'create'
}

export default PurchaseOrderItemForm;