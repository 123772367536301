// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'
import dayjs from 'dayjs';
import BTDTab from 'components/BTDUI/BTDTab';

const PurchaseOrderListItem = ({ created_at, isLoading, reference, status, supplier, total }) => {

	const [colour, setColour] = useState('');

	useEffect(() => {
	
		switch (status) {
			case 'Open':
				setColour('red');
				break;
			case 'Part Received':
				setColour('orange');
				break;
			case 'Received':
				setColour('green');
				break;
		
			default:
				setColour('grey')
				break;
		}
	}, [status])

	return (
		<div className='PurchaseOrderListItem'>

			<p className='PurchaseOrderListItem__ref blue inline-block semi-bold' style={{gridArea: 'ref', marginRight: '20px'}}>{! isLoading ? reference : <BTDSkeleton width='50%'/>}</p> 

			<p className='PurchaseOrderListItem__date blue' style={{gridArea: 'date'}}>{! isLoading ? created_at ? dayjs(created_at).format('DD/MM/YYYY') : '' : <BTDSkeleton width='50%'/>}</p>

			<p className='PurchaseOrderListItem__name blue' style={{gridArea: 'name'}}>{! isLoading ? supplier ? supplier.name : '' : <BTDSkeleton width='50%'/>}</p>

			<p className='PurchaseOrderListItem__total blue centre' style={{gridArea: 'total'}}>{! isLoading ? '£ ' + total.toFixed(2) : <BTDSkeleton width='50%'/>}</p>
			
			<p className='PurchaseOrderListItem__status blue' style={{gridArea: 'status', margin: '0 auto'}}>{! isLoading ? <span className='PurchaseOrderListItem__status__tab'><BTDTab colour={colour} text={status} /></span>: <BTDSkeleton width='50%'/>}</p>

		</div>
	)
}

PurchaseOrderListItem.propTypes = {
	created_at: PropTypes.string,
	isLoading: PropTypes.bool,
	reference: PropTypes.string, 
	status: PropTypes.string,
	supplier: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	total: PropTypes.number
}

PurchaseOrderListItem.defaultProps = {
	created_at: '',
	isLoading: false,
	name: '',
	reference: '',
	quantity: '',
	status: '',
	supplier: {},
	total: null
}

export default PurchaseOrderListItem;