// Packages
import PropTypes from 'prop-types'

// Components
import PurchaseOrderForm from 'modules/PurchaseOrders/components/Form';

// Other
import useApi from 'hooks/useApi';

const PurchaseOrderEditScreen = ({  itemId, onClose, onSubmit }) => {

    const { data: item, put: updateItem, errors } = useApi('/purchaseorders/' + itemId);

	const submit = async (values) => {

        if( await updateItem(values, 'Purchase Order updated!')) {

            onSubmit();
        }
    }

    return(
        <PurchaseOrderForm 
            errors={errors} 
            purchaseOrder={item} 
            onClose={onClose} 
            onSubmit={submit} 
            type='edit' 
        />
    )
}

PurchaseOrderEditScreen.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

PurchaseOrderEditScreen.defaultProps = {
    itemId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default PurchaseOrderEditScreen;