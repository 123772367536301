// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

// Components
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDDatepicker from 'components/BTDUI/fields/BTDDatepicker';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';

// Other
import useApi from 'hooks/useApi';
import './styles.css';

const TestResultsForm = ({ equipment, equipmentErrors, job, onSubmit, results, resultsErrors, type, }) => {

    const { data: manufacturers, isLoading: manufacturerLoading } = useApi('/manufacturers?per_page=99999');

    const [resultsData, setResultsData] = useState({});
	const [changedResultsData, setChangedResultsData] = useState({});
    const [resultsScaleData, setResultsScaleData] = useState({});
	const [changedResultsScaleData, setChangedResultsScaleData] = useState({});
    const [equipmentData, setEquipmentData] = useState({});
	const [changedEquipmentData, setChangedEquipmentData] = useState({});
    const [equipmentScaleData, setEquipmentScaleData] = useState({});
	const [changedEquipmentScaleData, setChangedEquipmentScaleData] = useState({});
    const [step, setStep] = useState(1);

    // Load Item values into form
	useEffect(() => {

		if ( results.length !== 0 )
		{
            setResultsData(results);

            if( results.test_results_scale.length !== 0 ) {

                setResultsScaleData(results.test_results_scale);

                setChangedResultsData();
            }
        }
        else {

            if( equipment.customer_equipment_scale && equipment.customer_equipment_scale.length !== 0 ) {

                setResultsScaleData({
                    range_weight_1: equipment.customer_equipment_scale.range_weight_1.toFixed(equipment.precision),
                    range_weight_2: equipment.customer_equipment_scale.range_weight_2.toFixed(equipment.precision),
                    range_weight_3: equipment.customer_equipment_scale.range_weight_3.toFixed(equipment.precision),
                    range_weight_4: equipment.customer_equipment_scale.range_weight_4.toFixed(equipment.precision),
                    range_weight_5: equipment.customer_equipment_scale.range_weight_5.toFixed(equipment.precision),
                    eccentricity_weight_0: equipment.customer_equipment_scale.eccentricity_weight_0.toFixed(equipment.precision),
                    eccentricity_weight_1: equipment.customer_equipment_scale.eccentricity_weight_1.toFixed(equipment.precision),
                    eccentricity_weight_2: equipment.customer_equipment_scale.eccentricity_weight_2.toFixed(equipment.precision),
                    eccentricity_weight_3: equipment.customer_equipment_scale.eccentricity_weight_3.toFixed(equipment.precision),
                    eccentricity_weight_4: equipment.customer_equipment_scale.eccentricity_weight_4.toFixed(equipment.precision),
                    repeatability_weight: equipment.customer_equipment_scale.repeatability_weight.toFixed(equipment.precision),
                });

                setChangedResultsScaleData({
                    range_weight_1: equipment.customer_equipment_scale.range_weight_1.toFixed(equipment.precision),
                    range_weight_2: equipment.customer_equipment_scale.range_weight_2.toFixed(equipment.precision),
                    range_weight_3: equipment.customer_equipment_scale.range_weight_3.toFixed(equipment.precision),
                    range_weight_4: equipment.customer_equipment_scale.range_weight_4.toFixed(equipment.precision),
                    range_weight_5: equipment.customer_equipment_scale.range_weight_5.toFixed(equipment.precision),
                    eccentricity_weight_0: equipment.customer_equipment_scale.eccentricity_weight_0.toFixed(equipment.precision),
                    eccentricity_weight_1: equipment.customer_equipment_scale.eccentricity_weight_1.toFixed(equipment.precision),
                    eccentricity_weight_2: equipment.customer_equipment_scale.eccentricity_weight_2.toFixed(equipment.precision),
                    eccentricity_weight_3: equipment.customer_equipment_scale.eccentricity_weight_3.toFixed(equipment.precision),
                    eccentricity_weight_4: equipment.customer_equipment_scale.eccentricity_weight_4.toFixed(equipment.precision),
                    repeatability_weight: equipment.customer_equipment_scale.repeatability_weight.toFixed(equipment.precision),
                });
                
                setChangedResultsData({contract_id: job.contract_id, contract_job_id: job.id, customer_id: job.contract ? job.contract.customer_id : null, customer_equipment_id: equipment.id})

            }
        }

        if( equipment.customer_equipment_scale ) {

            setEquipmentScaleData(equipment.customer_equipment_scale);
        }

        setEquipmentData(equipment);

         // eslint-disable-next-line
	}, [results, equipment, job]);


    useEffect(() => {

        if( equipment ) {

            switch (equipment.precision) {
                case 1:
                    setStep(0.1)
                    break;
                case 2:
                    setStep(0.01)
                    break;
                case 3:
                    setStep(0.001)
                    break;
                case 4:
                    setStep(0.0001)
                    break;
                case 5:
                    setStep(0.00001)
                    break;
            
                default:
                    setStep(1)
                    break;
            }
        }
    }, [equipment])

    const numberWithSign = (number) => {

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: equipment ? equipment.precision : 0,
            signDisplay: 'always',
        });

        return formatter.format(number);
    };

    return(
        <div className='TestResultsForm'>

            <div className='form__columns'>

                <div className='form__columns__column'>

                    <h1 className="TestResultsForm__title medium">Test results {equipment ? 'for ' + equipment.reference : ''}</h1>

                    <p className='TestResultsForm__links'>Before you enter the test results check the tolerances on each test. If you need to change them, <Link className='light-blue'>click here.</Link></p>

                    <div className='TestResultsForm__links form__grid-2'>

                        <p className='semi-bold'>Contract Ref: 
                            <Link className='light-blue medium' to={job.contract ? '/contracts/' + job.contract.id : ''}>{job.contract && job.contract.reference ? ' ' + job.contract.reference : ''}</Link>
                        </p>

                        <p className='semi-bold'>Equipment Ref: <span className='medium'>{equipment ? equipment.reference : ''}</span></p>

                    </div>

                    <div className='form__grid-2'>

                        <BTDSelectField
                            error={equipmentErrors && equipmentErrors.manufacturer_id ? equipmentErrors.manufacturer_id : null} 
                            isLoading={manufacturerLoading}
                            label='Manufacturer'
                            onChange={(value) => {
                                setEquipmentData({...equipmentData, manufacturer_id: value});
                                setChangedEquipmentData({...changedEquipmentData, manufacturer_id: value});
                            }} 
                            options={manufacturers ?? []}
                            required={true}
                            tabIndex={1}
                            value={equipmentData.manufacturer_id ?? ''} 
                        />

                        <BTDTextField 
                            error={equipmentErrors && equipmentErrors.model_number ? equipmentErrors.model_number : null} 
                            label='Model'
                            onChange={(value) => {
                                setEquipmentData({...equipmentData, model_number: value});
                                setChangedEquipmentData({...changedEquipmentData, model_number: value});
                            }} 
                            tabIndex={2}
                            value={equipmentData.model_number ?? ''} 
                        />
                    </div>

                    <div className='form__grid-2'>

                        <BTDTextField 
                            error={equipmentErrors && equipmentErrors.serial ? equipmentErrors.serial : null} 
                            label='Serial'
                            onChange={(value) => {
                                setEquipmentData({...equipmentData, serial: value});
                                setChangedEquipmentData({...changedEquipmentData, serial: value});
                            }} 
                            tabIndex={3}
                            value={equipmentData.serial ?? ''} 
                        />

                        <BTDTextField 
                            error={equipmentErrors && equipmentErrors.location ? equipmentErrors.location : null} 
                            label='Location'
                            onChange={(value) => {
                                setEquipmentData({...equipmentData, location: value});
                                setChangedEquipmentData({...changedEquipmentData, location: value});
                            }} 
                            tabIndex={4}
                            value={equipmentData.location ?? ''} 
                        />

                    </div>


                    <div className='form__grid-2 TestResultsForm__mobileGrid'>

                        <BTDNumberField
                            error={equipmentErrors && equipmentErrors.weighing_capacity ? equipmentErrors.weighing_capacity : null} 
                            label={`Weighing Capacity (${equipmentScaleData.weighing_capacity_units ?? 'kg'})`}
                            onChange={(value) => {
                                setEquipmentScaleData({...equipmentScaleData, weighing_capacity: value});
                                setChangedEquipmentScaleData({...changedEquipmentScaleData, weighing_capacity: value});
                            }} 
                            step={step}
                            style={{width: '100%'}}
                            tabIndex={5}
                            value={equipmentScaleData.weighing_capacity ?? ''} 
                        />
                        <BTDSelectField
                            error={equipmentErrors && equipmentErrors.weighing_capacity_units ? equipmentErrors.weighing_capacity_units : null} 
                            onChange={(value) => {
                                setEquipmentScaleData({...equipmentScaleData, weighing_capacity_units: value});
                                setChangedEquipmentScaleData({...changedEquipmentScaleData, weighing_capacity_units: value});
                            }} 
                            options={[
                                {id: 'kg', name: 'kg'},
                                {id: 'g', name: 'g'},
                                {id: 'mg', name: 'mg'},
                                {id: 'lb', name: 'lb'},
                            ]}
                            style={{maxWidth: '80px'}}
                            tabIndex={6}
                            value={equipmentScaleData.weighing_capacity_units ?? 'kg'} 
                        />
                    </div>
                </div>

                <div className='form__columns__column'>

                    <div className='form__grid-2 TestResultsForm__column2'>

                        <BTDDatepicker
                            error={resultsErrors && resultsErrors.created_at ? resultsErrors.created_at : null} 
                            label={'Test Date'}
                            onChange={(value) => {
                                setResultsData({...resultsData, created_at: value});
                                setChangedResultsData({...changedResultsData, created_at: value});
                            }} 
                            style={{width: '100%'}}
                            type='date'
                            tabIndex={7}
                            value={resultsData.created_at ?? ''} 
                        />

                        <BTDTextField 
                            error={resultsErrors && resultsErrors.ambient ? resultsErrors.ambient : null} 
                            label={'Ambient (°C)'}
                            onChange={(value) => {
                                setResultsData({...resultsData, ambient: value});
                                setChangedResultsData({...changedResultsData, ambient: value});
                            }} 
                            tabIndex={8}
                            value={resultsData.ambient ?? ''} 
                        />
                    </div>

                    <div className='form__grid-2'>

                        <BTDSelectField
                            error={resultsErrors && resultsErrors.engineer_user_id ? resultsErrors.engineer_user_id : null} 
                            label='Engineer'
                            onChange={(value) => {
                                setResultsData({...resultsData, engineer_user_id: value});
                                setChangedResultsData({...changedResultsData, engineer_user_id: value});
                            }} 
                            options={job ? job.engineers : []}
                            optionLabel={['firstname', 'lastname']}
                            required={true}
                            tabIndex={9}
                            value={resultsData.engineer_user_id ?? ''} 
                        />

                    </div>

                </div>

            </div>

            <h2 className='form__label'>Range Calibration: </h2>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Test 1</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.range_weight_1 + equipmentScaleData.range_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.range_reading_1 ? resultsErrors.range_reading_1 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.range_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.range_weight_1;
                            setResultsScaleData({...resultsScaleData, range_reading_1: value, range_error_1: error});
                            setChangedResultsScaleData({...changedResultsScaleData, range_reading_1: value, range_error_1: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={10}
                        value={resultsScaleData.range_reading_1 ? parseFloat(resultsScaleData.range_reading_1) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.range_reading_1) - resultsScaleData.range_weight_1) * 1e12) / 1e12 <= equipmentScaleData.range_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.range_error_1 ? numberWithSign(resultsScaleData.range_error_1) : ''}</span> 
                        {equipmentScaleData.range_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Test 2</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.range_weight_2 + equipmentScaleData.range_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.range_reading_2 ? resultsErrors.range_reading_2 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.range_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.range_weight_2;
                            setResultsScaleData({...resultsScaleData, range_reading_2: value, range_error_2: error});
                            setChangedResultsScaleData({...changedResultsScaleData, range_reading_2: value, range_error_2: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={11}
                        value={resultsScaleData.range_reading_2 ? parseFloat(resultsScaleData.range_reading_2) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.range_reading_2) - resultsScaleData.range_weight_2) * 1e12) / 1e12 <= equipmentScaleData.range_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.range_error_2 ? numberWithSign(resultsScaleData.range_error_2) : ''}</span> 
                        {equipmentScaleData.range_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Test 3</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.range_weight_3 + equipmentScaleData.range_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.range_reading_3 ? resultsErrors.range_reading_3 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.range_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.range_weight_3;
                            setResultsScaleData({...resultsScaleData, range_reading_3: value, range_error_3: error});
                            setChangedResultsScaleData({...changedResultsScaleData, range_reading_3: value, range_error_3: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={12}
                        value={resultsScaleData.range_reading_3 ? parseFloat(resultsScaleData.range_reading_3) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.range_reading_3) - resultsScaleData.range_weight_3) * 1e12) / 1e12 <= equipmentScaleData.range_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.range_error_3 ? numberWithSign(resultsScaleData.range_error_3) : ''}</span> 
                        {equipmentScaleData.range_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Test 4</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.range_weight_4 + equipmentScaleData.range_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.range_reading_4 ? resultsErrors.range_reading_4 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.range_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.range_weight_4;
                            setResultsScaleData({...resultsScaleData, range_reading_4: value, range_error_4: error});
                            setChangedResultsScaleData({...changedResultsScaleData, range_reading_4: value, range_error_4: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={13}
                        value={resultsScaleData.range_reading_4 ? parseFloat(resultsScaleData.range_reading_4) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.range_reading_4) - resultsScaleData.range_weight_4) * 1e12) / 1e12 <= equipmentScaleData.range_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.range_error_4 ? numberWithSign(resultsScaleData.range_error_4) : ''}</span> 
                        {equipmentScaleData.range_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Test 5</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.range_weight_5 + equipmentScaleData.range_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.range_reading_5 ? resultsErrors.range_reading_5 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.range_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.range_weight_5;
                            setResultsScaleData({...resultsScaleData, range_reading_5: value, range_error_5: error});
                            setChangedResultsScaleData({...changedResultsScaleData, range_reading_5: value, range_error_5: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={14}
                        value={resultsScaleData.range_reading_5 ? parseFloat(resultsScaleData.range_reading_5) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.range_reading_5) - resultsScaleData.range_weight_5) * 1e12) / 1e12 <= equipmentScaleData.range_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.range_error_5 ? numberWithSign(resultsScaleData.range_error_5) : ''}</span> 
                        {equipmentScaleData.range_tolerance_units}
                    </p>

                </div>

                <p className='form__flexContainer__detail small centre'>Tolerance is ±{equipmentScaleData.range_tolerance + ' ' + equipmentScaleData.range_tolerance_units}</p>

                <h2 className='form__label'>Eccentricity Test: </h2>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Centre</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.eccentricity_weight_0 + equipmentScaleData.eccentricity_tolerance_units}</p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.eccentricity_point_0 ? resultsErrors.eccentricity_point_0 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.eccentricity_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.eccentricity_weight_0;
                            setResultsScaleData({...resultsScaleData, eccentricity_point_0: value, eccentricity_error_0: error});
                            setChangedResultsScaleData({...changedResultsScaleData, eccentricity_point_0: value, eccentricity_error_0: error.toFixed(equipment.precision)});
                        }}
                        step={step}
                        tabIndex={15}
                        value={resultsScaleData.eccentricity_point_0 ? parseFloat(resultsScaleData.eccentricity_point_0) : ''}
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.eccentricity_point_0) - resultsScaleData.eccentricity_weight_0) * 1e12) / 1e12 <= equipmentScaleData.eccentricity_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.eccentricity_error_0 ? numberWithSign(resultsScaleData.eccentricity_error_0) : ''}</span> 
                        {equipmentScaleData.eccentricity_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Point 1</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.eccentricity_weight_1 + equipmentScaleData.eccentricity_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.eccentricity_point_1 ? resultsErrors.eccentricity_point_1 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.eccentricity_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.eccentricity_weight_1;
                            setResultsScaleData({...resultsScaleData, eccentricity_point_1: value, eccentricity_error_1: error});
                            setChangedResultsScaleData({...changedResultsScaleData, eccentricity_point_1: value, eccentricity_error_1: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={16}
                        value={resultsScaleData.eccentricity_point_1 ? parseFloat(resultsScaleData.eccentricity_point_1) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.eccentricity_point_1) - resultsScaleData.eccentricity_weight_1) * 1e12) / 1e12 <= equipmentScaleData.eccentricity_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.eccentricity_error_1 ? numberWithSign(resultsScaleData.eccentricity_error_1) : ''}</span> 
                        {equipmentScaleData.eccentricity_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Point 2</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.eccentricity_weight_2 + equipmentScaleData.eccentricity_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.eccentricity_point_2 ? resultsErrors.eccentricity_point_2 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.eccentricity_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.eccentricity_weight_2;
                            setResultsScaleData({...resultsScaleData, eccentricity_point_2: value, eccentricity_error_2: error});
                            setChangedResultsScaleData({...changedResultsScaleData, eccentricity_point_2: value, eccentricity_error_2: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={17}
                        value={resultsScaleData.eccentricity_point_2 ? parseFloat(resultsScaleData.eccentricity_point_2) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.eccentricity_point_2) - resultsScaleData.eccentricity_weight_2) * 1e12) / 1e12 <= equipmentScaleData.eccentricity_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.eccentricity_error_2 ? numberWithSign(resultsScaleData.eccentricity_error_2) : ''}</span> 
                        {equipmentScaleData.eccentricity_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Point 3</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.eccentricity_weight_3 + equipmentScaleData.eccentricity_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.eccentricity_point_3 ? resultsErrors.eccentricity_point_3 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.eccentricity_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.eccentricity_weight_3;
                            setResultsScaleData({...resultsScaleData, eccentricity_point_3: value, eccentricity_error_3: error});
                            setChangedResultsScaleData({...changedResultsScaleData, eccentricity_point_3: value, eccentricity_error_3: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={18}
                        value={resultsScaleData.eccentricity_point_3 ? parseFloat(resultsScaleData.eccentricity_point_3) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.eccentricity_point_3) - resultsScaleData.eccentricity_weight_3) * 1e12) / 1e12 <= equipmentScaleData.eccentricity_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.eccentricity_error_3 ? numberWithSign(resultsScaleData.eccentricity_error_3) : ''}</span> 
                        {equipmentScaleData.eccentricity_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Point 4</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.eccentricity_weight_4 + equipmentScaleData.eccentricity_tolerance_units} </p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.eccentricity_point_4 ? resultsErrors.eccentricity_point_4 : null} 
                        height='slim'
                        label={'Reading (' + equipmentScaleData.eccentricity_tolerance_units + ')'}
                        onChange={(value) => {
                            let error = value - resultsScaleData.eccentricity_weight_4;
                            setResultsScaleData({...resultsScaleData, eccentricity_point_4: value, eccentricity_error_4: error});
                            setChangedResultsScaleData({...changedResultsScaleData, eccentricity_point_4: value, eccentricity_error_4: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={19}
                        value={resultsScaleData.eccentricity_point_4 ? parseFloat(resultsScaleData.eccentricity_point_4) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.eccentricity_point_4) - resultsScaleData.eccentricity_weight_4) * 1e12) / 1e12 <= equipmentScaleData.eccentricity_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.eccentricity_error_4 ? numberWithSign(resultsScaleData.eccentricity_error_4) : ''}</span> 
                        {equipmentScaleData.eccentricity_tolerance_units}
                    </p>

                </div>

                <p className='form__flexContainer__detail small centre'>Tolerance is ±{equipmentScaleData.eccentricity_tolerance + ' ' + equipmentScaleData.eccentricity_tolerance_units}</p>
            

                <h2 className='form__label'>Repeatability Test: </h2>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Test 1</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.repeatability_weight}</p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.repeatability_1 ? resultsErrors.repeatability_1 : null} 
                        height='slim'
                        label='Reading'
                        onChange={(value) => {
                            let error = value - resultsScaleData.repeatability_weight;
                            setResultsScaleData({...resultsScaleData, repeatability_1: value, repeatability_error_1: error});
                            setChangedResultsScaleData({...changedResultsScaleData, repeatability_1: value, repeatability_error_1: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={20}
                        value={resultsScaleData.repeatability_1 ? parseFloat(resultsScaleData.repeatability_1) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.repeatability_1) - resultsScaleData.repeatability_weight) * 1e12) / 1e12 <= equipmentScaleData.repeatability_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.repeatability_error_1 ? numberWithSign(resultsScaleData.repeatability_error_1) : ''}</span> 
                        {equipmentScaleData.repeatability_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Test 2</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.repeatability_weight}</p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.repeatability_2 ? resultsErrors.repeatability_2 : null} 
                        height='slim'
                        label='Reading'
                        onChange={(value) => {
                            let error = value - resultsScaleData.repeatability_weight;
                            setResultsScaleData({...resultsScaleData, repeatability_2: value, repeatability_error_2: error});
                            setChangedResultsScaleData({...changedResultsScaleData, repeatability_2: value, repeatability_error_2: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={21}
                        value={resultsScaleData.repeatability_2 ? parseFloat(resultsScaleData.repeatability_2) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.repeatability_2) - resultsScaleData.repeatability_weight) * 1e12) / 1e12 <= equipmentScaleData.repeatability_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.repeatability_error_2 ? numberWithSign(resultsScaleData.repeatability_error_2) : ''}</span> 
                        {equipmentScaleData.repeatability_tolerance_units}
                    </p>

                </div>

                <div className='TestResultsForm__grid'>

                    <p className='TestResultsForm__testLabel'>Test 3</p>

                    <p className='TestResultsForm__number semi-bold'>{resultsScaleData.repeatability_weight}</p>

                    <BTDNumberField
                        className='TestResultsForm__number'
                        error={resultsErrors && resultsErrors.repeatability_3 ? resultsErrors.repeatability_3 : null} 
                        height='slim'
                        label='Reading'
                        onChange={(value) => {
                            let error = value - resultsScaleData.repeatability_weight;
                            setResultsScaleData({...resultsScaleData, repeatability_3: value, repeatability_error_3: error});
                            setChangedResultsScaleData({...changedResultsScaleData, repeatability_3: value, repeatability_error_3: error.toFixed(equipment.precision)});
                        }} 
                        step={step}
                        tabIndex={22}
                        value={resultsScaleData.repeatability_2 ? parseFloat(resultsScaleData.repeatability_3) : ''} 
                    />

                    <p className='TestResultsForm__errorText blue medium'>Error: 
                        <span className={`${Math.round(Math.abs(parseFloat(resultsScaleData.repeatability_3) - resultsScaleData.repeatability_weight) * 1e12) / 1e12 <= equipmentScaleData.repeatability_tolerance ? 'green' : 'red'} medium`}>{resultsScaleData.repeatability_error_3 ? numberWithSign(resultsScaleData.repeatability_error_3) : ''}</span> 
                        {equipmentScaleData.repeatability_tolerance_units}
                    </p>

                </div>

                <p className='form__flexContainer__detail small centre'>Tolerance is ±{equipmentScaleData.repeatability_tolerance + ' ' + equipmentScaleData.repeatability_tolerance_units}</p>

                <BTDTextareaField
                    error={resultsErrors && resultsErrors.notes ? resultsErrors.notes : null} 
                    label='Certificate notes'
                    onChange={(value) => {
                        setResultsData({...resultsData, notes: value});
                        setChangedResultsData({...changedResultsData, notes: value});
                    }} 
                    style={{marginTop: '20px', maxWidth: '800px', width: '100%'}}
                    tabIndex={23}
                    value={resultsData.notes ?? ''} 
                />
                
            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save test results' : 'Update customer equipment'}
                onClick={() => {onSubmit({results: changedResultsData, resultsScale: changedResultsScaleData, equipmentData: changedEquipmentData, equipmentScaleData: changedEquipmentScaleData})}} 
            ></BTDButton>


        </div>
    )

}

TestResultsForm.propTypes = {
	equipmentErrors: PropTypes.object,
    contract_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	customer_equipment: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	resultsErrors: PropTypes.object,
    type: PropTypes.string
}

TestResultsForm.defaultProps = {
    equipmentErrors: {},
    contract_id: null,
    customer_equipment: null,
	onSubmit: () => {alert('onSubmit is not set')},
    onClose: () => {alert('onClose is not set!')},
    resultsErrors: {},
    type: 'create'
}

export default TestResultsForm;