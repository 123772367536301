// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import CustomerEquipmentListScreen from "modules/CustomerEquipment/screens/List"
import CustomerEquipmentViewScreen from "modules/CustomerEquipment/screens/View"


const CustomerRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<CustomerEquipmentListScreen />}/>
			<Route path="/:customerEquipmentId/*" element={<CustomerEquipmentViewScreen />}/>
		</Routes>
	)
}

export default CustomerRoutes;