// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import UserListScreen from "modules/Users/screens/List"
import UserViewScreen from "modules/Users/screens/View"


const UserRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<UserListScreen />}/>
			<Route path="/:userId/*" element={<UserViewScreen />}/>
		</Routes>
	)
}

export default UserRoutes;