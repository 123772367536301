// Packages
import { useState } from 'react';

// Components
import CustomerList from 'modules/Customers/components/List';
import CustomerEquipmentCreateScreen from '../Create';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import CustomerEquipmentLayout from 'modules/CustomerEquipment/layouts';
import BTDPagination from 'components/BTDUI/BTDPagination';
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import useApi from 'hooks/useApi';
import './styles.css'

const CustomerEqupimentScreen = () => {

    const [page, setPage] = useState(1);

    const { data: items, isLoading, meta, onChange } = useApi('/customerequipment?page=' + page + '&per_page=100');
    
    const [formActive, setFormActive] = useState(false);

    return(
        <CustomerEquipmentLayout>

            {meta && meta.length !== 0 &&
                <p className='CustomerEqupimentScreen__text opacity'>Displaying {meta.from.toLocaleString()} to {meta.to.toLocaleString()} of {meta.total.toLocaleString()} customers</p>
            }

            { isLoading ? 
                <>
                <div style={{ 
                    alignItems: 'center',
                    display: 'flex',
                    height: '40vh',
                    justifyContent: 'center',
                    width: '100%', 
                    }}
                >
                    <BTDProgress height={50} type='circular' width={50} />
                </div>
            </>
            :
                <CustomerList 
                    isLoading={isLoading}  
                    items={items} 
                    onChange={() => {onChange()}}
                />
                }

            {meta && meta.total > meta.per_page &&
                <BTDPagination
                    currentPage={meta.current_page} 
                    totalPages={meta.last_page}
                    onChange={(value) => {
                        setPage(value);
                        onChange();
                    }}
                    style={{margin: '30px auto', width: 'max-content'}}
                />
            }	

            <AddIcon colour='#40506B' onClick={() => {
                setFormActive(true);
            }} />

            {formActive &&
                <CustomerEquipmentCreateScreen
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </CustomerEquipmentLayout>
    )

}

export default CustomerEqupimentScreen;