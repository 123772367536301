import './styles.css';

const Footer = () => {

	return (
		<footer className="Footer">

			&copy; {new Date().getFullYear()} Behind The Dot Ltd

		</footer>
	)
}

export default Footer;