/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
	STAGE: process.env.REACT_APP_STAGE,
	EDITOR_API_KEY: 'zk1783nnv3566cb03f9k4ze615o2ofnhtezwz0jobn5c790t'
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

	/*
	Production Environment Configuration
	*/
	production: {
		API_URL: "https://wild-api.westwardinstruments.com"
	},
	

	/*
	Staging Environment Configuration
	*/
	staging: {
		API_URL: "https://wild-api.westwardinstruments.com"
	},


	/*
	Development Environment Configuration
	*/
	development: {
		API_URL: "http://westward.api.local"
	},


	/*
	Local Environment Configuration
	*/
	local: {
		API_URL: "http://westward.api.local"
	}

}

const config = {
	...generalConfig,
	...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config