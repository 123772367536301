// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'
import dayjs from 'dayjs';
import BTDTab from 'components/BTDUI/BTDTab';

const ContractJobListItem = ({ created_at, customer_order_number, engineer_report_number, equipment_count, isLoading, readings_count, status, }) => {

	const [colour, setColour] = useState('');

	useEffect(() => {

		switch (status) {
			case 'closed':
				setColour('green');
				break;
			default: 
				setColour('orange');
				break;
		}
	}, [status])

	return (
		<div className='ContractJobListItem'>

				<p className='ContractJobListItem__date blue' style={{marginRight: '20px'}}>{! isLoading ? created_at ? dayjs(created_at).format('DD/MM/YYYY') : '' : <BTDSkeleton width='50%'/>}</p> 

				<div className='ContractJobListItem__orderNum'>
					<p className='ContractJobListItem__mobileLabel blue'>Customer Order #</p>
					<p className='ContractJobListItem__value blue'>{! isLoading ? customer_order_number : <BTDSkeleton width='50%'/>}</p>
				</div>

				<div className='ContractJobListItem__reportNum'>
					<p className='ContractJobListItem__mobileLabel blue'>Engineer Report #</p>
					<p className='ContractJobListItem__value blue'>{! isLoading ? engineer_report_number : <BTDSkeleton width='50%'/>}</p>
				</div>

				<div className='ContractJobListItem__equipCount'>
					<p className='ContractJobListItem__mobileLabel blue'>Equipment Count</p>
					<p className='ContractJobListItem__value blue centre'>{! isLoading ? equipment_count : <BTDSkeleton width='50%'/>}</p>
				</div>

				<div className='ContractJobListItem__readingReg blue'>
					<p className='ContractJobListItem__mobileLabel'>Readings Reg</p>
					<p className='ContractJobListItem__value blue centre'>{! isLoading ? readings_count : <BTDSkeleton width='50%'/>}</p>
				</div>

				<p className='ContractJobListItem__status blue centre'>{! isLoading ? <BTDTab colour={colour} style={{margin: '0 auto'}} text={status} /> : <BTDSkeleton width='50%'/>}</p>
		</div>
	)
}

ContractJobListItem.propTypes = {
	created_at: PropTypes.string, 
	customer_order_number: PropTypes.string, 
	engineer_report_number: PropTypes.string,
	equipment_count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
	isLoading: PropTypes.bool, 
	readings_count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
	status: PropTypes.string, 
}

ContractJobListItem.defaultProps = {
	created_at: '',
	customer_order_number: '',
	engineer_report_number: '',
	equipment_count: '',
	isLoading: false,
	readings_count: '',
	status: '',
}

export default ContractJobListItem;