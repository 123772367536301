// Packages
import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Context
import { useSnackbar } from 'context/Snackbar';
import { useUser } from 'context/User';

// Components
import Navigation from 'components/Navigation';
import Container from 'components/BTDUI/Container';
import Overlay from 'components/BTDUI/Overlay';
import MobileMenu from 'components/MobileMenu';

// other
import './styles.css';
import logo from 'assets/westward-white.svg';
import config from 'other/config';

const Header = () => {

	const [overlayActive, setOverlayActive] = useState(false);
	const [menuActive, setMenuActive] = useState(false);
    const [hambergerActive, setHamburgerActive] = useState(false);

	const navigate = useNavigate();

	const { showSnackbar } = useSnackbar();
	const { user } = useUser();

	const token = localStorage.getItem('access-token');

	const logout = async () => {

		try {
			const response = await axios.post(config.API_URL + '/logout', {}, {
				headers: {
					Authorization: 'Bearer ' + token
				}});
	
			if (response.status >= 200 && response.status < 300) {

				localStorage.removeItem('access-token');

				showSnackbar('Logged out', 'info')
				
				navigate('/login');
			}
			
		} catch (error) {

			console.error('Logout failed', error);
	
			if (error.response.status === 401) {
				
				showSnackbar('Unauthorised', 'error');

				navigate('/login');

			} else {

				showSnackbar(error.response.message, 'error')
			}
		}
	}

	return (
		<>

		<header className='Header__top'>
			<Container>
				<span className='white regular small'>{user ? user.firstname + ' ' + user.lastname : ''} logged in</span>
				<div className='header__top__right'>
					{user && user.length !== 0 &&
						<Link to='/profile' className='white regular small'>Your Profile</Link>
					}
					<span onClick={() => {logout()}} className='header__top__right__logout white regular small'>Logout</span>
				</div>
			</Container>
		</header>

		<header className="Header">

			<Container>
				<Link to='/'><img className='Header__logo' src={logo} alt="Westward logo"/></Link>

				<div className='Header__navigation'>
					<Navigation items={[
						{ label: 'Customers', url: '/customers' },
						{ label: 'Suppliers', url: '/suppliers' },
						{ label: 'Stock', url: '/stock' },
						{ label: 'Contracts', url: '/contracts' },
						{ label: 'Purchase Orders', url: '/purchaseorders' },
					]}/>
				</div>

				<button 
                    className={`hamburger hamburger--spin is-active-${hambergerActive}`} 
                    onClick={() => {
						if(! hambergerActive) {
							setHamburgerActive(true);
							setOverlayActive(true);
							setMenuActive(true);
						}
						else {
							setHamburgerActive(false);
							setMenuActive(false);
							setOverlayActive(false);
						}
                    }} 
                    type="button"
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>

                {menuActive &&
					<MobileMenu onClick={() => {
						setMenuActive(false);
						setHamburgerActive(false);
						setOverlayActive(false);
					}} user={user ?? null} />
				}

			</Container>


		</header>

		{overlayActive &&
		
			<Overlay onClick={() => {
				setMenuActive(false);
				setOverlayActive(false);
				setHamburgerActive(false);
			}} />
		}

		</>
	)

}

export default Header;