// Packages
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const TestSpecificationListItem = ({ isLoading, name, code }) => {

	return (
		<div className='TestSpecificationListItem'>
			<p className='blue' style={{paddingRight: '15px'}}>{! isLoading ? name : <BTDSkeleton width='50%'/>}</p> 
			<p className='TestSpecificationListItem__code blue'>{! isLoading ? code : <BTDSkeleton width='50%'/>}</p> 
		</div>
	)
}

TestSpecificationListItem.propTypes = {
	isLoading: PropTypes.bool,
	name: PropTypes.string, 
	code: PropTypes.string, 
}

TestSpecificationListItem.defaultProps = {
	isLoading: false,
	name: '',
	code: '',
}

export default TestSpecificationListItem;