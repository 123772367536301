// Packages
import { useNavigate, useParams } from 'react-router-dom';

// Components
import TestResultsForm from 'modules/TestResults/components/Form';
import TestResultsLayout from 'modules/TestResults/layouts';

// Other
import useApi from 'hooks/useApi';

const TestEquipmentEditScreen = () => {

    const { jobId, equipmentId, resultsId } = useParams();

    const { data: contractJob } = useApi('/contractjobs/' + jobId + '?with=contract,engineers');
    const { data: equipment, put: updateEquipment, errors: equipmentErrors } = useApi('/customerequipment/' + equipmentId + '?with=customerequipmentscale');
    const { data: results, errors: resultsErrors, put: updateResults } = useApi('/testresults/' + resultsId + '?with=engineer,testresultsscale');

    const navigate = useNavigate();

	const submit = async (values) => {

        if( values.equipmentData && values.equipmentData.length > 0 ) {

            if( ! await updateEquipment({equipment: values.equipmentData, scale: values.equipmentScaleData}, 'Customer Equipment updated!')) {

                return false;
            }
        }

        if( await updateResults({results: values.results, scale: values.resultsScale}, 'Test results updated!')) {

            navigate('/contracts/' + contractJob.contract_id + '/jobs/' + jobId);
        }
    }

    return(
        <TestResultsLayout contract_job={contractJob ?? {}}>

            <TestResultsForm
                equipment={equipment}
                equipmentErrors={equipmentErrors} 
                job={contractJob}
                onSubmit={submit} 
                results={results}
                resultsErrors={resultsErrors} 
                type='create' 
            />

        </TestResultsLayout>
    )
}

export default TestEquipmentEditScreen;