// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';

const SupplierViewLayout = ({ children }) => {

	return (
		<div className='SupplierLayout'>
			
			<Header/>

			<Container>

				{children}

			</Container>

			<Footer/>
        </div>
	)
}

SupplierViewLayout.propTypes = {
	children: PropTypes.node,
}

SupplierViewLayout.defaultProps = {
	children: null,
}

export default SupplierViewLayout