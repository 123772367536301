// Packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckedLink from 'components/BTDUI/CheckedLink';

// Context
import { useUser } from 'context/User';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const Navigation = ({ items }) => {

	const { user } = useUser();

	const currentPath = window.location.pathname;
	const urlFirst = currentPath.split('/')[1];

	const { data: pdfUrl, post: generatePDF } = useApi('/testresults/generate-pdf', false);

	useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])

	return (
		<nav className="Navigation">

			<ul className="Navigation__list">

			{items.map((item) => {

				return (
					<li key={item.url} className="Navigation__list__item">
						<CheckedLink to={item.url} className={`Navigation__list__item__link white ${('/' + urlFirst === item.url) || (urlFirst === '' && item.url === '/customers') ? 'current' : ''}`} label={item.label} />
					</li>
				)

			})}

				<li className="Navigation__list__item">
					<BTDMenu icon={<EllipsisIcon colour='#fff' height="20px" style={{backgroundColor: '#253551', borderRadius: '50%', padding: '8px 7px 6px 7px',  transform: 'rotate(90deg)'}} width='20px' />}>
						<div><CheckedLink to='/testequipment' label='Test Equipment'/></div>
						<div><CheckedLink to='/testspecifications' label='Test Specifications'/></div>
						<div><CheckedLink to='/manufacturers' label='Manufacturers'/></div>
						<div><CheckedLink to='/procedures' label='Procedures'/></div>
						<div onClick={() => {generatePDF({})}}>Blank scales certificate</div>
						{ user && user.is_admin ?
							<div><CheckedLink to='/users' label='Users'/></div>
							:
							<></>
						}
					</BTDMenu>
				</li>

				<a id="downloadLink" href=""/>

			</ul>

		</nav>
	)
}

Navigation.defaultProps = {
	items: []
}

Navigation.propTypes = {
	items: PropTypes.array.isRequired
}

export default Navigation;