// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';

const TestEquipmentForm = ({ errors, testEquipment, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    

    // Load Item values into form
	useEffect(() => {

		if ( testEquipment )
		{
            setData(testEquipment);
		}

	}, [testEquipment]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create test equipment' : 'Edit test equipment'}</h3>

            <BTDTextField
                error={errors && errors.name ? errors.name : null} 
                label='Name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                tabIndex={1}
                value={data.name ?? ''} 
            />

            <BTDTextField
                error={errors && errors.type ? errors.type : null} 
                label='Type'
                onChange={(value) => {
                    setData({...data, type: value});
                    setChangedData({...changedData, type: value});
                }} 
                tabIndex={2}
                value={data.type ?? ''} 
            />

            <BTDTextField
                error={errors && errors.serial ? errors.serial : null} 
                label='Serial'
                onChange={(value) => {
                    setData({...data, serial: value});
                    setChangedData({...changedData, serial: value});
                }} 
                tabIndex={3}
                value={data.serial ?? ''} 
            />

            <BTDTextField
                error={errors && errors.calibration_lab ? errors.calibration_lab : null} 
                label='Calibration Lab'
                onChange={(value) => {
                    setData({...data, calibration_lab: value});
                    setChangedData({...changedData, calibration_lab: value});
                }} 
                tabIndex={4}
                value={data.calibration_lab ?? ''} 
            />

            <BTDTextField
                error={errors && errors.cert_number ? errors.cert_number : null} 
                label='Cert Number'
                onChange={(value) => {
                    setData({...data, cert_number: value});
                    setChangedData({...changedData, cert_number: value});
                }} 
                tabIndex={5}
                value={data.cert_number ?? ''} 
            />

            <div className='form__grid-2'>

                <BTDNumberField
                    error={errors && errors.uncertainty ? errors.uncertainty : null} 
                    label='Uncertainty'
                    onChange={(value) => {
                        setData({...data, uncertainty: value});
                        setChangedData({...changedData, uncertainty: value});
                    }} 
                    step={0.01}
                    style={{display: 'block', width: '100%'}}
                    tabIndex={6}
                    value={data.uncertainty ?? ''} 
                />

                <BTDSelectField
                blankOption={false}
                    error={errors && errors.uncertainty_units ? errors.uncertainty_units : null} 
                    label='Uncertainty Units'
                    onChange={(value) => {
                        setData({...data, uncertainty_units: value});
                        setChangedData({...changedData, uncertainty_units: value});
                    }} 
                    options={[
                        {id: '%', name: '%'},
                        {id: 'mg', name: 'mg'},
                        {id: 'gms', name: 'gms'}
                    ]}
                    
                    tabIndex={7}
                    value={data.uncertainty_units ?? '%'} 
                    width='100px'
                />
            </div>

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save test equipment' : 'Update test equipment'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

TestEquipmentForm.propTypes = {
	errors: PropTypes.object,
	procedure: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

TestEquipmentForm.defaultProps = {
    errors: {},
    procedure: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default TestEquipmentForm;