// Packages
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import SearchIcon from 'components/BTDUI/icons/SearchIcon';
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const BTDAutoCompleteField = ({ description, error, isLoading, label, onChange, options, optionLabel, required, style, tabIndex, value, width }) => {

    const [active, setActive] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [displayValue, setDisplayValue] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hasInputBeenFocused, setHasInputBeenFocused] = useState(false);
    const [noOptions, setNoOptions] = useState(false);
    const [labelLoading, setlabelLoading] = useState(false);
    const [focusFromTab, setFocusFromTab] = useState(false);

    const inputRef = useRef(null);
    const elementRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {

        if( value != null && value != '' ) {

            setlabelLoading(true);

            for( let i = 0; i < options.length; i++ ) {

                if( options[i].id === value ) {

                    setDisplayValue(options[i].name);
                }
            }

            setlabelLoading(false);
        }

    }, [options, value])

    // Filters the dropdown list as per the search query
    const filteredOptions = options.filter((option) => {
        var returnValue = false;
        if(optionLabel) {
            
            for(let i = 0; i < optionLabel.length; i++) {

                if(option[optionLabel[i]] && option[optionLabel[i]].toLowerCase().includes(inputValue.toLowerCase()) ) {

                    returnValue = true;
                }
            }
        }
        else if(option.name && option.name.toLowerCase().includes(inputValue.toLowerCase()) ) {

            returnValue = true;
        }

        return(returnValue);
    });

    useEffect(() => {

        // Handle styling for no search results
        if( filteredOptions.length === 0 ) {
            setNoOptions(true);
        }
        else {
            setNoOptions(false);
        }
        
    }, [filteredOptions])

    useEffect(() => {

        if( displayValue != '') {

            setActive(true);
        }
        else {

            setActive(false);
        }
    }, [displayValue])

    useEffect(() => {

        if( inputRef && isDropdownOpen && ! hasInputBeenFocused) {

            inputRef.current.focus();
            setHasInputBeenFocused(true);
        }

        const handleClickOutside = (event) => {

            if (elementRef.current && ! elementRef.current.contains(event.target)) {

            setIsDropdownOpen(false);
        }
        };
    
        if ( isDropdownOpen ) {
        
        window.addEventListener('click', handleClickOutside);
        }
    
        return () => {
        window.removeEventListener('click', handleClickOutside);
        };
    // eslint-disable-next-line
    }, [isDropdownOpen])

    const getNestedPropertyValue = (obj, path) => {

        const properties = path.split('.');
        return properties.reduce((acc, property) => acc && acc[property], obj);
    };

    // Click on select (open/close dropdown)
    const handleClick = () => {

        if (! focusFromTab) {

            setIsDropdownOpen(! isDropdownOpen);
        }
        setFocusFromTab(false);
    };

    const handleFocus = () => {

        setFocusFromTab(true);
        setIsDropdownOpen(true);
    };

    const handleOptionClick = (optionId) => {

        onChange(optionId); 
        setIsDropdownOpen(false);
    };

    return(
        <>
        {description &&
            <p className='BTDAutoCompleteField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div 
            className={`BTDAutoCompleteField ${width} `} 
            onFocus={handleFocus}
            style={style}
            ref={elementRef}
            tabIndex={tabIndex ?? 0} 
        >
            <label className={`BTDAutoCompleteField__label ${active ? 'active' : ''}`}>
                <span className='BTDAutoCompleteField__label__wrapper'>
                    {label ?? ''}
                    {required &&
                        <span className='red'>{<>&nbsp;</>}*</span>
                    }
                </span>
            </label>
            <div 
                className='BTDAutoCompleteField__selectInput' 
                onClick={handleClick}
            >
                <span className='BTDAutoCompleteField__selectInput__value'>{displayValue}</span>
                {(isLoading || labelLoading) ?
                    <BTDProgress height={25} style={{display: 'block', margin: '0px', padding: '10px 0 0 18px'}} type='circular' width={25} />
                    :
                    <span className={`BTDAutoCompleteField__downArrow ${isDropdownOpen ? 'active' : ''}`}></span>
                }
            </div>
            {isDropdownOpen && 

                <div className="BTDAutoCompleteField__dropdown">
                    <SearchIcon colour='#bbb' style={{left: '40px', position: 'absolute', top: '40px'}}/>
                    <input 
                        className={`BTDAutoCompleteField__input ${error ?? ''} ${noOptions ? 'noOptions' : ''}`} 
                        onBlur={() => setIsDropdownOpen(false)}
                        onChange={(e) => {setInputValue(e.target.value)}}
                        ref={inputRef}
                        required={required} 
                        tabIndex={tabIndex + 0.5 ?? 0}
                        value={inputValue ?? ''}
                    />
                    <ul className="BTDAutoCompleteField__options" ref={dropdownRef}>
                        {filteredOptions.map((option, index) => {

                            var text = '';

                            if( optionLabel ) {

                                for( let i = 0; i < optionLabel.length; i++ ) {

                                    if (optionLabel[i].includes('.')) {
                                        
                                        text += getNestedPropertyValue(option, optionLabel[i]) + ' ';
                                    }
                                    else {

                                        text += option[optionLabel[i]] + ' ';
                                    }
                                }
                            }
                            else {
                                text = option.name;
                            }
                                return(
                            
                            <li
                                className='BTDAutoCompleteField__options__option'
                                key={index} 
                                onMouseDown={() => handleOptionClick(option.id)}
                            >
                                {text}
                            </li>
                        )})}
                    </ul>
                </div>
            }

        </div>
            {error &&
                <p className='BTDAutoCompleteField__error'>{error}</p>
            }
    </>
    )
}

BTDAutoCompleteField.propTypes = {
    description: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    optionLabel: PropTypes.array,
    options: PropTypes.array,
    required: PropTypes.bool,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.string
}

BTDAutoCompleteField.defaultProps = {
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    optionLabel: null,
    options: [],
    required: false,
    style: {},
    tabIndex : 0,
    value: '',
    width: 'large'
}

export default BTDAutoCompleteField;                                         