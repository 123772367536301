// Packages
import PropTypes from 'prop-types'

// Components
import ContactForm from 'modules/Contacts/components/Form';

// Other
import useApi from 'hooks/useApi';

const ContactCreateScreen = ({ onClose, onSubmit, parent, parentId }) => {

    const { post: createItem, errors } = useApi('/contacts',  false)

	const submit = async (values) => {

        if( await createItem({[parent]: parentId, ...values}, 'Contact created!') ) {
    
            onSubmit();
        }
    }


    return(
        <ContactForm 
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

ContactCreateScreen.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

ContactCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
}

export default ContactCreateScreen;