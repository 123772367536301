// Packages
import { Routes, Route } from "react-router-dom"

import { UserProvider } from 'context/User/';

// Routes
import LoginRoutes from "modules/Login/routes"
import ProfileScreen from "modules/Profile/screens"
import CustomerRoutes from "modules/Customers/routes"
import SupplierRoutes from "modules/Suppliers/routes"
import StockRoutes from "modules/Stock/routes"
import ContractRoutes from "modules/Contracts/routes"
import ProcedureRoutes from "modules/Procedures/routes"
import UserRoutes from "modules/Users/routes"
import ManufactureRoutes from "modules/Manufacturers/routes"
import TestSpecificationRoutes from "modules/TestSpecifications/routes"
import TestEquipmentRoutes from "modules/TestEquipment/routes"
import PurchaseOrderRoutes from "modules/PurchaseOrders/routes"
import ContractJobRoutes from "modules/ContractJobs/routes"
import TestResultsRoutes from "modules/TestResults/routes";
import CustomerEquipmentRoutes from "modules/CustomerEquipment/routes";


// Wrapper component for protected routes
const ProtectedRoutes = () => (
	<UserProvider>
		<Routes>
			<Route path="/profile/*" element={<ProfileScreen />} />
			<Route path="/customers/*" element={<CustomerRoutes />} />
			<Route path="/suppliers/*" element={<SupplierRoutes />} />
			<Route path="/stock/*" element={<StockRoutes />} />
			<Route path="/contracts/*" element={<ContractRoutes />} />
			<Route path="/manufacturers/*" element={<ManufactureRoutes />} />
			<Route path="/procedures/*" element={<ProcedureRoutes />} />
			<Route path="/users/*" element={<UserRoutes />} />
			<Route path="/testspecifications/*" element={<TestSpecificationRoutes />} />
			<Route path="/testequipment/*" element={<TestEquipmentRoutes />} />
			<Route path="/purchaseorders/*" element={<PurchaseOrderRoutes />} />
			<Route path="/contracts/:contractId/jobs/:jobId/equipment/:equipmentId/results/*" element={<TestResultsRoutes />} />
			<Route path="/customerequipment/*" element={<CustomerEquipmentRoutes />} />
			<Route path="/contracts/:contractId/jobs/*" element={<ContractJobRoutes />} />
			<Route index element={<CustomerRoutes />} />
		</Routes>
	</UserProvider>
);

const MainRoutes = () => {
	return (
		<Routes>
			{/* Login Routes (outside of UserProvider) */}
			<Route path="/login/*" element={<LoginRoutes />} />
	
			{/* Protected Routes */}
			<Route path="/*" element={<ProtectedRoutes />} />
		</Routes>
	);
};

export default MainRoutes;