// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Context
import { useUser } from 'context/User';

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';

const UserForm = ({ errors, item, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    
    const { user } = useUser();

    // Load Item values into form
	useEffect(() => {

		if ( item )
		{
            setData(item);
		}

	}, [item]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create user' : 'Edit user'}</h3>

            <div className='form__grid-2'>

                <BTDTextField
                    error={errors && errors.firstname ? errors.firstname : null} 
                    label='First Name'
                    onChange={(value) => {
                        setData({...data, firstname: value});
                        setChangedData({...changedData, firstname: value});
                    }} 
                    required={true}
                    tabIndex={1}
                    value={data.firstname ?? ''} 
                />

                <BTDTextField
                    error={errors && errors.lastname ? errors.lastname : null} 
                    label='Last Name'
                    onChange={(value) => {
                        setData({...data, lastname: value});
                        setChangedData({...changedData, lastname: value});
                    }} 
                    required={true}
                    tabIndex={2}
                    value={data.lastname ?? ''} 
                />

            </div>

                <BTDTextField
                    error={errors && errors.username ? errors.username : null} 
                    label='Username'
                    onChange={(value) => {
                        setData({...data, username: value});
                        setChangedData({...changedData, username: value});
                    }} 
                    required={true}
                    tabIndex={3}
                    value={data.username ?? ''} 
                />
            <div className='form__grid-2'>

                <BTDTextField
                    error={errors && errors.initials ? errors.initials : null} 
                    label='Initials'
                    onChange={(value) => {
                        setData({...data, initials: value});
                        setChangedData({...changedData, initials: value});
                    }} 
                    tabIndex={4}
                    value={data.initials ?? ''} 
                />

                <BTDSelectField
                    blankOption={false}
                    error={errors && errors.type ? errors.type : null} 
                    label='Type'
                    onChange={(value) => {
                        setData({...data, type: value});
                        setChangedData({...changedData, type: value});
                    }} 
                    options={[
                        {id: 'admin', name: 'Admin'},
                        {id: 'engineer', name: 'Engineer'},
                    ]}
                    required={true}
                    tabIndex={5}
                    value={data.type ?? 'engineer'} 
                />

            </div>

            <BTDTextField
                    error={errors && errors.email ? errors.email : null} 
                    label='Email'
                    onChange={(value) => {
                        setData({...data, email: value});
                        setChangedData({...changedData, email: value});
                    }} 
                    tabIndex={6}
                    value={data.email ?? ''} 
                />

            <BTDSwitch
                checked={data.is_admin ?? false}
                error={errors && errors.is_admin ? errors.is_admin : null} 
                label='Able to edit users'
                onChange={(value) => {
                    setData({...data, is_admin: value});
                    setChangedData({...changedData, is_admin: value});
                }} 
            />

            {user.is_admin &&
                <div className='form__grid-2' style={{marginBottom: '30px'}}>
                <BTDTextField
                    error={errors && errors.password ? errors.password : null} 
                    label='Change Password'
                    onChange={(value) => {
                        setData({...data, password: value});
                        setChangedData({...changedData, password: value});
                    }} 
                    style={{marginBottom: '4px'}}
                    type='password'
                    tabIndex={7}
                    value={data.password ?? ''} 
                />
                <BTDTextField
                    error={errors && errors.password_confirmation ? errors.password_confirmation : null} 
                    label='Password confirm'
                    onChange={(value) => {
                        setData({...data, password_confirmation: value});
                        setChangedData({...changedData, password_confirmation: value});
                    }} 
                    style={{marginBottom: '4px'}}
                    type='password'
                    tabIndex={8}
                    value={data.password_confirmation ?? ''} 
                />
                {data.password && data.password.length !== 0 && data.password_confirmation && data.password_confirmation.length !== 0 && data.password !== data.password_confirmation &&
                    <p className='red'>Passwords must match!</p>
                }
                {data.password && data.password.length !== 0 && data.password_confirmation && data.password_confirmation.length !== 0 && data.password === data.password_confirmation &&
                    <p className='green'>Passwords match!</p>
                }
                </div>
            }

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save user' : 'Update user'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

UserForm.propTypes = {
	errors: PropTypes.object,
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

UserForm.defaultProps = {
    errors: {},
    user: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default UserForm;