// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import SupplierListScreen from "modules/Suppliers/screens/List"
import SupplierViewScreen from "modules/Suppliers/screens/View"


const SupplierRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<SupplierListScreen />}/>
			<Route path="/:supplierId/*" element={<SupplierViewScreen />}/>
		</Routes>
	)
}

export default SupplierRoutes;