// Packages
import { useParams } from 'react-router-dom';

// Components
import TestResultsForm from 'modules/TestResults/components/Form';
import TestResultsLayout from 'modules/TestResults/layouts';

// Other
import useApi from 'hooks/useApi';

const TestResultsCreateScreen = () => {

    const { jobId, equipmentId } = useParams();

    const { data: contractJob } = useApi('/contractjobs/' + jobId + '?with=contract,engineers');
    const { data: equipment, put: updateEquipment, errors: equipmentErrors } = useApi('/customerequipment/' + equipmentId + '?with=customerequipmentscale');
    const { data: results, post: createItem, errors: resultsErrors } = useApi('/testresults?with=,engineer,testresultsscale', false);
    
    const submit = async (values) => {

        if( values.equipmentData && values.equipmentData.length > 0 ) {

            if( ! await updateEquipment({equipment: values.equipmentData, scale: values.equipmentScaleData}, 'Customer Equipment updated!')) {

                return false;
            }
        }

        await createItem({results: values.results, scale: values.resultsScale}, 'Test results saved!', '/contracts/' + contractJob.contract_id + '/jobs/' + jobId + '?results=')
    }


    return(
        <TestResultsLayout contract_job={contractJob ?? {}}>

            <TestResultsForm
                equipment={equipment}
                equipmentErrors={equipmentErrors} 
                job={contractJob}
                onSubmit={submit} 
                results={results}
                resultsErrors={resultsErrors} 
                type='create' 
            />

        </TestResultsLayout>
    )
}

export default TestResultsCreateScreen;