// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import ListItem from 'modules/Stock/components/ListItem';

// Other
import './styles.css';

const StockList = ({ isLoading, items, onChange, query }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='StockList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No results</h3>
		)
	}


	// Data State
	return (
		<div className='StockList'>

			{items.map((item, index) => {

				return (
						<Link className='StockList__link' to={'/stock/' + item.id + (query !== '' ? '?query=' + query : '')} key={index}>
							<ListItem 
								{...item} 
								isLoading={false}
								onChange={() => {onChange()}}
							/>
						</Link>
				)
				
			})}

		</div>
	)
}

StockList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
	query: PropTypes.string
}

StockList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
	query: ''
}

export default StockList;