// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import ScreenTitle from 'components/ScreenTitle';
import Header from 'components/Header';
import Footer from 'components/Footer';

const ProcedureLayout = ({ children, title }) => {

	return (
		<div className='ProcedureLayout'>
			
			<Header/>

			<Container>

				<ScreenTitle title={title}/>

				{children}

			</Container>

			<Footer/>
        </div>
	)
}

ProcedureLayout.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string
}

ProcedureLayout.defaultProps = {
	children: null,
	title: ''
}

export default ProcedureLayout;