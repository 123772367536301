// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import PurchaseOrderListScreen from "modules/PurchaseOrders/screens/List"
import PurchaseOrderViewScreen from "modules/PurchaseOrders/screens/View"


const StockRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<PurchaseOrderListScreen />}/>
			<Route path="/:purchaseOrderId/*" element={<PurchaseOrderViewScreen />}/>
		</Routes>
	)
}

export default StockRoutes;