// Packages
import PropTypes from 'prop-types'

// Components
import CustomerStockPriceForm from 'modules/CustomerStockPrices/components/Form';

// Other
import useApi from 'hooks/useApi';

const CustomerStockPriceEditScreen = ({  itemId, onClose, onSubmit, customerId }) => {

    const { data: item, put: updateItem, errors } = useApi('/customerstockprices/' + itemId);

	const submit = async (values) => {

        if( await updateItem({customer_id: customerId, ...values}, 'Customer stock price updated!')) {

            onSubmit();
        }
    }

    return(
        <CustomerStockPriceForm 
            errors={errors} 
            customer_stock_price={item} 
            onClose={onClose} 
            onSubmit={submit} 
            type='edit' 
        />
    )
}

CustomerStockPriceEditScreen.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

CustomerStockPriceEditScreen.defaultProps = {
    itemId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
    customerId: ''
}

export default CustomerStockPriceEditScreen;