// Packages
import PropTypes from 'prop-types'

// Components
import ManufacturerForm from 'modules/Manufacturers/components/Form';

// Other
import useApi from 'hooks/useApi';

const ManufacturerEditScreen = ({  itemId, onClose, onSubmit }) => {

    const { data: item, put: updateItem, errors } = useApi('/manufacturers/' + itemId);

	const submit = async (values) => {

        if( await updateItem(values, 'Manufacturer updated!')) {

            onSubmit();
        }
    }

    return(
        <ManufacturerForm 
            errors={errors} 
            manufacturer={item} 
            onClose={onClose} 
            onSubmit={submit} 
            type='edit' 
        />
    )
}

ManufacturerEditScreen.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

ManufacturerEditScreen.defaultProps = {
    itemId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default ManufacturerEditScreen;