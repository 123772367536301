// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import CustomerListScreen from "modules/Customers/screens/List"
import CustomerViewScreen from "modules/Customers/screens/View"


const CustomerRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<CustomerListScreen />}/>
			<Route path="/:customerId/*" element={<CustomerViewScreen />}/>
		</Routes>
	)
}

export default CustomerRoutes;