// Packages
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import ClearIcon from 'components/BTDUI/icons/ClearIcon';

// Other
import './styles.css';

const BTDMultiselect = ({className, description, error, label, onChange, options, optionLabel, required, style, tabIndex, value, width, }) => {

    const [active, setActive] = useState(false);
    const [selectedValues, setSelectedValues] = useState(value || []);
    const [open, setOpen] = useState(false);
    const [focusFromTab, setFocusFromTab] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const dropdownRef = useRef(null);
    const node = useRef();

    useEffect(() => {

        setSelectedValues(value);

    }, [value])

    useEffect(() => {

        if (selectedValues.length > 0) {

            setActive(true);

        } else {

            setActive(false);
        }

    }, [selectedValues]);

    const handleOptionClick = (optionId) => {

        let newSelectedValues = [...selectedValues];

            if (newSelectedValues.includes(optionId)) {

                newSelectedValues = newSelectedValues.filter((value) => value !== optionId);

            } else {

                newSelectedValues.push(optionId);
            }

        setSelectedValues(newSelectedValues);
        onChange(newSelectedValues);
    };

    const handleClickOutside = (e) => {

        if (node.current.contains(e.target)) {

            return;
        }
        setOpen(false);
    };

    useEffect(() => {

        if (open) {

            document.addEventListener('mousedown', handleClickOutside);
        } else {

            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {

            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const getNestedPropertyValue = (obj, path) => {
        const properties = path.split('.');
        return properties.reduce((acc, property) => acc && acc[property], obj);
    };

    const handleKeyDown = (e) => {

        if (['ArrowUp', 'ArrowDown'].includes(e.key)) {

            e.preventDefault();
            const direction = e.key === 'ArrowUp' ? -1 : 1;
            const newIndex = (selectedIndex + direction + options.length) % options.length;
            setSelectedIndex((prevIndex) => (prevIndex + direction + options.length) % options.length);
            const optionHeight = dropdownRef.current.firstChild.clientHeight;
            const scrollTop = Math.max(0, newIndex * optionHeight - optionHeight * 2);
            dropdownRef.current.scrollTop = scrollTop;

        } else if (e.key === 'Enter' && open) {

            // Handle Enter key to select options
            handleOptionClick(options[selectedIndex].id);
        }
    };

    const handleFocus = () => {

        setFocusFromTab(true);
        setOpen(true);
    };

    const handleClick = () => {

        if (! focusFromTab) {

            setOpen(! open);
        }
        setFocusFromTab(false);
    };

    return (
        <>
        {description &&
            <p className='BTDMultiselect__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
    
            <div 
                onBlur={() => {setOpen(false)}}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                ref={node} 
                className={`BTDMultiselect ${className} ${width}`} 
                style={style}
                tabIndex={tabIndex ?? 0} 
            >

                <div 
                    className={`BTDMultiselect__select ${active ? 'active' : ''}`} 
                    onClick={() => {handleClick()}} 
                >

                    {options.length > 0 && options.map((option, index) => {

                        if( selectedValues.includes(option.id)) {

                            let text = '';

                            if( optionLabel ) {

                                for( let i = 0; i < optionLabel.length; i++ ) {

                                    if (optionLabel[i].includes('.')) {
                                        
                                        text += getNestedPropertyValue(option, optionLabel[i]) + ' ';
                                    }
                                    else {

                                        text += option[optionLabel[i]] + ' ';
                                    }
                                }
                            }
                            else {
                                text = option.name;
                            }
                            return(
                                <span className='BTDMultiselect__select__tab' key={index}>
                                    {text} 
                                    <ClearIcon 
                                        colour='#aaa' 
                                        height='16px' 
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            handleOptionClick(option.id);
                                        }} 
                                        style={{cursor: 'pointer', marginLeft: '5px', transform: 'translateY(1px)'}} 
                                        width='16px'
                                    />
                                </span>
                            )
                        }
                        else {
                            return(<></>)
                        }
                    })}

                    <span className={`BTDMultiselect__select__downArrow ${open ? 'open' : ''}`}></span>

                    <div className={`BTDMultiselect__label ${active ? 'active' : ''}`}>
                        <span className='BTDMultiselect__label__wrapper'>
                            {label ?? ''}
                            {required && <span className="red">{<>&nbsp;</>}*</span>}
                        </span>
                    </div>

                </div>

                {open && (
                <div className="BTDMultiselect__dropdown" ref={dropdownRef}>
                    {options.map((option, index) => {
                        
                        let text = '';

                        if( optionLabel ) {

                            for( let i = 0; i < optionLabel.length; i++ ) {

                                if (optionLabel[i].includes('.')) {
                                        
                                    text += getNestedPropertyValue(option, optionLabel[i]) + ' ';
                                }
                                else {

                                    text += option[optionLabel[i]] + ' ';
                                }
                            }
                        }
                        else {
                            text = option.name;
                        }

                        return(
                    <div
                        key={index}
                        className={`BTDMultiselect__dropdown__option ${selectedIndex === index ? 'selected' : ''}`}
                        onClick={() => handleOptionClick(option.id)}
                    >
                        {text}
                        {selectedValues.includes(option.id) ? <span> &#10003;</span> : null}
                    </div>
                    )})}
                </div>
                )}
            </div>
                {error && <p className="BTDMultiselect__error">{error}</p>}
        </>
    );
};

BTDMultiselect.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    optionLabel: PropTypes.array,
    options: PropTypes.array,
    required: PropTypes.bool,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    width: PropTypes.string,
};

BTDMultiselect.defaultProps = {
    className: '',
    description: null,
    error: null,
    label: '',
    onChange: () => {
    alert('onChange is not set!');
    },
    optionLabel: null,
    options: [],
    required: false,
    style: {},
    tabIndex : 0,
    value: [],
    width: 'large',
};

export default BTDMultiselect;
