// Packages
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const ProcedureListItem = ({ isLoading, name, number }) => {

	return (
		<div className='ProcedureListItem'>
			<h3 className='blue'>{! isLoading ? number : <BTDSkeleton width='50%'/>}</h3> 
			<h3 className='ProcedureListItem__name blue'>{! isLoading ? name : <BTDSkeleton width='50%'/>}</h3> 
		</div>
	)
}

ProcedureListItem.propTypes = {
	isLoading: PropTypes.bool,
	name: PropTypes.string, 
	number: PropTypes.string, 
}

ProcedureListItem.defaultProps = {
	isLoading: false,
	name: '',
	number: '',
}

export default ProcedureListItem;