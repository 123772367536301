// Packages
import PropTypes from 'prop-types';

const Search2Icon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="Search2Icon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
                <title>search</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Customers---List" transform="translate(-126, -304)" fill={colour} fillRule="nonzero">
                        <g id="search" transform="translate(126, 304)">
                            <path d="M11.499375,11.428125 C10.9675,11.96 10.261875,12.2525 9.509375,12.2525 C8.756875,12.2525 8.05125,11.96 7.519375,11.428125 C6.9875,10.896875 6.695625,10.190625 6.695625,9.43875 C6.695625,8.686875 6.988125,7.980625 7.519375,7.44875 C8.05125,6.916875 8.756875,6.624375 9.509375,6.624375 C10.261875,6.624375 10.9675,6.916875 11.499375,7.44875 C12.03125,7.98 12.323125,8.686875 12.323125,9.43875 C12.323125,10.190625 12.03125,10.896875 11.499375,11.428125 Z M20,10 C20,15.51375 15.51375,20 10,20 C4.48625,20 0,15.51375 0,10 C0,4.48625 4.48625,0 10,0 C15.51375,0 20,4.485625 20,10 Z M14.449375,13.48 L12.795625,11.821875 C13.298125,11.1325 13.573125,10.3075 13.573125,9.438125 C13.573125,8.3525 13.150625,7.331875 12.383125,6.564375 C11.615625,5.796875 10.595625,5.37375 9.509375,5.37375 C8.423125,5.37375 7.403125,5.796875 6.635625,6.564375 C5.868125,7.331875 5.445625,8.3525 5.445625,9.438125 C5.445625,10.52375 5.86875,11.544375 6.635625,12.31125 C7.403125,13.07875 8.423125,13.501875 9.509375,13.501875 C10.3875,13.501875 11.22125,13.220625 11.915,12.708125 L13.564375,14.361875 C13.68625,14.484375 13.84625,14.545625 14.006875,14.545625 C14.166875,14.545625 14.32625,14.485 14.448125,14.363125 C14.6925,14.120625 14.6925,13.724375 14.449375,13.48 L14.449375,13.48 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

Search2Icon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Search2Icon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default Search2Icon;