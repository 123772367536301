// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import Container from 'components/BTDUI/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';

const TestSpecificationViewLayout = ({ children }) => {

	return (
		<div className='TestSpecificationViewLayout'>
			
			<Header/>

			<Container>

				<Link className='blue opacity' to='/testspecifications'>Test Specifications {'>'}</Link> 

				{children}

			</Container>

			<Footer/>
        </div>
	)
}

TestSpecificationViewLayout.propTypes = {
	children: PropTypes.node,
}

TestSpecificationViewLayout.defaultProps = {
	children: null,
}

export default TestSpecificationViewLayout;