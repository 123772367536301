// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';

// Other
import useApi from 'hooks/useApi';

const StockForm = ({ errors, stock, onClose, onSubmit, type, }) => {

    const { data: suppliers, isLoading } = useApi('/suppliers?per_page=99999');

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    

    // Load Item values into form
	useEffect(() => {

		if ( stock )
		{
            setData(stock);
		}

	}, [stock]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create stock' : 'Edit stock'}</h3>
        
            <BTDTextField 
                error={errors && errors.reference ? errors.reference : null} 
                label='Product Number'
                onChange={(value) => {
                    setData({...data, reference: value});
                    setChangedData({...changedData, reference: value});
                }} 
                required={true}
                tabIndex={1}
                value={data.reference ?? ''} 
            />

            <BTDTextField 
                error={errors && errors.name ? errors.name : null} 
                label='Name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                tabIndex={2}
                value={data.name ?? ''} 
            />

            <BTDAutoCompleteField
                error={errors && errors.supplier_id ? errors.supplier_id : null} 
                isLoading={isLoading}
                label='Supplier'
                onChange={(value) => {
                    setData({...data, supplier_id: value});
                    setChangedData({...changedData, supplier_id: value});
                }} 
                options={suppliers ?? []}
                tabIndex={3}
                value={data.supplier_id ?? ''} 
            />  

            <BTDTextField 
                error={errors && errors.supplier_reference ? errors.supplier_reference : null} 
                label='Supplier Part No.'
                onChange={(value) => {
                    setData({...data, supplier_reference: value});
                    setChangedData({...changedData, supplier_reference: value});
                }} 
                tabIndex={4}
                value={data.supplier_reference ?? ''} 
            />

            <div className='form__grid-2'>

                <BTDNumberField
                    error={errors && errors.quantity ? errors.quantity : null} 
                    label='Stock Quantity'
                    onChange={(value) => {
                        setData({...data, quantity: value});
                        setChangedData({...changedData, quantity: value});
                    }}
                    style={{width: '100%'}} 
                    tabIndex={4}
                    value={data.quantity ?? ''} 
                />

                <BTDNumberField  
                    error={errors && errors.reorder_level ? errors.reorder_level : null} 
                    label='Minimum Stock Qty'
                    onChange={(value) => {
                        setData({...data, reorder_level: value});
                        setChangedData({...changedData, reorder_level: value});
                    }} 
                    style={{width: '100%'}} 
                    tabIndex={5}
                    value={data.reorder_level ?? ''} 
                />
            </div>

            <div className='form__grid-2'>
                <BTDNumberField 
                    error={errors && errors.cost_price ? errors.cost_price : null} 
                    label='Cost Price'
                    onChange={(value) => {
                        setData({...data, cost_price: value});
                        setChangedData({...changedData, cost_price: value});
                    }} 
                    step={0.01}
                    style={{width: '100%'}} 
                    tabIndex={6}
                    value={data.cost_price ?? ''} 
                />
                <BTDNumberField 
                    error={errors && errors.price ? errors.price : null} 
                    label='Sale Price'
                    onChange={(value) => {
                        setData({...data, price: value});
                        setChangedData({...changedData, price: value});
                    }} 
                    step={0.01}
                    style={{width: '100%'}} 
                    tabIndex={7}
                    value={data.price ?? ''} 
                />
            </div>

            {type === 'create' &&
                <BTDTextareaField
                    error={errors && errors.notes ? errors.notes : null} 
                    label='Notes'
                    onChange={(value) => {
                        setData({...data, notes: value});
                        setChangedData({...changedData, notes: value});
                    }} 
                    rows={3}
                    tabIndex={8}
                    value={data.notes ?? ''} 
                />
            }


            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save stock' : 'Update stock'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

StockForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

StockForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default StockForm;