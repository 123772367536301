// Packages
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Context
import { useSnackbar } from 'context/Snackbar';
import { useUI } from 'context/UI';

// Components
import LoginLayout from 'modules/Login/layouts';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import './styles.css';
import config from 'other/config';
import logo from 'assets/westward-white.svg';

const LoginScreen = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { setLoading } = useUI();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const url = new URL(window.location.href);

    const submit = async () => {

        setLoading(true);

        try {

            const response = await axios.post(config.API_URL + '/sanctum/token', {username: username, password: password});

            if (response.status >= 200 && response.status < 300) {

                localStorage.setItem('access-token', response.data.access_token);

                let redirect = decodeURIComponent(url.searchParams.get('redirect'));

                let redirectUrl = redirect != null && redirect !== 'null' ? redirect : '/';

                navigate(redirectUrl);
            }
            else {

                showSnackbar(response.error, 'error');
            }

        } catch (error) {

            showSnackbar('Login failed', error.response.message);
        }

        setLoading(false);

    }

    return(
        <LoginLayout>

            <div className='LoginScreen'>

                <div className='LoginScreen__header'>
                    <img className='LoginScreen__header__logo' src={logo} alt="Westward logo"/>
                    <p className='white medium'>CRM Login</p>
                </div>

                <div className='LoginScreen__main'>

                    <BTDTextField
                        autocomplete={true}
                        label='Your Username'
                        onChange={(value) => setUsername(value)}
                        style={{backgroundColor: '#fff'}}
                        tabIndex={1}
                        value={username}
                    />
                    <BTDTextField
                        autocomplete={true}
                        label='Your Password'
                        onChange={(value) => setPassword(value)}
                        type='password'
                        style={{backgroundColor: '#fff'}}
                        tabIndex={2}
                        value={password}
                    />

                    <BTDButton
                        formButton={true}
                        label='Login >'
                        onClick={() => submit()}
                    />
                </div>
            </div>


        </LoginLayout>
    )

}

export default LoginScreen;