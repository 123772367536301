// Packages
import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import SupplierEditScreen from 'modules/Suppliers/screens/Edit';
import SupplierViewLayout from 'modules/Suppliers/layouts/View';
import ContactCreateScreen from 'modules/Contacts/screens/Create';
import NoteCreateScreen from 'modules/Notes/screens/Create';
import NoteList from 'modules/Notes/components/List';
import ContactList from 'modules/Contacts/components/List';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import PurchaseOrderList from 'modules/PurchaseOrders/components/List';
import StockList from 'modules/Stock/components/List';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const SupplierViewScreen = () => {

	const { supplierId } = useParams();

	const { data: supplier, isLoading, onChange } = useApi('/suppliers/' + supplierId + '?with=contacts,notes,notes.lastupdateduser,purchaseorders,purchaseorders.supplier,category,stock,createdbyuser,updatedbyuser');
	const { destroy } = useApi('/suppliers/' + supplierId, false);

	const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('query');

	const [formActive, setFormActive] = useState(false);
	const [contactFormActive, setContactFormActive] = useState(false);
	const [noteFormActive, setNoteFormActive] = useState(false);
	const [activeTab, setActiveTab] = useState('people');

	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('supplier', 'Supplier Deleted!')) {

			navigate('/suppliers');
		}

    }

	return (
		<SupplierViewLayout className='SupplierViewScreen'>

			<Link className='blue opacity' to={`/suppliers${query ? '?query=' + query: ''}`}>{query ? 'Back to search results' : 'Suppliers'}{' >'}</Link> 

			<div className='SupplierViewScreen__titleBar'>
				<div className='SupplierViewScreen__titleBar__left'>
					<h1 style={{marginRight: '20px'}}>{! isLoading ? supplier.name : <BTDSkeleton width='200px'/>}</h1>
				</div>
				<div className='SupplierViewScreen__titleBar__right'>
					<BTDButton
						label='Edit'
						onClick={() => {setFormActive(true)}}
						style={{marginRight: '20px'}}
					/>
					<BTDButton
						colour='red'
						label='Delete'
						onClick={() => {deleteItem()}}
					/>
				</div>
			</div>

			<div className='SupplierViewScreen__details'>
				<p>Supplier name: </p>
				<p className='semi-bold'>{! isLoading ? supplier.name : <BTDSkeleton width='100px'/>}</p>
				<p>Category: </p>
				<p className='semi-bold'>{! isLoading ? (supplier.category ? supplier.category.name : '') : <BTDSkeleton width='100px'/>}</p>
				<p>Reference: </p>
				<p className='semi-bold'>{! isLoading ? supplier.reference : <BTDSkeleton width='100px'/>}</p>
				<p>Email: </p>
				<p><Link className='SupplierViewScreen__details__email light-blue semi-bold' to={'mailto:' + supplier.email}>{! isLoading ? supplier.email : <BTDSkeleton width='100px'/>}</Link></p>
				<p>Address: </p>
				<div>
					<p className='semi-bold'>{! isLoading ? supplier.address1 : <BTDSkeleton width='100px'/>}</p>
					<p className='semi-bold'>{! isLoading ? supplier.address2 : <BTDSkeleton width='100px'/>}</p>
					<p className='semi-bold'>{! isLoading ? supplier.city : <BTDSkeleton width='100px'/>}</p>
					<p className='semi-bold'>{! isLoading ? supplier.county : <BTDSkeleton width='100px'/>}</p>
					<p className='semi-bold'>{! isLoading ? supplier.postcode : <BTDSkeleton width='100px'/>}</p>
				</div>
				<p>Telephone: </p>
				<p className='semi-bold'>{! isLoading ? supplier.telephone : <BTDSkeleton width='100px'/>}</p>
				<p>Created at: </p>
				{! isLoading ? <NameDateDisplay date={supplier.created_at} user={supplier.created_by_user} /> : <BTDSkeleton width='100px'/>}
				<p></p>
				<p></p>
				<p>Updated at: </p>
				{! isLoading ? <NameDateDisplay date={supplier.updated_at} user={supplier.updated_by_user} /> : <BTDSkeleton width='100px'/>}
			</div>

			<div className="tabs">
				<ul className="tabs__list">
					<li className={`tabs__list__item ${ activeTab === 'people' ? 'active' : ''}`} onClick={() => {setActiveTab('people')}}>People</li>
					<li className={`tabs__list__item ${ activeTab === 'po' ? 'active' : ''}`} onClick={() => {setActiveTab('po')}}>Purchase Orders</li>
					<li className={`tabs__list__item ${ activeTab === 'notes' ? 'active' : ''}`} onClick={() => {setActiveTab('notes')}}>Notes</li>
					<li className={`tabs__list__item ${ activeTab === 'stock' ? 'active' : ''}`} onClick={() => {setActiveTab('stock')}}>Stock</li>
				</ul>
			</div>

			<div className='tabs__select'>
				<BTDSelectField
					label='Select Tab'
					onChange={(value) => {setActiveTab(value)}}
					options={[
						{id: 'people', name: 'People'},
						{id: 'po', name: 'Purchase Orders'},
						{id: 'notes', name: 'Notes'},
						{id: 'stock', name: 'Stock'},
					]}
					style={{marginBottom: '30px'}}
					value={activeTab}
				/>
			</div>

			{activeTab === 'people' &&
				<>
					<BTDButton
						label='Create Contact'
						onClick={() => {setContactFormActive(true)}}
						style={{marginBottom: '30px'}}
					/>

					<ContactList
						items={! isLoading ? supplier.contacts : []}
						onChange={() => {onChange()}}
						parent='supplier_id'
						parentId={! isLoading ? supplier.id : ''}
					/>
				</>
			}

			{activeTab === 'po' &&
				<PurchaseOrderList
					items={! isLoading ? supplier.purchase_orders : []}
				/>
			}

			{activeTab === 'notes' &&
				<>
					<BTDButton
						label='Add Note'
						onClick={() => {setNoteFormActive(true)}}
						style={{marginBottom: '30px'}}
					/>

					<NoteList
						items={! isLoading ? supplier.notes : []}
						onChange={() => {onChange()}}
						parent='supplier_id'
						parentId={! isLoading ? supplier.id : ''}
					/>
				</>
			}

			{activeTab === 'stock' &&
				<>
					<StockList
						items={! isLoading ? supplier.stock : []}
						onChange={() => {onChange()}}
					/>
				</>
			}

			{formActive &&
				<SupplierEditScreen
					itemId={supplierId}
					onClose={() => {
						setFormActive(false);
					}} 
					onSubmit={() => {
						onChange();
						setFormActive(false);
					}} 
				/>
			}

			{contactFormActive &&
				<ContactCreateScreen
					onClose={() => {
						setContactFormActive(false);
					}} 
					onSubmit={() => {
						onChange();
						setContactFormActive(false);
					}} 
					parent='supplier_id'
					parentId={! isLoading ? supplier.id : ''}
				/>
			}

			{noteFormActive &&
				<NoteCreateScreen
					onClose={() => {
						setNoteFormActive(false);
					}} 
					onSubmit={() => {
						onChange();
						setNoteFormActive(false);
					}} 
					parent='supplier_id'
					parentId={! isLoading ? supplier.id : ''}
				/>
			}
			
		</SupplierViewLayout>

	)

}

export default SupplierViewScreen