// Packages
import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import ManufacturerEditScreen from 'modules/Manufacturers/screens/Edit';
import ManufacturerViewLayout from 'modules/Manufacturers/layouts/View';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const ManufacturerViewScreen = () => {

	const { manufacturerId } = useParams();

	const { data: manufacturer, isLoading, onChange } = useApi('/manufacturers/' + manufacturerId + '?with=createdbyuser,updatedbyuser');
	const { destroy } = useApi('/manufacturers/' + manufacturerId, false);

	const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('query');

	const [formActive, setFormActive] = useState(false);

	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('manufacturer', 'Manufacturer Deleted!')) {

			navigate('/manufacturers');
		}

    }



	return (
		<ManufacturerViewLayout className='ManufacturerViewScreen'>

				<Link className='blue opacity' to={`/manufacturers${query ? '?query=' + query: ''}`}>{query ? 'Back to search results' : 'Manufacturers'}{' >'}</Link> 

				<div className='ManufacturerViewScreen__titleBar'>
					<div className='ManufacturerViewScreen__titleBar__left'>
						<h1 style={{marginRight: '20px'}}>{! isLoading ? manufacturer.name : <BTDSkeleton width='200px'/>}</h1>
					</div>
					<div className='ManufacturerViewScreen__titleBar__right'>
						<BTDButton
							label='Edit'
							onClick={() => {setFormActive(true)}}
							style={{marginRight: '20px'}}
						/>
						<BTDButton
							colour='red'
							label='Archive'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='ManufacturerViewScreen__details'>
					<p>Manufacturer: </p>
					<p className='semi-bold'>{! isLoading ? manufacturer.name : <BTDSkeleton width='100px'/>}</p>
					<p>Created at: </p>
					{! isLoading ? <NameDateDisplay date={manufacturer.created_at} user={manufacturer.created_by_user} /> : <BTDSkeleton width='100px'/>}
					<p></p>
					<p></p>
					<p>Updated at: </p>
					{! isLoading ? <NameDateDisplay date={manufacturer.updated_at} user={manufacturer.updated_by_user} /> : <BTDSkeleton width='100px'/>}
				</div>


				{formActive &&
					<ManufacturerEditScreen
						itemId={manufacturerId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}

				
		</ManufacturerViewLayout>

	)

}

export default ManufacturerViewScreen;