// Packages
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { useUser } from 'context/User';

// Components
import UserList from 'modules/Users/components/List';
import UserCreateScreen from '../Create';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import BTDPagination from 'components/BTDUI/BTDPagination';
import BTDProgress from 'components/BTDUI/BTDProgress';
import UserLayout from 'modules/Users/layouts';

// Other
import useApi from 'hooks/useApi';
import './styles.css'

const UserScreen = () => {

    const [page, setPage] = useState(1);

    const { user } = useUser();

    const { data: users, isLoading, meta, onChange } = useApi('/users?page=' + page + '&per_page=100');
    
    const [formActive, setFormActive] = useState(false);

    const navigate = useNavigate();


    if( ! user.is_admin ) {

        navigate('/customers')
    }

    return(
        <UserLayout>

            <div className='UserListScreen__filters'>

            </div>

            {meta && meta.length !== 0 &&
                <p className='UserListScreen__text opacity'>Displaying {meta.from.toLocaleString()} to {meta.to.toLocaleString()} of {meta.total.toLocaleString()} users</p>
            }

            { isLoading ? 
                <>
                    <div style={{ 
                        alignItems: 'center',
                        display: 'flex',
                        height: '40vh',
                        justifyContent: 'center',
                        width: '100%', 
                        }}
                    >
                        <BTDProgress height={50} type='circular' width={50} />
                    </div>
                </>
            :
                <UserList 
                    isLoading={isLoading}  
                    items={users} 
                    onChange={() => {onChange()}}
                />
            }

            {meta && meta.total > meta.per_page &&
                <BTDPagination
                    currentPage={meta.current_page} 
                    totalPages={meta.last_page}
                    onChange={(value) => {
                        setPage(value);
                        onChange();
                    }}
                    style={{margin: '30px auto', width: 'max-content'}}
                />
            }	

            <AddIcon colour='#40506B' onClick={() => {
                setFormActive(true);
            }} />

            {formActive &&
                <UserCreateScreen
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </UserLayout>
    )

}

export default UserScreen;