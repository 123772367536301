// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import ProcedureListScreen from "modules/Procedures/screens/List"
import ProcedureViewScreen from "modules/Procedures/screens/View"


const ProcedureRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<ProcedureListScreen />}/>
			<Route path="/:procedureId/*" element={<ProcedureViewScreen />}/>
		</Routes>
	)
}

export default ProcedureRoutes;