// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import ContractEditScreen from 'modules/Contracts/screens/Edit';
import EyeIcon from 'components/BTDUI/icons/EyeIcon';

// Other
import './styles.css'

const CustomerContractsListItem = ({ id, isLoading, onChange, reference, schedule_months }) => {

	const [formActive, setFormActive] = useState(false);
	const [scheduleArray, setScheduleArray] = useState([]);

	useEffect(() => {

			var resultArray = []
			Object.entries(schedule_months).forEach(([key, item]) => {

				var monthString = '';
				for( let i = 0; i < item.length; i++ ) {

					monthString += item[i].month ? item[i].month.name : '';

					monthString += i <item.length -1 ? ' & ' : '';
				}

				resultArray.push({type: key, months: monthString});
			});
			
			setScheduleArray(resultArray)

		// eslint-disable-next-line
	},[schedule_months])

	return (
		<>
		<div className="CustomerContractsListItem card">

			<div>
				<Link to={'/contracts/' + id}>
					<h3 className='light-blue semi-bold'>{! isLoading ? reference : <BTDSkeleton />}</h3>
				</Link>
			</div>

			<div>
				{scheduleArray && scheduleArray.map((item, index) => {

					return(
						<p className='medium blue' key={index}>{item.type}: <span className='semi-bold blue'>{item.months}</span></p>
					)
				})
					
				}
			</div>

			<BTDMenu icon={<EllipsisIcon height="18px" />} style={{margin: '0 0 0 auto'}}>
				<Link className='blue' to={'/contracts/' + id} style={{display: 'block', marginBottom: '10px'}}><EyeIcon colour='#40506B' height='20px' style={{margin: '0 5px 0 -5px', transform: 'translateY(4px)'}} /> View</Link>
				<li onClick={() => {setFormActive(true);}}><EditIcon style={{marginRight: '5px', transform: 'translateY(3px)'}} /> Edit</li>
			</BTDMenu>

		</div>

		{formActive &&
			<ContractEditScreen
				itemId={id}
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
			/>
		}
		</>
	)
}

CustomerContractsListItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	reference: PropTypes.string,
	onChange: PropTypes.func,
	schedule_months: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

CustomerContractsListItem.defaultProps = {
	id: null,
	isLoading: false,
	reference: '',
	onChange: () => {'onChange is not set!'},
	schedule_months: {}
}

export default CustomerContractsListItem;