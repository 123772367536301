// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import Container from 'components/BTDUI/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';

const TestEquipmentViewLayout = ({ children }) => {

	return (
		<div className='TestEquipmentViewLayout'>
			
			<Header/>

			<Container>

				<Link className='blue opacity' to='/testequipment'>Test Equipment {'>'}</Link> 

				{children}

			</Container>

			<Footer/>
        </div>
	)
}

TestEquipmentViewLayout.propTypes = {
	children: PropTypes.node,
}

TestEquipmentViewLayout.defaultProps = {
	children: null,
}

export default TestEquipmentViewLayout;