// Packages
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import TestEquipmentEditScreen from 'modules/TestEquipment/screens/Edit';
import TestEquipmentViewLayout from 'modules/TestEquipment/layouts/View';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const TestEquipmentViewScreen = () => {

	const { testEquipmentId } = useParams();

	const { data: testEquipment, isLoading, onChange } = useApi('/testequipment/' + testEquipmentId);
	const { destroy } = useApi('/testequipment/' + testEquipmentId, false);

	const [formActive, setFormActive] = useState(false);
	
	const navigate = useNavigate();

	const deleteItem = async () => {

		if(await destroy('test equipment', 'Test Equipment Deleted!')) {

			navigate('/testequipment');
		}
    }


	return (
		<TestEquipmentViewLayout className='TestEquipmentViewScreen'>

				<div className='TestEquipmentViewScreen__titleBar'>
					<div className='TestEquipmentViewScreen__titleBar__left'>
						<h1 style={{marginRight: '20px'}}>{! isLoading ? testEquipment.name : <BTDSkeleton width='200px'/>}</h1>
					</div>
					<div className='TestEquipmentViewScreen__titleBar__right'>
						<BTDButton
							label='Edit'
							onClick={() => {setFormActive(true)}}
							style={{marginRight: '20px'}}
						/>
						<BTDButton
							colour='red'
							label='Archive'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='TestEquipmentViewScreen__details'>
					<p>Test Equipment: </p>
					<p className='semi-bold'>{! isLoading ? testEquipment.name : <BTDSkeleton width='100px'/>}</p>
					<p>Type: </p>
					<p className='semi-bold'>{! isLoading ? testEquipment.type : <BTDSkeleton width='100px'/>}</p>
					<p>Serial: </p>
					<p className='semi-bold'>{! isLoading ? testEquipment.serial : <BTDSkeleton width='100px'/>}</p>
					<p>Calibration Lab: </p>
					<p className='semi-bold'>{! isLoading ? testEquipment.calibration_lab : <BTDSkeleton width='100px'/>}</p>
					<p>Cert Number: </p>
					<p className='semi-bold'>{! isLoading ? testEquipment.cert_number : <BTDSkeleton width='100px'/>}</p>
					<p>Uncertainty: </p>
					<p className='semi-bold'>{! isLoading ? testEquipment.uncertainty ? testEquipment.uncertainty + ' ' + testEquipment.uncertainty_units : '' : <BTDSkeleton width='100px'/>}</p>
				</div>


				{formActive &&
					<TestEquipmentEditScreen
						itemId={testEquipmentId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}

				
		</TestEquipmentViewLayout>

	)

}

export default TestEquipmentViewScreen;