// Packages
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const CustomerEquipmentListItem = ({ isLoading, manufacturer, model_number, reference, serial, type }) => {

	return (
		<div className='CustomerEquipmentListItem'>

				<p className='CustomerEquipmentListItem__ref blue' style={{marginRight: '20px'}}><span className='CustomerEquipmentListItem__mobileLabel semi-bold'>Ref:<>&nbsp;</></span>{! isLoading ? reference : <BTDSkeleton width='50%'/>}</p> 

				<p className='CustomerEquipmentListItem__serial blue'><span className='CustomerEquipmentListItem__mobileLabel semi-bold'>Serial:<>&nbsp;</></span>{! isLoading ? serial : <BTDSkeleton width='50%'/>}</p>

				<p className='CustomerEquipmentListItem__man blue'>{! isLoading ? manufacturer ? manufacturer.name : '' : <BTDSkeleton width='50%'/>}</p>

				<p className='CustomerEquipmentListItem__model blue'>{! isLoading ? model_number : <BTDSkeleton width='50%'/>}</p>

				<p className='CustomerEquipmentListItem__type blue centre'>{! isLoading ? type : <BTDSkeleton width='50%'/>}</p>
		</div>
	)
}

CustomerEquipmentListItem.propTypes = {
	manufacturer: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	model_number: PropTypes.string, 
	isLoading: PropTypes.bool,
	reference: PropTypes.string, 
	serial: PropTypes.string, 
	type: PropTypes.string, 
}

CustomerEquipmentListItem.defaultProps = {
	manufacturer: {},
	model_number: '',
	isLoading: false,
	reference: '',
	serial: '',
	type: '',
}

export default CustomerEquipmentListItem;