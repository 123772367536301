// Packages
import { useState, useEffect } from 'react';

// Components
import CustomerList from 'modules/Customers/components/List';
import CustomerCreateScreen from '../Create';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import CustomerLayout from 'modules/Customers/layouts';
import BTDPagination from 'components/BTDUI/BTDPagination';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDMenu from 'components/BTDUI/BTDMenu';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import Search2Icon from 'components/BTDUI/icons/Search2Icon';
import LetterIcon from 'components/BTDUI/icons/LetterIcon';
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import useApi from 'hooks/useApi';
import './styles.css';

const CustomerScreen = () => {

    const [page, setPage] = useState(1);

    const { data: items, isLoading, meta, onChange } = useApi('/customers?page=' + page + '&per_page=100');
    const { data: searchResults, post: submit, isLoading: searchLoading } = useApi('/customers/search?per_page=100', false);
    const { data: searchByLetterResults, post: searchByLetter, isLoading: searchbyLetterLoading } = useApi('/customers/search-by-letter?per_page=100', false);
    const { data: pdfUrl, get: generatePDF } = useApi('/customers/generate-pdf', false);

    const queryParams = new URLSearchParams(window.location.search);
    const urlQuery = queryParams.get('query');

    const [formActive, setFormActive] = useState(false);
    const [showPagination, setShowPagination] = useState(true);
    const [query, setQuery] = useState('');
    const [letter, setLetter] = useState('');
    const [customers, setCustomers] = useState([]);

    useEffect(() => {

        if( items.length !== 0 ) {

            setCustomers(items);
            setShowPagination(true);
            setQuery('');
            setLetter('');
        }

        if(urlQuery) {

            setQuery(urlQuery);
            submit({query: urlQuery});
        }

    }, [items, urlQuery])

    useEffect(() => {

        setCustomers(searchResults);

        if(query !== '') {

            setShowPagination(false);
        }
        else {

            setShowPagination(true);
        }
        setLetter('');

    // eslint-disable-next-line
    }, [searchResults])

    useEffect(() => {

        setCustomers(searchByLetterResults);
        setQuery('');
        if( letter !== '' && letter !== null ) {

            setShowPagination(false);
        }
        else {

            setShowPagination(true);
        }
    // eslint-disable-next-line
    }, [searchByLetterResults])

    useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])


    return(
        <CustomerLayout title='Customers'>

            <div className='CustomerListScreen__filters'>

                <form className='SearchBar' style={{gridArea: 'search'}} onSubmit={(e) => {
                    e.preventDefault();
                    submit({query: query}, false);
                }}>

                    <BTDTextField
                        label='Search for customers'
                        onChange={(value) => {setQuery(value)}}
                        startIcon={query ? 
                            <ClearIcon 
                                height='28px'
                                onClick={ () => {
                                    setQuery('');
                                    submit({query: null}, false);
                                }} 
                                style={{cursor: 'pointer'}}
                                width='28px'
                            /> 
                            : 
                            <Search2Icon colour='#666' height='28px' width='28px'/>}
                        style={{marginBottom: '0'}}
                        value={query}
                    />
                </form>

                <BTDSelectField
                    label='Filter by letter'
                    onChange={(value) => {
                        setLetter(value);
                        searchByLetter({query: value})}}
                    options={[
                        {id: 'a', name: 'A'},
                        {id: 'b', name: 'B'},
                        {id: 'c', name: 'C'},
                        {id: 'd', name: 'D'},
                        {id: 'e', name: 'E'},
                        {id: 'f', name: 'F'},
                        {id: 'g', name: 'G'},
                        {id: 'h', name: 'H'},
                        {id: 'i', name: 'I'},
                        {id: 'j', name: 'J'},
                        {id: 'k', name: 'K'},
                        {id: 'l', name: 'L'},
                        {id: 'm', name: 'M'},
                        {id: 'n', name: 'N'},
                        {id: 'o', name: 'O'},
                        {id: 'p', name: 'P'},
                        {id: 'q', name: 'Q'},
                        {id: 'r', name: 'R'},
                        {id: 's', name: 'S'},
                        {id: 't', name: 'T'},
                        {id: 'u', name: 'U'},
                        {id: 'v', name: 'V'},
                        {id: 'w', name: 'W'},
                        {id: 'x', name: 'X'},
                        {id: 'y', name: 'Y'},
                        {id: 'z', name: 'Z'}
                    ]}
                    startIcon={<LetterIcon colour='#666' height='28px' width='28px'/>}
                    style={{gridArea: 'letter', margin: '0'}}
                    value={letter}
                />


                <div className='CustomerListScreen__filters__menuWrapper'>
                    <BTDMenu icon={<EllipsisIcon colour='#A0A0A0' height="20px" style={{gridArea: 'menu', padding: '20px', transform: 'rotate(90deg)'}} />} style={{alignItems: 'center', display: 'flex', height: '60px'}} >
                        <li onClick={() => {generatePDF()}}>Download Customers PDF</li>
                    </BTDMenu>
                    <a id="downloadLink" href="" target='_blank'/>
                </div>


            </div>

            {showPagination && meta && meta.length !== 0 &&
                <p className='CustomerListScreen__text opacity'>Displaying {meta.from.toLocaleString()} to {meta.to.toLocaleString()} of {meta.total.toLocaleString()} customers</p>
            }

            { (searchLoading || searchbyLetterLoading) && ! isLoading ? 
                <>
                <div style={{ 
                    alignItems: 'center',
                    display: 'flex',
                    height: '40vh',
                    justifyContent: 'center',
                    width: '100%', 
                    }}
                >
                    <BTDProgress height={50} type="circular" width={50} />
                </div>
            </>
            :
                <CustomerList 
                    isLoading={isLoading}  
                    items={customers} 
                    onChange={() => {onChange()}}
                    query={query ?? ''}
                />
                }

            {showPagination && meta && meta.total > meta.per_page &&
                <BTDPagination
                    currentPage={meta.current_page} 
                    totalPages={meta.last_page}
                    onChange={(value) => {
                        setPage(value);
                        onChange();
                    }}
                    style={{margin: '30px auto', width: 'max-content'}}
                />
            }	

            <AddIcon colour='#40506B' onClick={() => {
                setFormActive(true);
            }} />

            {formActive &&
                <CustomerCreateScreen
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </CustomerLayout>
    )

}

export default CustomerScreen;
