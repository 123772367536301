// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import ContractJobListScreen from "modules/ContractJobs/screens/List"
import ContractJobViewScreen from "modules/ContractJobs/screens/View"


const ContractJobRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<ContractJobListScreen />}/>
			<Route path="/:contractJobId/*" element={<ContractJobViewScreen />}/>
		</Routes>
	)
}

export default ContractJobRoutes;