// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import NoteEditScreen from 'modules/Notes/screens/Edit';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const NoteListItem = ({ body, created_at, id, isLoading, last_updated_user, onChange, parent, parentId, last_updated, user }) => {

	const { destroy } = useApi('/notes/' + id, false);

	const [formActive, setFormActive] = useState(false);

	const deleteItem = async () => {

		if(await destroy('note', 'Note Deleted!')) {

			onChange();
		}

    }

	return (
		<>
		<div className="NoteListItem card">

			<div className="NoteListItem__header">
				<div className="NoteListItem__header__left">
					<span className="NoteListItem__header__left__deskText">On </span>
					<span className='NoteListItem__header__left__deskText medium'>{! isLoading ? dayjs(created_at).format('DD MMM YYYY') : <BTDSkeleton width='80px'/>} </span>
					<span className='NoteListItem__header__left__mobileText medium'>{! isLoading ? dayjs(created_at).format('DD/MM/YYYY') : <BTDSkeleton width='80px'/>}<>&nbsp;</> </span>
					<span className="NoteListItem__header__left__deskText">at </span>
					<span>{! isLoading ? dayjs(created_at).format('HH:mm A') : <BTDSkeleton width='40px'/>} </span>
					<span className='semi-bold'>{! isLoading ? user.length !== 0 ? user.firstname + ' ' + user.lastname : '' : <BTDSkeleton width='80px'/>} </span>
					{user.length !== 0 && <span className="NoteListItem__header__left__deskText">wrote:</span>}
				</div>

				{last_updated &&
					<>
					<span className='NoteListItem__header__right NoteListItem__header__right-desktop opacity medium'>{! isLoading ? (last_updated_user.length !== 0 ? 'Last updated ' + dayjs(last_updated).format('DD MMM YYYY') + ' by ' + last_updated_user.firstname + ' ' +  last_updated_user.lastname : '') : <BTDSkeleton width='80px'/>}</span>
					<span className='NoteListItem__header__right NoteListItem__header__right-mobile opacity medium'>{! isLoading ? (last_updated_user.length !== 0 ? 'Updated ' + dayjs(last_updated).format('DD/MM/YYYY') + ' ' + last_updated_user.firstname + ' ' +  last_updated_user.lastname : '') : <BTDSkeleton width='80px'/>}</span>
					</>
				}

				<div>
					<BTDMenu icon={<EllipsisIcon height="18px" />} style={{margin: '0 0 0 auto', position: 'absolute', right: '5px', top: '10px'}}>
						<li onClick={() => {setFormActive(true);}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
						<li onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
					</BTDMenu>
				</div>

			</div>

			<div className="NoteListItem__main" dangerouslySetInnerHTML={{ __html: ! isLoading ? body.replace(/\n/g, '<br>') : '' }}/>


		</div>

		{formActive &&
			<NoteEditScreen
				itemId={id}
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
				parent={parent}
				parentId={parentId}
			/>
		}
		</>
	)
}

NoteListItem.propTypes = {
	body: PropTypes.string,
	created_at: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	last_updated_user: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	parent: PropTypes.string,
    parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	last_updated: PropTypes.string,
	user: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

NoteListItem.defaultProps = {
	body: '',
	created_at: '',
	id: null,
	isLoading: false,
	last_updated_user: {},
	onChange: () => {'onChange is not set!'},
	parent: '',
    parentId: '',
	last_updated: '',
	user: {},
}

export default NoteListItem;