// Packages
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const UserListItem = ({ isLoading, firstname, lastname, username }) => {

	return (
		<div className='UserListItem'>
			<h3 className='blue' style={{paddingRight: '20px'}}>{! isLoading ? firstname + ' ' + lastname : <BTDSkeleton width='50%'/>}</h3> 
			<h3 className='blue medium'>{! isLoading ? username : <BTDSkeleton width='50%'/>}</h3> 
		</div>
	)
}

UserListItem.propTypes = {
	isLoading: PropTypes.bool,
	name: PropTypes.string, 
	username: PropTypes.string, 
}

UserListItem.defaultProps = {
	isLoading: false,
	name: '',
	username: '',
}

export default UserListItem;