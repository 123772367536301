// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import TestResultsEditScreen from "modules/TestResults/screens/Edit"
import TestResultsCreateScreen from "modules/TestResults/screens/Create"


const TestResultsRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<TestResultsCreateScreen />}/>
			<Route path="/:resultsId/*" element={<TestResultsEditScreen />}/>
		</Routes>
	)
}

export default TestResultsRoutes;