// Packages
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import PurchaseOrderItemEditScreen from 'modules/PurchaseOrderItems/screens/Edit';

// Other
import useApi from 'hooks/useApi';
import './styles.css'

const PurchaseOrderItemListItem = ({ id, isLoading, name, onChange, price, purchase_order, purchaseOrderId, quantity, stock, stock_id, total }) => {

	const { destroy } = useApi('/purchaseorderitems/' + id, false);

	const [formActive, setFormActive] = useState(false);

	const deleteItem = async () => {

		if(await destroy('purchase order item', 'Purchase order item Deleted!')) {

			onChange();
		}
    }

	return (
		<div className='PurchaseOrderItemListItem tr'>
			<div className='PurchaseOrderItemListItem__qty'>
				<p className='PurchaseOrderItemListItem__mobileLabel medium blue'> Qty</p>
				<span className='td'>{! isLoading ? quantity : <BTDSkeleton width='50%'/>}</span>
			</div>

			<span className='PurchaseOrderItemListItem__desc td'>{! isLoading ? name : <BTDSkeleton width='50%'/>} <Link className='light-blue' to={'/stock/' + stock_id}>{! isLoading && stock ? ' [' + stock.reference + ']' : ''}</Link></span>

			<div className='PurchaseOrderItemListItem__unit'>
				<p className='PurchaseOrderItemListItem__mobileLabel medium blue'>Unit Price</p>
				<span className='td right'>£ {! isLoading ? price : <BTDSkeleton width='50%'/>}</span> 
			</div>

			<div className='PurchaseOrderItemListItem__total'>
				<p className='PurchaseOrderItemListItem__mobileLabel medium blue'>Total Price</p>
				<span className='td right'>£ {! isLoading ? total : <BTDSkeleton width='50%'/>}</span>
			</div>

			<div className='PurchaseOrderItemListItem__buttons'>

				<EditIcon
					height='16px' 
					onClick={() => {setFormActive(true)}}
					style={{cursor: 'pointer'}}
					width='16px'
				/>

				<ClearIcon
					colour='#BC3B3B'
					height='18px' 
					onClick={() => {deleteItem()}}
					style={{cursor: 'pointer'}}
					width='18px'
				/>

			</div>

			{formActive &&
				<PurchaseOrderItemEditScreen
					itemId={id}
					onClose={() => {setFormActive(false)}}
					onSubmit={() => {
						onChange();
						setFormActive(false);
					}}
					purchaseOrderId={purchaseOrderId}
					supplier_id={purchase_order ? purchase_order.supplier_id : ''}
				/>
			}
		</div>
	)
}

PurchaseOrderItemListItem.propTypes = {
	created_at: PropTypes.string,
	isLoading: PropTypes.bool,
	name: PropTypes.string, 
	onChange: PropTypes.func,
	price: PropTypes.number,
	purchaseOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	quantity: PropTypes.number,
	stock: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	stock_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	total: PropTypes.number
}

PurchaseOrderItemListItem.defaultProps = {
	created_at: '',
	isLoading: false,
	name: '',
	onChange: () => {'onChange is not set!'},
	price: null,
	purchaseOrderId: null,
	quantity: null,
	stock: null,
	stock_id: '',
	total: null
}

export default PurchaseOrderItemListItem;