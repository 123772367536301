// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import ListItem from 'modules/TestEquipment/components/ListItem';

// Other
import './styles.css';

const TestEquipmentList = ({ isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='TestEquipmentList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No test equipment to show here</h3>
		)
	}


	// Data State
	return (
		<div className='TestEquipmentList'>

			<div className='TestEquipmentList__header tr'>
				<span className='semi-bold'>Equipment</span>
				<span className='semi-bold'>Type</span>
				<span className='semi-bold'>Serial</span>
			</div>

			{items.map((item, index) => {

				return (
						<Link className='TestEquipmentList__link' to={'/testequipment/' + item.id} key={index}>
							<ListItem 
								{...item} 
								isLoading={false}
								onChange={() => {onChange()}}
							/>
						</Link>
				)
				
			})}

		</div>
	)
}

TestEquipmentList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

TestEquipmentList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default TestEquipmentList;