// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const SearchIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="SearchIcon" onClick={onClick} style={style}>
            <svg height={height} viewBox="0 0 368 368" width={width} version="1.1" xmlns="http://www.w3.org/2000/svg" >
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="magnifying-glass" transform="translate(0.6146, 0.6146)" fill={colour} fillRule="nonzero">
                        <path d="M363.645833,348.562499 L280.309895,265.226562 C305.88737,236.059895 320,199.154297 320,160 C320,117.260417 303.354166,77.0833333 273.135417,46.8645835 C242.916667,16.6458336 202.739583,0 160,0 C117.260417,0 77.0833333,16.6458336 46.8645835,46.8645835 C16.6458336,77.0833333 2.84217094e-14,117.260417 2.84217094e-14,160 C2.84217094e-14,202.739583 16.6458336,242.916667 46.8645835,273.135417 C77.0833333,303.354166 117.260417,320 160,320 C199.154297,320 236.059895,305.88737 265.226562,280.309895 L348.562499,363.645833 C350.645833,365.729166 353.374999,366.770833 356.104166,366.770833 C358.833333,366.770833 361.5625,365.729166 363.645834,363.645833 C367.812499,359.479166 367.812499,352.729166 363.645833,348.562499 L363.645833,348.562499 Z M61.9479157,258.052082 C35.760416,231.859374 21.3333323,197.036458 21.3333323,159.999999 C21.3333323,122.96354 35.760416,88.140624 61.9479157,61.9479157 C88.140624,35.760416 122.96354,21.3333323 159.999999,21.3333323 C197.036458,21.3333323 231.859374,35.760416 258.052082,61.9479157 C284.239582,88.140624 298.666666,122.96354 298.666666,159.999999 C298.666666,197.036458 284.239582,231.859374 258.052082,258.052082 C231.859374,284.239582 197.036458,298.666666 159.999999,298.666666 C122.96354,298.666666 88.140624,284.239582 61.9479157,258.052082 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
        </div>
    )
}

SearchIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SearchIcon.defaultProps = {
    colour: "currentColor",
    height: "22px",
    onClick: () => {},
    style: {},
    width: "22px", 
}

export default SearchIcon;