// Packages
import PropTypes from 'prop-types';

const PhoneIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="PhoneIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
                <title>phone-call</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Customers---List" transform="translate(-921, -462)" fill={colour} fillRule="nonzero">
                        <g id="phone-call" transform="translate(921, 462)">
                            <path d="M19.4755459,14.6779668 L16.6786137,11.8869103 C15.6797093,10.8901044 13.9815718,11.2888657 13.5820101,12.5846744 C13.2823388,13.4818387 12.2834344,13.9802416 11.3844204,13.7808415 C9.38661168,13.2824386 6.68956985,10.6907432 6.19011766,8.59745086 C5.89044634,7.70028662 6.48978897,6.70348072 7.38880291,6.40447789 C8.68737861,6.00575553 9.08694036,4.3111855 8.08803598,3.31437961 L5.29110371,0.523323096 C4.49198021,-0.174441032 3.29329495,-0.174441032 2.59406188,0.523323096 L0.696143555,2.4172543 C-1.20177477,4.41086609 0.895924431,9.69393735 5.59077503,14.3789251 C10.2856256,19.0639128 15.5798188,21.2569247 17.5776276,19.263274 L19.4755459,17.3693428 C20.174818,16.571898 20.174818,15.375731 19.4755459,14.6779668 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

PhoneIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PhoneIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default PhoneIcon;