// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import ListItem from 'modules/Users/components/ListItem';

// Other
import './styles.css';

const UserList = ({ isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='UserList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No users to show here</h3>
		)
	}


	// Data State
	return (
		<div className='UserList'>

			{items.map((item, index) => {

				return (
						<Link className='UserList__link' to={'/users/' + item.id} key={index}>
							<ListItem 
								{...item} 
								isLoading={false}
								onChange={() => {onChange()}}
							/>
						</Link>
				)
				
			})}

		</div>
	)
}

UserList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

UserList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default UserList;