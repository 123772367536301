// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import CustomerStockPriceEditScreen from 'modules/CustomerStockPrices/screens/Edit';

// Other
import './styles.css'
import useApi from 'hooks/useApi';
import { Link } from 'react-router-dom';

const CustomerStockPriceListItem = ({ created_at, created_by_user, updated_at, updated_by_user, id, isLoading, onChange, customerId, price, stock }) => {

	const { destroy } = useApi('/customerstockprices/' + id, false);

	const [formActive, setFormActive] = useState(false);

	const deleteItem = async () => {

		if(await destroy('customer stock price', 'Customer Stock Price Deleted!')) {

			onChange();
		}
    }

	return (
		<>
		<div className="CustomerStockPriceListItem">

			<div className="CustomerStockPriceListItem__left">
				<p className='semi-bold'>{! isLoading ? stock ? stock.name : '' : <BTDSkeleton width='80px'/>} </p>
				<p className='CustomerStockPriceListItem__left__ref regular'>
					<span>Our Code: <Link className='light-blue medium' to={'/stock/' + stock.id}>{! isLoading ? stock ? stock.reference : '' : <BTDSkeleton width='50px'/>}</Link></span>
					<span>Supplier Code: <Link className='light-blue medium' to={'/stock/' + stock.id}>{! isLoading ? stock ? stock.supplier_reference : '' : <BTDSkeleton width='50px'/>}</Link></span>
				</p>
				<div className='CustomerStockPriceListItem__left__created'>
					<p className='medium opacity'>{! isLoading ? 'Created ' + dayjs(created_at).format('D MMMM YYYY') + (created_by_user.length !== 0 ? ' by ' + created_by_user.firstname + ' ' + created_by_user.lastname : '') : <BTDSkeleton width='80px'/>} <>&nbsp;&nbsp;&nbsp;</></p>
					<p className='medium opacity'>{! isLoading ? 'Updated ' + dayjs(updated_at).format('D MMMM YYYY') + (updated_by_user.length !== 0 ? ' by ' + updated_by_user.firstname + ' ' + updated_by_user.lastname : '') : <BTDSkeleton width='80px'/>} </p>
				</div>
			</div>

			<h2 className='CustomerStockPriceListItem__price medium'>{! isLoading ? '£ ' + price : <BTDSkeleton width='80px'/>}</h2>

			<div className="CustomerStockPriceListItem__menu">
				<BTDMenu icon={<EllipsisIcon height="18px" />} >
					<li onClick={() => {setFormActive(true);}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
					<li onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
				</BTDMenu>
			</div>

		</div>

		{formActive &&
			<CustomerStockPriceEditScreen
				itemId={id}
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
				customerId={customerId}
			/>
		}
		</>
	)
}

CustomerStockPriceListItem.propTypes = {
	created_at: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	onChange: PropTypes.func,
    customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	price: PropTypes.string,
	stock: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

CustomerStockPriceListItem.defaultProps = {
	created_at: '',
	id: null,
	isLoading: false,
	onChange: () => {'onChange is not set!'},
    customerId: '',
	price: '',
	stock: {}
}

export default CustomerStockPriceListItem;