// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';

const CustomerViewLayout = ({ children }) => {

	return (
		<div className='CustomerLayout'>
			
			<Header/>

			<Container>

				{children}

			</Container>

			<Footer/>
        </div>
	)
}

CustomerViewLayout.propTypes = {
	children: PropTypes.node,
}

CustomerViewLayout.defaultProps = {
	children: null,
}

export default CustomerViewLayout