// Packages
import PropTypes from 'prop-types'

// Components
import PurchaseOrderItemForm from 'modules/PurchaseOrderItems/components/Form';

// Other
import useApi from 'hooks/useApi';

const PurchaseOrderItemEditScreen = ({  itemId, onClose, onSubmit, purchaseOrderId, supplier_id }) => {

    const { data: item, put: updateItem, errors } = useApi('/purchaseorderitems/' + itemId);

	const submit = async (values) => {

        if( await updateItem({purchase_order_id: purchaseOrderId, ...values}, 'Purchase order item updated!')) {

            onSubmit();
        }
    }

    return(
        <PurchaseOrderItemForm 
            errors={errors} 
            purchaseOrderItem={item} 
            onClose={onClose} 
            onSubmit={submit} 
            supplier_id={supplier_id}
            type='edit' 
        />
    )
}

PurchaseOrderItemEditScreen.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    purchaseOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    supplier_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PurchaseOrderItemEditScreen.defaultProps = {
    itemId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
    purchaseOrderId: null,
    supplier_id: '',
}

export default PurchaseOrderItemEditScreen;