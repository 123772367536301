// Packages
import PropTypes from 'prop-types'

// Components
import ContractJobForm from 'modules/ContractJobs/components/Form';

// Other
import useApi from 'hooks/useApi';

const ContractJobCreateScreen = ({ contract_id, onClose }) => {

    const { post: create, errors } = useApi('/contractjobs?with=equipment,engineers', false);
    const { data: equipment } = useApi('/customerequipment?contract_id=' + contract_id + '&with=manufacturer&per_page=9999')

    const submit = async (values) => {

        create({...values, contract_id: contract_id}, 'Job created!', '/contracts/' + contract_id + '/jobs/');
    }


    return(
        <ContractJobForm 
            errors={errors} 
            equipment={equipment}
            contract_id={contract_id}
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

ContractJobCreateScreen.propTypes = {
    contract_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
}

ContractJobCreateScreen.defaultProps = {
    contract_id: null,
	onClose: () => {alert('onClose is not set!')},
}

export default ContractJobCreateScreen;