// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';


const ContactForm = ({ errors, contact, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    

    // Load Item values into form
	useEffect(() => {

		if ( contact )
		{
            setData(contact);
		}

	}, [contact]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create contact' : 'Edit contact'}</h3>
        
            <BTDTextField 
                error={errors && errors.name ? errors.name : null} 
                label='Contact name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                tabIndex={1}
                value={data.name ?? ''} 
            />
            
            <BTDTextField 
                error={errors && errors.position ? errors.position : null} 
                label='Position'
                onChange={(value) => {
                    setData({...data, position: value});
                    setChangedData({...changedData, position: value});
                }} 
                tabIndex={2}
                value={data.position ?? ''} 
            />

            <BTDTextField 
                error={errors && errors.email ? errors.email : null} 
                label='Email'
                onChange={(value) => {
                    setData({...data, email: value});
                    setChangedData({...changedData, email: value});
                }} 
                tabIndex={3}
                value={data.email ?? ''} 
            />

            <BTDTextField 
                error={errors && errors.telephone ? errors.telephone : null} 
                label='Telephone'
                onChange={(value) => {
                    setData({...data, telephone: value});
                    setChangedData({...changedData, telephone: value});
                }} 
                tabIndex={4}
                value={data.telephone ?? ''} 
            />

            <BTDSwitch
                checked={data.is_primary ?? false}
                label='Is this the primary contact?'
                onChange={(value) => {
                    setData({...data, is_primary: value});
                    setChangedData({...changedData, is_primary: value});
                }} 
            />

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save contact' : 'Update contact'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

ContactForm.propTypes = {
	errors: PropTypes.object,
	contact: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

ContactForm.defaultProps = {
    errors: {},
    contact: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default ContactForm;