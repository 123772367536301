// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import LoginScreen from "modules/Login/screens"


const LoginRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<LoginScreen />}/>
		</Routes>
	)
}

export default LoginRoutes;