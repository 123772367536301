// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Context
import { useUser } from 'context/User';

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';

// Other
import useApi from 'hooks/useApi';

const PurchaseOrderForm = ({ errors, purchaseOrder, onClose, onSubmit, type, }) => {

    const { data: suppliers, isLoading: supplierLoading } = useApi('/suppliers?per_page=999999');
    const { data: users, isLoading: userLoading } = useApi('/users?per_page=999');

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

    const { user } = useUser();
    

    // Load Item values into form
	useEffect(() => {

		if ( purchaseOrder )
		{
            setData(purchaseOrder);
		}
        else {
            setData({...data, user_id: user ? user.id : '', status: 'Draft'})
            setChangedData({...changedData, user_id:  user ? user.id : '', status: 'Draft'})
        }
        // eslint-disable-next-line
	}, [purchaseOrder]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create purchase order' : 'Edit purchase order'}</h3>

            <BTDAutoCompleteField
                error={errors && errors.supplier_id ? errors.supplier_id : null}
                isLoading={supplierLoading}
                label='Supplier'
                onChange={(value) => {
                    setData({...data, supplier_id: value});
                    setChangedData({...changedData, supplier_id: value});
                }} 
                options={suppliers ?? []}
                required={true}
                tabIndex={1}
                value={data.supplier_id ?? ''} 
            />  

            <div className='form__grid-2'>
                <BTDSelectField
                    error={errors && errors.user_id ? errors.user_id : null} 
                    isLoading={userLoading}
                    label='Placed By'
                    onChange={(value) => {
                        setData({...data, user_id: value});
                        setChangedData({...changedData, user_id: value});
                    }} 
                    options={users ?? []}
                    optionLabel={['firstname', 'lastname']}
                    tabIndex={2}
                    value={data.user_id ? data.user_id : user.id} 
                />

                <BTDSelectField
                    blankOption={false}
                    error={errors && errors.status ? errors.status : null} 
                    label='Status'
                    onChange={(value) => {
                        setData({...data, status: value});
                        setChangedData({...changedData, status: value});
                    }} 
                    options={[
                        {id: 'Draft', name: 'Draft'},
                        {id: 'Open', name: 'Open'},
                        {id: 'Part Received', name: 'Part Received'},
                        {id: 'Received', name: 'Received'},
                    ]}
                    tabIndex={3}
                    value={data.status ?? 'draft'} 
                />

                </div>

            <div className='form__grid-2'>

                <BTDNumberField
                    error={errors && errors.discount ? errors.discount : null} 
                    label='Discount (£)'
                    onChange={(value) => {
                        setData({...data, discount: value});
                        setChangedData({...changedData, discount: value});
                    }}
                    step={0.01}
                    style={{width: '100%'}} 
                    tabIndex={4}
                    value={data.discount ?? ''} 
                />

                <BTDNumberField  
                    error={errors && errors.delivery ? errors.delivery : null} 
                    label='Delivery (£)'
                    onChange={(value) => {
                        setData({...data, delivery: value});
                        setChangedData({...changedData, delivery: value});
                    }} 
                    step={0.01}
                    style={{width: '100%'}} 
                    tabIndex={5}
                    value={data.delivery ?? ''} 
                />
            </div>


            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save purchase order' : 'Update purchase order'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

PurchaseOrderForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

PurchaseOrderForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default PurchaseOrderForm;