// Packages
import PropTypes from 'prop-types';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const TestEquipmentListItem = ({ isLoading, name, serial, type }) => {

	return (
		<div className='TestEquipmentListItem'>
			<p className='blue'><span className='TestEquipmentListItem__mobileLabel semi-bold'>Equipment:<>&nbsp;</></span>{! isLoading ? name : <BTDSkeleton width='50%'/>}</p> 
			<p className='blue'><span className='TestEquipmentListItem__mobileLabel semi-bold'>Type:<>&nbsp;</></span>{! isLoading ? type : <BTDSkeleton width='50%'/>}</p> 
			<p className='blue'><span className='TestEquipmentListItem__mobileLabel semi-bold'>Serial:<>&nbsp;</></span>{! isLoading ? serial : <BTDSkeleton width='50%'/>}</p> 
		</div>
	)
}

TestEquipmentListItem.propTypes = {
	isLoading: PropTypes.bool,
	name: PropTypes.string, 
	type: PropTypes.string, 
	serial: PropTypes.string, 
}

TestEquipmentListItem.defaultProps = {
	isLoading: false,
	name: '',
	type: '',
	serial: '',
}

export default TestEquipmentListItem;