// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';

// Other
import useApi from 'hooks/useApi';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';

const ContractForm = ({ contract, customer_id, errors, onClose, onSubmit, type, }) => {

    const { data: customers, isLoading: customersLoading } = useApi('/customers?per_page=99999');
    const { data: months, isLoading: monthLoading } = useApi('/months');
    const { data: engineers, isLoading: engineersLoading } = useApi('/users/engineers')

    const [data, setData] = useState([])
	const [changedData, setChangedData] = useState([])
    const [items, setItems] = useState([{ type: '', month_id: '' }]);
    

    // Load Item values into form
	useEffect(() => {

		if ( contract )
		{
            setData(contract);

            if( contract.schedule_items ) {

                setItems(contract.schedule_items)
                setChangedData({...changedData, schedule_items: contract.schedule_items})
            }
		}

	}, [contract]);

    useEffect(() => {

        if( customer_id ) {

            setData({...data, customer_id: customer_id})
            setChangedData({...changedData, customer_id: customer_id})
        }
        // eslint-disable-next-line
    }, [customer_id])

    const handleTypeChange = (index, value) => {
        const newItems = [...items];
        newItems[index].type = value;
        setItems(newItems);
        setData({...data, schedule_items: newItems});
        setChangedData({...changedData, schedule_items: newItems});
    };
    
    const handleMonthChange = (index, value) => {
        const newItems = [...items];
        newItems[index].month_id = value;
        setItems(newItems);
        setData({...data, schedule_items: newItems});
        setChangedData({...changedData, schedule_items: newItems});
    };
    
    
    const deleteItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
        setData({...data, schedule_items: newItems});
        setChangedData({...changedData, schedule_items: newItems});
    };


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create contract' : 'Edit contract'}</h3>

            <BTDAutoCompleteField
                error={errors && errors.customer_id ? errors.customer_id : null} 
                isLoading={customersLoading}
                label='Customer'
                onChange={(value) => {
                    setData({...data, customer_id: value});
                    setChangedData({...changedData, customer_id: value});
                }} 
                options={customers ?? []}
                optionLabel={['name', 'division']}
                tabIndex={1}
                value={data.customer_id ?? ''} 
            />  


            <BTDSwitch
                checked={data.is_signed ?? false}
                label='Has the contract been signed?'
                onChange={(value) => {
                    setData({...data, is_signed: value});
                    setChangedData({...changedData, is_signed: value});
                }} 
            />
        
            <BTDTextareaField
                error={errors && errors.detail ? errors.detail : null} 
                label='Contract Detail'
                onChange={(value) => {
                    setData({...data, detail: value});
                    setChangedData({...changedData, detail: value});
                }} 
                rows={3}
                tabIndex={2}
                value={data.detail ?? ''} 
            />

            <BTDSelectField
                error={errors && errors.lead_engineer_id ? errors.lead_engineer_id : null} 
                isLoading={engineersLoading}
                label='Lead Engineer'
                onChange={(value) => {
                    setData({...data, lead_engineer_id: value});
                    setChangedData({...changedData, lead_engineer_id: value});
                }} 
                options={engineers}
                optionLabel={['firstname', 'lastname']}
                tabIndex={3}
                value={data.lead_engineer_id ?? ''} 
            />

            <BTDTextareaField
                error={errors && errors.test_equipment ? errors.test_equipment : null} 
                label='Test Equipment'
                onChange={(value) => {
                    setData({...data, test_equipment: value});
                    setChangedData({...changedData, test_equipment: value});
                }} 
                rows={3}
                tabIndex={4}
                value={data.test_equipment ?? ''} 
            />

            <BTDTextareaField
                error={errors && errors.engineers_notes ? errors.engineers_notes : null} 
                label='Engineers Notes'
                onChange={(value) => {
                    setData({...data, engineers_notes: value});
                    setChangedData({...changedData, engineers_notes: value});
                }} 
                rows={3}
                tabIndex={5}
                value={data.engineers_notes ?? ''} 
            />

            <BTDTextField 
                error={errors && errors.price ? errors.price : null} 
                label='Price'
                onChange={(value) => {
                    setData({...data, price: value});
                    setChangedData({...changedData, price: value});
                }} 
                tabIndex={6}
                value={data.price ?? ''} 
            />

            
            <p className='form__label'>Schedule:</p>

            {items.map((item, index) => (
                <div key={index} style={{display: 'grid', gridTemplateColumns: '43% 43% auto', columnGap: '20px', position: 'relative'}}>
                    <BTDSelectField
                        label='Type'
                        onChange={(value) => {handleTypeChange(index, value)}}
                        options={[
                            {id: 'Service', name: 'Service'},
                            {id: 'Calibration', name: 'Calibration'},
                            {id: 'Letter', name: 'Letter'},
                            {id: 'Reminder', name: 'Reminder'},
                        ]}
                        required={true}
                        style={{display: 'flex', marginRight: '20px'}}
                        tabIndex={7}
                        value={item.type}
                    />
                    <BTDSelectField
                        label='Month'
                        isLoading={monthLoading}
                        onChange={(value) => {handleMonthChange(index, value)}}
                        options={months}
                        required={true}
                        style={{display: 'flex'}}
                        tabIndex={8}
                        value={item.month_id}
                    />
                    <ClearIcon 
                        colour='#ccc' 
                        height='23px' 
                        onClick={() => deleteItem(index)}
                        style={{cursor: 'pointer', position: 'absolute', right: '15px', top: '20px'}}
                    />
                </div>
            ))}

                <BTDButton
                    label='Add item' 
                    onClick={() => {setItems([...items, { type: '', month_id: '' }])}}
                    size='small'
                    style={{margin: '-15px 0 50px auto'}}
                />


            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save contract' : 'Update contract'}
                onClick={() => {onSubmit(changedData)}} 
                style={{marginTop: '30px'}}
            ></BTDButton>

        </FormTemplate>
    )

}

ContractForm.propTypes = {
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    schedule_items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    type: PropTypes.string
}

ContractForm.defaultProps = {
    customer_id: null,
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    schedule_items: [],
    type: 'create'
}

export default ContractForm;