// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import ContactEditScreen from 'modules/Contacts/screens/Edit';
import StarIcon from 'components/BTDUI/icons/StarIcon';
import EmailIcon from 'components/BTDUI/icons/EmailIcon';
import PhoneIcon from 'components/BTDUI/icons/PhoneIcon';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const ContactsListItem = ({ email, id, isLoading, is_primary, onChange, name, parent, parentId, position, telephone }) => {

	const { destroy } = useApi('/contacts/' + id, false);

	const [formActive, setFormActive] = useState(false);

	const deleteItem = async () => {

		if(await destroy('contact', 'Contact Deleted!')) {

			onChange();
		}
    }

	return (
		<>
		<div className="ContactsListItem card">

			<div className="ContactsListItem__name">
				<h3 className='blue semi-bold'>{! isLoading ? name : <BTDSkeleton />} {is_primary ? <StarIcon colour='#E3C31F' height='16px' width='16px'/> : '' }</h3>
				<p className='blue regular'>{! isLoading ? position : <BTDSkeleton />}</p>
			</div>

			<div>
				{telephone &&
					<div style={{marginBottom: '8px'}}>
						<PhoneIcon colour='#40506B' height='14px' style={{marginRight: '4px', transform: 'translateY(0px)'}} />
						<Link className='blue medium'>{! isLoading ? telephone : <BTDSkeleton width='50%'/>}</Link>
					</div>
				}
				{email &&
				<div className='ContactsListItem__email'>
					<EmailIcon colour='#40506B' height='14px' style={{marginRight: '4px', transform: 'translateY(3px)'}} />
					<Link to={'mailto:' + email} className='light-blue medium'>{! isLoading ? email : <BTDSkeleton width='50%'/>}</Link>
				</div>
				}
			</div>

			<BTDMenu className='ContactsListItem__menu' icon={<EllipsisIcon height="18px" />} style={{margin: '0 0 0 auto'}}>
				<li onClick={() => {setFormActive(true);}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
				<li onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
			</BTDMenu>

		</div>

		{formActive &&
			<ContactEditScreen
				itemId={id}
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
				parent={parent}
				parentId={parentId}
			/>
		}
		</>
	)
}

ContactsListItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	parent: PropTypes.string,
    parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ContactsListItem.defaultProps = {
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	parent: '',
    parentId: ''
}

export default ContactsListItem;