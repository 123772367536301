// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDMultiselect from 'components/BTDUI/fields/BTDMultiselect';
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDRadioField from 'components/BTDUI/fields/BTDRadioField';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';

// Other
import useApi from 'hooks/useApi';
import BTDCheckboxField from 'components/BTDUI/fields/BTDCheckboxField';

const ContractJobEquipmentForm = ({ addErrors, contract_id, errors, equipment, onClose, onSubmit }) => {

    const [data, setData] = useState({});
	const [changedData, setChangedData] = useState({});
    const [type, setType] = useState('existing');
    const [equipmentData, setEquipmentData] = useState({});
	const [changedEquipmentData, setChangedEquipmentData] = useState({});
    const [scaleData, setScaleData] = useState({});
	const [changedScaleData, setChangedScaleData] = useState({});
    const [step, setStep] = useState(0);
    const [equipmentIds, setEquipmentIds] = useState([])

    const { data: manufacturers, isLoading: manufacturerLoading } = useApi('/manufacturers?per_page=999');
    const { data: testspecifications, isLoading: testSpecLoading } = useApi('/testspecifications?per_page=999');
    const { data: testequipment,isLoading: testEquipLoading } = useApi('/testequipment?per_page=999');
    const { data: procedures, isLoading: procedureLoading } = useApi('/procedures?per_page=999');

    useEffect(() => {

        if( equipmentData.precision ) {

            switch (equipmentData.precision) {
                case 0:
                    setStep(1);
                    break;
                case 1:
                    setStep(0.1);
                    break;
                case 2:
                    setStep(0.01);
                    break;
                case 3:
                    setStep(0.001);
                    break;
                case 4:
                    setStep(0.0001);
                    break;
                case 5:
                    setStep(0.00001);
                    break;
                
                default:
                    setStep(1);
                    break;
            }
        }
    },[equipmentData.precision])

    useEffect(() => {

        setChangedEquipmentData({...changedEquipmentData, contract_id: contract_id});

    }, [contract_id])


    return(
        <FormTemplate onClose={onClose} size='large'>

            <BTDRadioField
                label='What would you like to do?'
                onChange={(value) => {
                    setType(value);
                }}
                options={[
                    {value: 'existing', label: "Choose and existing item of equipment from this contract which isn't on the job", colour: 'blue'},
                    {value: 'new', label: 'Create a new equipment item and add it to this contract and job', colour: 'blue'}
                ]}
                value={type ?? 'existing'} 
            />

            {type === 'existing' &&

                <div className='form__equipmentGrid'>

                    {equipment.map((e) => {
                        return (
                            <BTDCheckboxField
                                checked={data.equipment_ids && data.equipment_ids.includes(e.id)}
                                label={e.reference + ' - ' + e.manufacturer.name + ' - ' + e.model_number + ' - ' + e.serial}
                                onChange={(value) => {
                                    let ids = data.equipment_ids ? [...data.equipment_ids] : []
                                    let index = ids.indexOf(e.id);
                                    if (index !== -1) {
                                        
                                        ids.splice(index, 1);
                                    } else {
                                        
                                        ids.push(e.id);
                                    }
                                        setData({...data, equipment_ids: ids});
                                        setChangedData({...changedData, equipment_ids: ids});
                                    }} 
                            />
                        )
                    })}
                </div>
                // <BTDMultiselect
                //     error={addErrors && addErrors.equipment_ids ? addErrors.equipment_ids : null} 
                //     label='Equipment'
                //     onChange={(value) => {
                //         setData({...data, equipment_ids: value});
                //         setChangedData({...changedData, equipment_ids: value});
                //     }} 
                //     options={equipment ?? []}
                //     optionLabel={['reference', 'manufacturer.name', 'model_number', 'serial']}
                //     style={{maxWidth: '500px'}}
                //     value={data.equipment_ids ?? []} 
                // />
            }

            {type === 'new' &&
            <>
                <div className='form__columns'>

                    <div className='form__columns__column'>

                        <h2 className="form__title medium">{type === 'create' ? 'Create customer equipment' : 'Edit customer equipment'}</h2>

                        <div className='form__grid-2'>

                            <BTDTextField 
                                error={errors && errors.reference ? errors.reference : null} 
                                label='Customer Equipment ID'
                                onChange={(value) => {
                                    setEquipmentData({...equipmentData, reference: value});
                                    setChangedEquipmentData({...changedEquipmentData, reference: value});
                                }} 
                                required={true}
                                tabIndex={1}
                                value={equipmentData.reference ?? ''} 
                            />

                            <BTDTextField 
                                error={errors && errors.location ? errors.location : null} 
                                label='Location'
                                onChange={(value) => {
                                    setEquipmentData({...equipmentData, location: value});
                                    setChangedEquipmentData({...changedEquipmentData, location: value});
                                }} 
                                tabIndex={2}
                                value={equipmentData.location ?? ''} 
                            />
                        </div>
                        <div className='form__grid-2'>
                            <BTDSelectField
                                error={errors && errors.manufacturer_id ? errors.manufacturer_id : null} 
                                isLoading={manufacturerLoading}
                                label='Manufacturer'
                                onChange={(value) => {
                                    setEquipmentData({...equipmentData, manufacturer_id: value});
                                    setChangedEquipmentData({...changedEquipmentData, manufacturer_id: value});
                                }} 
                                options={manufacturers ?? []}
                                required={true}
                                tabIndex={3}
                                value={equipmentData.manufacturer_id ?? ''} 
                            />

                            <BTDTextField 
                                error={errors && errors.model_number ? errors.model_number : null} 
                                label='Model Number'
                                onChange={(value) => {
                                    setEquipmentData({...equipmentData, model_number: value});
                                    setChangedEquipmentData({...changedEquipmentData, model_number: value});
                                }} 
                                tabIndex={4}
                                value={equipmentData.model_number ?? ''} 
                            />
                        </div>

                        <div className='form__grid-2'>
                            <BTDTextField 
                                error={errors && errors.serial ? errors.serial : null} 
                                label='Manufacturer Serial #'
                                onChange={(value) => {
                                    setEquipmentData({...equipmentData, serial: value});
                                    setChangedEquipmentData({...changedEquipmentData, serial: value});
                                }} 
                                tabIndex={5}
                                value={equipmentData.serial ?? ''} 
                            />

                            <BTDSelectField 
                                error={errors && errors.type ? errors.type : null} 
                                label='Equipment Type'
                                onChange={(value) => {
                                    setEquipmentData({...equipmentData, type: value});
                                    setChangedEquipmentData({...changedEquipmentData, type: value});
                                }} 
                                options={[{id: 'scales', name: 'scales'}]}
                                tabIndex={6}
                                value={equipmentData.type ?? ''} 
                            />

                        </div>

                        <BTDSelectField
                            error={errors && errors.test_specification_id ? errors.test_specification_id : null} 
                            isLoading={testSpecLoading}
                            label='Test Specification'
                            onChange={(value) => {
                                setEquipmentData({...equipmentData, test_specification_id: value});
                                setChangedEquipmentData({...changedEquipmentData, test_specification_id: value});
                            }} 
                            options={testspecifications ?? []}
                            required={true}
                            tabIndex={7}
                            value={equipmentData.test_specification_id ?? ''} 
                        />

                        <div className='form__grid-2'>
                            <BTDSelectField 
                                error={errors && errors.procedure_id ? errors.procedure_id : null} 
                                isLoading={procedureLoading}
                                label='Test Procedure'
                                onChange={(value) => {
                                    setEquipmentData({...equipmentData, procedure_id: value});
                                    setChangedEquipmentData({...changedEquipmentData, procedure_id: value});
                                }} 
                                options={procedures ?? []}
                                required={true}
                                tabIndex={8}
                                value={equipmentData.procedure_id ?? ''} 
                            />

                            <BTDSelectField 
                                error={errors && errors.precision ? errors.precision : null} 
                                label='Certificate Precision (dps)'
                                onChange={(value) => {
                                    setEquipmentData({...equipmentData, precision: value});
                                    setChangedEquipmentData({...changedEquipmentData, precision: value});
                                }} 
                                options={[
                                    {id: 0, name: 0},
                                    {id: 1, name: 1},
                                    {id: 2, name: 2},
                                    {id: 3, name: 3},
                                    {id: 4, name: 4},
                                    {id: 5, name: 5},
                                ]}
                                required={true}
                                tabIndex={9}
                                value={equipmentData.precision ?? ''} 
                            />
                        </div>

                        <BTDMultiselect
                            error={errors && errors.test_equipment_ids ? errors.test_equipment_ids : null} 
                            isLoading={testEquipLoading}
                            label='Test Equipment Used'
                            onChange={(value) => {
                                setEquipmentData({...equipmentData, test_equipment_ids: value});
                                setChangedEquipmentData({...changedEquipmentData, test_equipment_ids: value});
                            }} 
                            options={testequipment ?? []}
                            style={{maxWidth: '100%'}}
                            tabIndex={10}
                            value={equipmentData.test_equipment_ids ?? []} 
                        />

                        <div className='form__grid-2'>

                            <BTDNumberField
                                error={errors && errors.weighing_capacity ? errors.weighing_capacity : null} 
                                label='Weighing Capacity'
                                onChange={(value) => {
                                    setScaleData({...scaleData, weighing_capacity: value});
                                    setChangedScaleData({...changedScaleData, weighing_capacity: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={11}
                                value={scaleData.weighing_capacity ?? ''} 
                            />
                            <BTDSelectField
                                blankOption={false}
                                error={errors && errors.weighing_capacity_units ? errors.weighing_capacity_units : null} 
                                onChange={(value) => {
                                    setScaleData({...scaleData, weighing_capacity_units: value});
                                    setChangedScaleData({...changedScaleData, weighing_capacity_units: value});
                                }} 
                                options={[
                                    {id: 'kg', name: 'kg'},
                                    {id: 'g', name: 'g'},
                                    {id: 'mg', name: 'mg'},
                                    {id: 'lb', name: 'lb'},
                                ]}
                                style={{maxWidth: '90px'}}
                                tabIndex={12}
                                value={scaleData.weighing_capacity_units ?? 'kg'} 
                            />
                        </div>

                    </div>
                
                    <div className='form__columns__column'>

                        <h3>Calibration Details</h3>

                        <p className='form__label'>Range Calibration: </p>

                        <div className='form__grid-2'>

                            <BTDNumberField
                                error={errors && errors.range_tolerance ? errors.range_tolerance : null} 
                                height='slim'
                                label='Tolerance'
                                onChange={(value) => {
                                    setScaleData({...scaleData, range_tolerance: value});
                                    setChangedScaleData({...changedScaleData, range_tolerance: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={13}
                                value={scaleData.range_tolerance ?? ''} 
                            />
                            <BTDSelectField
                                blankOption={false}
                                error={errors && errors.range_tolerance_units ? errors.range_tolerance_units : null} 
                                height='slim'
                                onChange={(value) => {
                                    setScaleData({...scaleData, range_tolerance_units: value});
                                    setChangedScaleData({...changedScaleData, range_tolerance_units: value});
                                }} 
                                options={[
                                    {id: 'kg', name: 'kg'},
                                    {id: 'g', name: 'g'},
                                    {id: 'mg', name: 'mg'},
                                    {id: 'lb', name: 'lb'},
                                ]}
                                style={{maxWidth: '90px'}}
                                tabIndex={14}
                                value={scaleData.range_tolerance_units ?? 'kg'} 
                            />
                        </div>

                        <div className='form__grid-2'>
                            <BTDNumberField
                                error={errors && errors.range_weight_1 ? errors.range_weight_1 : null} 
                                height='slim'
                                label={`Test Weight 1 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, range_weight_1: value});
                                    setChangedScaleData({...changedScaleData, range_weight_1: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={15}
                                value={scaleData.range_weight_1 ?? ''} 
                            />
                            <BTDNumberField
                                error={errors && errors.range_weight_2 ? errors.range_weight_2 : null} 
                                height='slim'
                                label={`Test Weight 2 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, range_weight_2: value});
                                    setChangedScaleData({...changedScaleData, range_weight_2: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={16}
                                value={scaleData.range_weight_2 ?? ''} 
                            />
                        </div>

                        <div className='form__grid-2'>
                            <BTDNumberField
                                error={errors && errors.range_weight_3 ? errors.range_weight_3 : null} 
                                height='slim'
                                label={`Test Weight 3 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, range_weight_3: value});
                                    setChangedScaleData({...changedScaleData, range_weight_3: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={17}
                                value={scaleData.range_weight_3 ?? ''} 
                            />
                            <BTDNumberField
                                error={errors && errors.range_weight_4 ? errors.range_weight_4 : null} 
                                height='slim'
                                label={`Test Weight 4 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, range_weight_4: value});
                                    setChangedScaleData({...changedScaleData, range_weight_4: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={18}
                                value={scaleData.range_weight_4 ?? ''} 
                            />
                        </div>

                        <div className='form__grid-2'>
                            <BTDNumberField
                                error={errors && errors.range_weight_5 ? errors.range_weight_5 : null} 
                                height='slim'
                                label={`Test Weight 5 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, range_weight_5: value});
                                    setChangedScaleData({...changedScaleData, range_weight_5: value});
                                }} 
                                step={step}
                                style={{marginBottom: '30px', width: '100%'}}
                                tabIndex={19}
                                value={scaleData.range_weight_5 ?? ''} 
                            />
                        </div>

                        <p className='form__label'>Eccentricity Test: </p>

                        <div className='form__grid-2'>

                            <BTDNumberField
                                error={errors && errors.eccentricity_tolerance ? errors.eccentricity_tolerance : null} 
                                height='slim'
                                label='Tolerance'
                                onChange={(value) => {
                                    setScaleData({...scaleData, eccentricity_tolerance: value});
                                    setChangedScaleData({...changedScaleData, eccentricity_tolerance: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={20}
                                value={scaleData.eccentricity_tolerance ?? ''} 
                            />
                            <BTDSelectField
                                blankOption={false}
                                error={errors && errors.eccentricity_tolerance_units ? errors.eccentricity_tolerance_units : null} 
                                height='slim'
                                onChange={(value) => {
                                    setScaleData({...scaleData, eccentricity_tolerance_units: value});
                                    setChangedScaleData({...changedScaleData, eccentricity_tolerance_units: value});
                                }} 
                                options={[
                                    {id: 'kg', name: 'kg'},
                                    {id: 'g', name: 'g'},
                                    {id: 'mg', name: 'mg'},
                                    {id: 'lb', name: 'lb'},
                                ]}
                                style={{maxWidth: '90px'}}
                                tabIndex={21}
                                value={scaleData.eccentricity_tolerance_units ?? 'kg'} 
                            />
                        </div>
                        
                        <div className='form__grid-2'>
                            <BTDNumberField
                                error={errors && errors.eccentricity_weight_0 ? errors.eccentricity_weight_0 : null} 
                                height='slim'
                                label={`Test Weight - Centre (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, eccentricity_weight_0: value, eccentricity_weight_1: value, eccentricity_weight_2: value, eccentricity_weight_3: value, eccentricity_weight_4: value});
                                    setChangedScaleData({...changedScaleData, eccentricity_weight_0: value, eccentricity_weight_1: value, eccentricity_weight_2: value, eccentricity_weight_3: value, eccentricity_weight_4: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={22}
                                value={scaleData.eccentricity_weight_0 ?? ''} 
                            />
                            <BTDNumberField
                                error={errors && errors.eccentricity_weight_1 ? errors.eccentricity_weight_1 : null} 
                                height='slim'
                                label={`Test Weight 1 - Point 1 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, eccentricity_weight_1: value});
                                    setChangedScaleData({...changedScaleData, eccentricity_weight_1: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={23}
                                value={scaleData.eccentricity_weight_1 ?? ''} 
                            />
                        </div>

                        <div className='form__grid-2'>
                            <BTDNumberField
                                error={errors && errors.eccentricity_weight_2 ? errors.eccentricity_weight_2 : null} 
                                height='slim'
                                label={`Test Weight 2 - Point 2 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, eccentricity_weight_2: value});
                                    setChangedScaleData({...changedScaleData, eccentricity_weight_2: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={24}
                                value={scaleData.eccentricity_weight_2 ?? ''} 
                            />
                            <BTDNumberField
                                error={errors && errors.eccentricity_weight_3 ? errors.eccentricity_weight_3 : null} 
                                height='slim'
                                label={`Test Weight 3 - Point 3 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, eccentricity_weight_3: value});
                                    setChangedScaleData({...changedScaleData, eccentricity_weight_3: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={25}
                                value={scaleData.eccentricity_weight_3 ?? ''} 
                            />
                        </div>

                        <div className='form__grid-2'>
                            <BTDNumberField
                                error={errors && errors.eccentricity_weight_4 ? errors.eccentricity_weight_4 : null} 
                                height='slim'
                                label={`Test Weight 4 - Point 4 (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, eccentricity_weight_4: value});
                                    setChangedScaleData({...changedScaleData, eccentricity_weight_4: value});
                                }} 
                                step={step}
                                style={{marginBottom: '30px', width: '100%'}}
                                tabIndex={26}
                                value={scaleData.eccentricity_weight_4 ?? ''} 
                            />
                        </div>

                        <p className='form__label'>Repeatability Test: </p>

                        <div className='form__grid-2'>

                            <BTDNumberField
                                error={errors && errors.repeatability_tolerance ? errors.repeatability_tolerance : null} 
                                height='slim'
                                label='Tolerance'
                                onChange={(value) => {
                                    setScaleData({...scaleData, repeatability_tolerance: value});
                                    setChangedScaleData({...changedScaleData, repeatability_tolerance: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={27}
                                value={scaleData.repeatability_tolerance ?? ''} 
                            />
                            <BTDSelectField
                                blankOption={false}
                                error={errors && errors.repeatability_tolerance_units ? errors.repeatability_tolerance_units : null} 
                                height='slim'
                                onChange={(value) => {
                                    setScaleData({...scaleData, repeatability_tolerance_units: value});
                                    setChangedScaleData({...changedScaleData, repeatability_tolerance_units: value});
                                }} 
                                options={[
                                    {id: 'kg', name: 'kg'},
                                    {id: 'g', name: 'g'},
                                    {id: 'mg', name: 'mg'},
                                    {id: 'lb', name: 'lb'},
                                ]}
                                style={{maxWidth: '80px'}}
                                tabIndex={28}
                                value={scaleData.repeatability_tolerance_units ?? 'kg'} 
                            />
                        </div>

                        <div className='form__grid-2'>
                            <BTDNumberField
                                error={errors && errors.repeatability_weight ? errors.repeatability_weight : null} 
                                height='slim'
                                label={`Test Weight (${scaleData.weighing_capacity_units ?? 'kg'})`}
                                onChange={(value) => {
                                    setScaleData({...scaleData, repeatability_weight: value});
                                    setChangedScaleData({...changedScaleData, repeatability_weight: value});
                                }} 
                                step={step}
                                style={{width: '100%'}}
                                tabIndex={29}
                                value={scaleData.repeatability_weight ?? ''} 
                            />
                        </div>
                    </div>
                    
                </div>
                
                </>
                }

            <BTDButton 
                formButton={true}
                label='Add Equipment'
                onClick={() => {
                    let values = type === 'existing' ? {type: 'existing', data: data} : {type: 'new', data: {equipment: changedEquipmentData, scale: changedScaleData}}
                    onSubmit(values)
                }} 
            ></BTDButton>


        </FormTemplate>
    )

}

ContractJobEquipmentForm.propTypes = {
	addErrors: PropTypes.object,
    contract_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	contract_job: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	errors: PropTypes.object,
    equipment: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

ContractJobEquipmentForm.defaultProps = {
    addErrors: {},
    contract_id: null,
    contract_job: null,
    errors: {},
    equipment: [],
	onSubmit: () => {alert('onSubmit is not set')},
    onClose: () => {'onClose is not set!'},
    type: 'create'
}

export default ContractJobEquipmentForm;