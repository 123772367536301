// Packages
import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import CustomerEquipmentEditScreen from 'modules/CustomerEquipment/screens/Edit';
import CustomerEquipmentViewLayout from 'modules/CustomerEquipment/layouts/View';
import BTDProgress from 'components/BTDUI/BTDProgress';
import NameDateDisplay from 'components/NameDateDisplay';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const CustomerEquipmentViewScreen = () => {

	const { customerEquipmentId } = useParams();

	const { data: customerequipment, isLoading, onChange } = useApi('/customerequipment/' + customerEquipmentId + '?with=manufacturer,procedure,testspecification,testequipment,contract,customerequipmentscale,createdbyuser,updatedbyuser');
	const { destroy } = useApi('/customerequipment/' + customerEquipmentId, false);

	const [formActive, setFormActive] = useState(false);

	const navigate = useNavigate();

	const deleteItem = async () => {

		if (await destroy('customer equipment', 'Customer Equipment Deleted!')) {

			navigate('/contracts/' + (customerequipment ? '/contracts/' + customerequipment.contract_id : ''));
		}

    }

	return (
		<CustomerEquipmentViewLayout className='CustomerEquipmentViewScreen'>

			<Link className='blue opacity' to='/contracts'>{'Contracts > '}</Link>
			<Link className='blue opacity' to={customerequipment ? '/contracts/' + customerequipment.contract_id : '/contracts'}>{customerequipment && customerequipment.contract ? 'Contract ' + customerequipment.contract.reference + ' >' : ''}</Link> 

				<div className='CustomerEquipmentViewScreen__titleBar'>
					<div className='CustomerEquipmentViewScreen__titleBar__left'>
						<h1 style={{marginRight: '5px'}}>{! isLoading ? customerequipment.reference : <BTDSkeleton width='200px'/>}</h1>
						<h2 className='opacity'>{! isLoading ? customerequipment.type ? ': ' + customerequipment.type : '' : <BTDSkeleton width='150px'/>}</h2>
					</div>
					<div className='CustomerEquipmentViewScreen__titleBar__right'>
						<BTDButton
							label='Edit'
							onClick={() => {setFormActive(true)}}
							style={{marginRight: '20px'}}
						/>
						<BTDButton
							colour='red'
							label='Delete'
							onClick={() => {deleteItem()}}
						/>
					</div>
				</div>

				<div className='CustomerEquipmentViewScreen__details'>
					<p>Customer's reference: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.reference : <BTDSkeleton width='100px'/>}</p>
					<p>Location: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.location : <BTDSkeleton width='100px'/>}</p>
					<p>Manufacturer: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.manufacturer ? customerequipment.manufacturer.name : '' : <BTDSkeleton width='100px'/>}</p>
					<p>Model Number: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.model_number : <BTDSkeleton width='100px'/>}</p>
					<p>Manufacturer Serial #: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.serial : <BTDSkeleton width='100px'/>}</p>
					<p>Test Specification: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.test_specification ? customerequipment.test_specification.name : '' : <BTDSkeleton width='100px'/>}</p>
					<p>Test Procedure: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.procedure ? customerequipment.procedure.name : '' : <BTDSkeleton width='100px'/>}</p>
					<p>Precision: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.precision + ' decimal places' : <BTDSkeleton width='100px'/>}</p>
					<p>Test Equipment Used: </p>
					<div>
						{customerequipment.test_equipment && customerequipment.test_equipment.map((item, index) => {
							return(
								<p className='semi-bold' key={index}>{item.name}</p>
							)
						})} 
					</div>
					<p>Equipment Type: </p>
					<p className='semi-bold'>{! isLoading ? customerequipment.type : <BTDSkeleton width='100px'/>}</p>
					<p>Weighing Capacity: </p>
					<p className='semi-bold'>
						{! isLoading ? customerequipment.customer_equipment_scale ? 
							(customerequipment.customer_equipment_scale.weighing_capacity ? customerequipment.customer_equipment_scale.weighing_capacity.toFixed(customerequipment.precision) : '') + ' ' 
							+ (customerequipment.customer_equipment_scale.weighing_capacity_units ? customerequipment.customer_equipment_scale.weighing_capacity_units : '')
							: '' : <BTDSkeleton width='100px'/>}
					</p>
					<p>Created at: </p>
					{! isLoading ? <NameDateDisplay date={customerequipment.created_at} user={customerequipment.created_by_user} /> : <BTDSkeleton width='100px'/>}
					<p></p>
					<p></p>
					<p>Updated at: </p>
					{! isLoading ? <NameDateDisplay date={customerequipment.updated_at} user={customerequipment.updated_by_user} /> : <BTDSkeleton width='100px'/>}
				</div>

				{customerequipment.customer_equipment_scale && 
				<div className='CustomerEquipmentViewScreen__calibration'>

					<h2>Calibration Details</h2>

					{isLoading ? <BTDProgress type='circular' /> :
					<>
						{customerequipment.customer_equipment_scale &&
						<>
						<h4 className='semi-bold'>Range Calibration</h4>

						<div className='CustomerEquipmentViewScreen__calibration__table'>
							<div className='tr'>
								<p className='semi-bold'>Tolerance: </p>
								<p>{<>&plusmn;</>} <span>{customerequipment.customer_equipment_scale.range_tolerance ? customerequipment.customer_equipment_scale.range_tolerance.toFixed(customerequipment.precision) : ''}</span> {customerequipment.customer_equipment_scale.range_tolerance_units}</p>
							</div>
							<div className='CustomerEquipmentViewScreen__calibration__table__gridRow tr'>
								<p className='CustomerEquipmentViewScreen__calibration__table__gridRow__title semi-bold'>Test Weights: </p>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Test 1: </span>
									<span>{(customerequipment.customer_equipment_scale.range_weight_1.toFixed(customerequipment.precision)) + ' ' +
									customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Test 2: </span>
									<span>{(customerequipment.customer_equipment_scale.range_weight_2.toFixed(customerequipment.precision)) + ' ' +
									customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Test 3: </span>
									<span>{(customerequipment.customer_equipment_scale.range_weight_3.toFixed(customerequipment.precision)) + ' ' +
									customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Test 4: </span>
									<span>{(customerequipment.customer_equipment_scale.range_weight_4.toFixed(customerequipment.precision)) + ' ' +
									customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Test 5: </span>
									<span>{(customerequipment.customer_equipment_scale.range_weight_5.toFixed(customerequipment.precision)) + ' ' +
									customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
							</div>
						</div>

						<h4 className='semi-bold'>Eccentricity Test</h4>

						<div className='CustomerEquipmentViewScreen__calibration__table'>
							<div className='tr'>
								<p className='semi-bold'>Tolerance: </p>
								<p>{<>&plusmn;</>} <span>{customerequipment.customer_equipment_scale.eccentricity_tolerance ? customerequipment.customer_equipment_scale.eccentricity_tolerance.toFixed(customerequipment.precision) : ''}</span> {customerequipment.customer_equipment_scale.eccentricity_tolerance_units}</p>
							</div>
							<div className='CustomerEquipmentViewScreen__calibration__table__gridRow tr'>
								<p className='CustomerEquipmentViewScreen__calibration__table__gridRow__title semi-bold'>Test Weights: </p>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Centre: </span>
									<span>{(customerequipment.customer_equipment_scale.eccentricity_weight_0.toFixed(customerequipment.precision)) + ' ' + customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Point 1: </span>
									<span>{(customerequipment.customer_equipment_scale.eccentricity_weight_1.toFixed(customerequipment.precision)) + ' ' + customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Point 2: </span>
									<span>{(customerequipment.customer_equipment_scale.eccentricity_weight_2.toFixed(customerequipment.precision)) + ' ' + customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Point 3: </span>
									<span>{(customerequipment.customer_equipment_scale.eccentricity_weight_3.toFixed(customerequipment.precision)) + ' ' + customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
								<div className='CustomerEquipmentViewScreen__calibration__table__gridRow__value'>
									<span className='semi-bold'>Point 4: </span>
									<span>{(customerequipment.customer_equipment_scale.eccentricity_weight_4.toFixed(customerequipment.precision)) + ' ' + customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
							</div>
						</div>

						<h4 className='semi-bold'>Repeatability Test</h4>

						<div className='CustomerEquipmentViewScreen__calibration__table'>
							<div className='tr'>
								<p className='semi-bold'>Tolerance: </p>
								<p>{<>&plusmn;</>} <span>{customerequipment.customer_equipment_scale.repeatability_tolerance ? customerequipment.customer_equipment_scale.repeatability_tolerance.toFixed(customerequipment.precision) : ''}</span> {customerequipment.customer_equipment_scale.repeatability_tolerance_units}</p>
							</div>
							<div className='tr'>
								<p className='semi-bold'>Test Weight: </p>
								<div>
									<span>{(customerequipment.customer_equipment_scale.repeatability_weight.toFixed(customerequipment.precision)) + ' ' + customerequipment.customer_equipment_scale.weighing_capacity_units}</span>
								</div>
							</div>
						</div>
						</>
						}
					</>
					}
				</div>
				}



				{formActive &&
					<CustomerEquipmentEditScreen
						itemId={customerEquipmentId}
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}
				
		</CustomerEquipmentViewLayout>

	)

}

export default CustomerEquipmentViewScreen