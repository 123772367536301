// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const BTDFAB = ({ colour, height, onClick, style, width }) => {

    return(

        <div className='BTDFAB' onClick={onClick} style={{backgroundColor: colour, ...style}} >
            <svg fill={colour} height={height} width={width} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="plus" fill="#FFFFFF" fillRule="nonzero">
                        <path d="M492,236 L276,236 L276,20 C276,8.954 267.046,0 256,0 C244.954,0 236,8.954 236,20 L236,236 L20,236 C8.954,236 0,244.954 0,256 C0,267.046 8.954,276 20,276 L236,276 L236,492 C236,503.046 244.954,512 256,512 C267.046,512 276,503.046 276,492 L276,276 L492,276 C503.046,276 512,267.046 512,256 C512,244.954 503.046,236 492,236 Z" id="Path"></path>
                    </g>
                </g>
            </svg>
        </div>
    );
}

BTDFAB.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

BTDFAB.defaultProps = {
    colour: "currentColor",
    height: "22px",
    onClick: () => {},
    style: {},
    width: "22px", 
}

export default BTDFAB;