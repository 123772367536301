// Packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import CheckedLink from 'components/BTDUI/CheckedLink';

// other
import './styles.css';
import useApi from 'hooks/useApi';

const MobileMenu = ({ onClick }) => {

    const { data: pdfUrl, get: generatePDF } = useApi('/testresults/generate-pdf', false);

    useEffect(() => {

        if( pdfUrl && pdfUrl.length !== 0) {

            document.getElementById('downloadLink').href = pdfUrl.url;

            document.getElementById('downloadLink').click();
        }

    }, [pdfUrl])

    return(
        <div className="MobileMenu">

            <div className='MobileMenu__links'>

                <CheckedLink to='/customers' className='MobileMenu__links__link blue' onClick={onClick}>Customers</CheckedLink>
                <CheckedLink to='/suppliers' className='MobileMenu__links__link blue' onClick={onClick}>Suppliers</CheckedLink>
                <CheckedLink to='/stock' className='MobileMenu__links__link blue' onClick={onClick}>Stock</CheckedLink>
                <CheckedLink to='/contracts' className='MobileMenu__links__link blue' onClick={onClick}>Contracts</CheckedLink>
                <CheckedLink to='/purchaseorders' className='MobileMenu__links__link blue' onClick={onClick}>Purchase Orders</CheckedLink>

                <br/>
                <br/>

                <CheckedLink to='/testequipment' className='MobileMenu__links__link blue' onClick={onClick}>Test Equipment</CheckedLink>
                <CheckedLink to='/testspecifications' className='MobileMenu__links__link blue' onClick={onClick}>Test Specifications</CheckedLink>
                <CheckedLink to='/manufacturers' className='MobileMenu__links__link blue' onClick={onClick}>Manufacturers</CheckedLink>
                <CheckedLink to='/procedures' className='MobileMenu__links__link blue' onClick={onClick}>Procedures</CheckedLink>
                <div onClick={() => {generatePDF()}}>Blank scales certificate</div>
            </div>


        </div>
    )
}

MobileMenu.propTypes = {
    onClick: PropTypes.func,
}

MobileMenu.defaultProps = {
    onClick: () => {alert('onClick is not set!')},
}

export default MobileMenu;